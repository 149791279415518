import React, {useEffect, useState} from "react";
import {getEditUserKeys, getUserState, getRole} from '../body/pages/settings/tableComponents/helper';
import {getCognitoUserRole, UserRole} from '../../helper/userHelper'
import {Box, Button, Paper, Tab, Tabs, TextField} from "@material-ui/core";
import {useStyles} from "../../css/styles";
import {getCompany, loader_add, popup_close, updateUser} from '../../actions/actions';
import {validation} from "../forms/helper/validationHelper";
import {useDispatch, useSelector} from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import {helper} from "../forms/helper/helper";


const UserEditPopup = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const userAdmin = useSelector(state => state.user);
    const company = useSelector(state => state.company);
    const userAdminRole = getCognitoUserRole(userAdmin);
    const {user, queryParams, companyBranches} = props;
    const [userState, setUserState] = useState(getUserState({...user}, userAdminRole, getEditUserKeys));
    const [validationEmail, setValidationEmail] = useState(null);
    const [validLastName, setValidLastName] = useState(null);
    const [validPhone, setValidPhone] = useState(null);
    const [submitAttempt, setSubmitAttempt] = useState(false);

    useEffect(() => {
        dispatch(getCompany(user.companyId, dispatch));
    }, [user, dispatch]);

    const fillForm = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if(name === getEditUserKeys.PHONE_NUMBER.key) {
            value = helper.prettyPhone(value);
        }


        if(!!submitAttempt){
            if(name === getEditUserKeys.LAST_NAME.key) {
                validation.checkLastName(userState[getEditUserKeys.LAST_NAME.key], setValidLastName);
            }


            if(name === getEditUserKeys.EMAIL.key) {

                validation.checkEmail(userState[getEditUserKeys.EMAIL.key], setValidationEmail);
            }

            if(name === getEditUserKeys.PHONE_NUMBER.key) {

                validation.checkPhone(userState[getEditUserKeys.PHONE_NUMBER.key], setValidPhone);
            }
        }

        const newState = {...userState};
        if( 'role' === name || getEditUserKeys.DESIRED_ROLE.key === name) {
            value = Number(value);
        }
        newState[name] = value;
        setUserState(newState);
    };



    const update = () => {
        setSubmitAttempt(true);
        const email = validation.checkEmail(userState[getEditUserKeys.EMAIL.key], setValidationEmail);
        const lastName = validation.checkLastName(userState[getEditUserKeys.LAST_NAME.key], setValidLastName);
        const mobile = validation.checkPhone(userState[getEditUserKeys.PHONE_NUMBER.key], setValidPhone);
        if (!email || !lastName || !mobile) {
            return;
        }
            let attr = {...userState, [getEditUserKeys.PHONE_NUMBER.key]: `+1${userState[getEditUserKeys.PHONE_NUMBER.key]}`}

            if (attr[getEditUserKeys.BIND_BRANCH.key] === '') {
                delete attr[getEditUserKeys.BIND_BRANCH.key];
            }
            dispatch(updateUser(user.id, user.companyId, attr, queryParams, dispatch, userAdmin));
            dispatch(loader_add());


    };

    const isWhiteLabel = company.accessType === 'whiteLabel';
    const roles = getRole(userAdmin);

    if (isWhiteLabel) {
        const groupAdminRoleIndex = roles.findIndex(role => Number(role.value) === Number(UserRole.GroupAdmin));
        roles.splice(groupAdminRoleIndex, 1);
    } else {
        const whiteLabelAdminRoleIndex = roles.findIndex(role => Number(role.value) === Number(UserRole.WhiteLabelAdmin));
        roles.splice(whiteLabelAdminRoleIndex, 1);
    }

    return <Paper className={classes.root}>
                    <Tabs value={0} indicatorColor="primary" textColor="primary" centered={true} >
                        <Tab label="Edit User"/>
                    </Tabs>
                    <div className={classes.content}>
                        <form autoComplete="off">
                            <TextField
                                name={getEditUserKeys.EMAIL.key}
                                label={getEditUserKeys.EMAIL.label}
                                value={userState[getEditUserKeys.EMAIL.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                                helperText={null !== validationEmail ? validationEmail : ''}
                                error={null !== validationEmail ? true : false}
                            />
                            <TextField
                                name={getEditUserKeys.COMPANY_ID.key}
                                label={getEditUserKeys.COMPANY_ID.label}
                                value={userState[getEditUserKeys.COMPANY_ID.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required

                            />
                            <TextField
                                name={getEditUserKeys.FIRST_NAME.key}
                                label={getEditUserKeys.FIRST_NAME.label}
                                value={userState[getEditUserKeys.FIRST_NAME.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required

                            />
                            <TextField
                                name={getEditUserKeys.LAST_NAME.key}
                                label={getEditUserKeys.LAST_NAME.label}
                                value={userState[getEditUserKeys.LAST_NAME.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                                helperText={null !== validLastName ? validLastName : ''}
                                error={validLastName ? true : false}
                            />
                            <TextField
                                name={getEditUserKeys.PRACTICE_NAME.key}
                                label={getEditUserKeys.PRACTICE_NAME.label}
                                value={userState[getEditUserKeys.PRACTICE_NAME.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                            />
                            <TextField
                                name={getEditUserKeys.DESIRED_ROLE.key}
                                label={getEditUserKeys.DESIRED_ROLE.label}
                                select
                                value={userState[getEditUserKeys.DESIRED_ROLE.key]}
                                fullWidth={true}
                                margin="normal"
                                onClick={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                            >
                                {roles.map((option, ) => (
                                    <option  key={option.value} value={option.value}>
                                        {option.label}
                                    </option >
                                ))}

                            </TextField>
                            {null !== companyBranches && 0 !== companyBranches.length
                                ? <TextField
                                    label={getEditUserKeys.BIND_BRANCH.label}
                                    name={getEditUserKeys.BIND_BRANCH.key}
                                    select
                                    value={userState[getEditUserKeys.BIND_BRANCH.key]}
                                    fullWidth={true}
                                    margin="normal"
                                    onClick={e => fillForm(e)}
                                    onChange={e => fillForm(e)}
                                    onKeyUp={e => fillForm(e)}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                >
                                    <option value="">
                                    </option >
                                    {companyBranches.map(branch => (
                                        <option  key={branch.id} value={branch.id}>
                                            {branch.location}
                                        </option >
                                    ))}
                                </TextField>
                                : null
                            }
                            <TextField
                                name={getEditUserKeys.STREET_ADDRESS.key}
                                label={getEditUserKeys.STREET_ADDRESS.label}
                                value={userState[getEditUserKeys.STREET_ADDRESS.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                            />
                            <TextField
                                name={getEditUserKeys.CITY.key}
                                label={getEditUserKeys.CITY.label}
                                value={userState[getEditUserKeys.CITY.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                            />
                            <TextField
                                name={getEditUserKeys.ZIP_CODE.key}
                                label={getEditUserKeys.ZIP_CODE.label}
                                value={userState[getEditUserKeys.ZIP_CODE.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                            />
                            <TextField
                                name={getEditUserKeys.PHONE_NUMBER.key}
                                label={getEditUserKeys.PHONE_NUMBER.label}
                                value={userState[getEditUserKeys.PHONE_NUMBER.key]}
                                onMouseUp={e => fillForm(e)}
                                onChange={e => fillForm(e)}
                                onKeyUp={e => fillForm(e)}
                                autoComplete="email"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                required
                                helperText={null !== validPhone ? validPhone : ''}
                                error={null !== validPhone ? true : false}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                }}
                            />

                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                                <div onClick={e => dispatch(popup_close())} style={{cursor: 'pointer'}}>Back</div>
                                <Button
                                    onClick={e => update()}
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#02c9e3',
                                        color: '#ffffff'
                                    }}>
                                    Update User
                                </Button>
                            </Box>
                        </form>
                    </div>
                </Paper>;
};

export default UserEditPopup;