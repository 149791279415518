import {actionTypes} from '../actions/actionTypes';

import WhiteLabelApi from '../Api/WhiteLabelApi';
import {
  exeption_add,
} from "../actions/actions";


const _whiteLabelApi = new WhiteLabelApi();

export default function whiteLabelInfo(state = null, action) {
    switch (action.type) { 

          case actionTypes.UPDATE_COMPANY_NAME:
          _whiteLabelApi.updateCompanyName({}, action.companyId, {name:action.companyName}, action.dispatch)
                .then(data => {
                  console.log(data)
                    return data;
                }) 
                .catch(err => {
                  console.log(err)
                  action.dispatch(exeption_add(err));
                  return state;
                });
            return state;
            
        default:
            break;
    }

    return state;
}