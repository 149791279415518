import {actionTypes} from '../actions/actionTypes';
import {exeption_close, loader_remove, setEmbededDashboardsNames} from '../actions/actions';
import EmbededDashboardApi from "../Api/EmbededDashboardApi";
const _embededDashboardApi = new EmbededDashboardApi();

export default function embededDashboardsNames(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_EMBEDED_DASHBOARDS_NAMES:
            _embededDashboardApi.getEmbededDashboardsNames({}, null, null)
                .then(data => {
                    action.dispatch(setEmbededDashboardsNames(data.data, action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return state;
                })
                .catch(e => {
                    if(undefined !== e.DashboardNotFoundError) {
                        action.dispatch(setEmbededDashboardsNames("You don't have any dashboards yet", action.dispatch));
                    } else {
                        action.dispatch(setEmbededDashboardsNames("Something went wrong", action.dispatch));
                    }
                    action.dispatch(loader_remove());
                    return state;
                });
            return 'Please wait, for dashboard refresh';

        case actionTypes.SET_EMBEDED_DASHBOARDS_NAMES:
            return action.data;

        default:
            break;
    }

    return state;
};