import AuthApiClient from './AuthApiClient';
export default class ManagedCompanyApi extends AuthApiClient {
 
    getManagedCompany(headers, body, id) {
        headers = headers ?? {};

        return this._sendAuthRequest(`/api/user/${id}/managed-companies`, 'GET', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    userLinkToCompany(headers, body, id) {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';
        return this._sendAuthRequest(`/api/user/${id}/link-to-company`, 'POST',  body, headers, null)
            .then(response => {
                return this._filterNonJsonResponse(response)
                    .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                    .then(response => response.json())
                    .then(data => {
                        return Promise.reject(data.error);

                    });
            });
    }

    userUnlinkFromCompany(headers, body, id) {
        headers = headers ?? {};
        return this._sendAuthRequest(`/api/user/${id}/unlink-from-company`, 'DELETE',  body, headers, null, true)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }
}