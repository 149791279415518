import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class VerificationApi extends AuthApiClient {


    sendPhoneVerificationCode(body, headers)
    {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(routes.PHONE_VERIFICATION_CODE, 'POST', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    verifyPhone(body, headers)
    {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(routes.VERIFICATION_PHONE, 'POST', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    userMfaWasSet(body, headers)
    {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(routes.USER_MFA_WAS_SET, 'POST', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }
}