import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {requestAccountRecovery, passwordReset, loader_add, clearRecoveryPasswordState} from '../../actions/actions';
import {inputStyles} from '../../css/styles'
import {Box} from "@material-ui/core";
import {Link} from "react-router-dom";
import {routes} from "../../route/routes";
import {validation} from "./helper/validationHelper";


const ForgotPasswordForm = () => {
    const inputStyle = inputStyles();
    const pageState = useSelector(state => state.auth.forgotPassword);
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [validateEmail, setValidateEmail] = useState(null);
    const [validationPsw, setvalidationPsw] = useState([]);
    const [submitAttempt, setSubmitAttempt] = useState(null);

    const clearState = (e) => {
        dispatch(clearRecoveryPasswordState(dispatch));
    };

    const fillFormEmail = (e) => {
        const value = e.target.value;
        setUsername(value);

        if(submitAttempt) {
            validation.checkEmail(username, setValidateEmail);
        }
    };

    const setNewPassword = (e) => {
        let value = e.target.value;
        if(0 < value.length) {
            validation.checkPassword(value, setvalidationPsw);
        }

        setPassword(value);
    };

    const dispatchRequestAccountRecovery = () => {
        setSubmitAttempt(true);
        let validEmail = validation.checkEmail(username, setValidateEmail);
        if(false === validEmail) {
            return;
        }
        if (null === validateEmail) {
            dispatch(loader_add());
            dispatch(requestAccountRecovery(username, dispatch));
        }
    };

    const dispatchPasswordReset = () => {
        if ('' === password) {
            return setvalidationPsw(['Password should contain at least 10 characters']);
        }
        if ('' !== username) {
            dispatch(loader_add());
            dispatch(passwordReset({ username, password, code }, dispatch));
        }
    };
    
    if (pageState.recoveryRequestSentSuccesfully) {
        return (<div>
            <div className='loin-text-info' >
                <p>If you have already registered look for the verification code in that email.</p>
            </div>
            <form noValidate autoComplete="off">
                <TextField
                    name="code"
                    label="Code"
                    className={inputStyle.root}
                    value={code}
                    autoComplete="code"
                    variant="outlined"
                    fullWidth={true}
                    margin="normal"
                    onChange={e => setCode(e.target.value)}
                />
                <TextField
                    name="password"
                    type="password"
                    label="New password"
                    className={inputStyle.root}
                    value={password}
                    autoComplete="password"
                    variant="outlined"
                    fullWidth={true}
                    margin="normal"
                    onChange={e => setNewPassword(e)}
                    helperText={0 < validationPsw.length ? validationPsw.join('\n') : ''}
                    error={0 < validationPsw.length ? true : false}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
                    <div onClick={e => clearState(e)} className={inputStyle.back}><Link to={routes.LOGIN}>Back to Sign In</Link></div>
                <Button
                    onClick={e => dispatchPasswordReset()}
                    variant="contained"
                    style={{
                        backgroundColor: '#02c9e3',
                        color: '#ffffff'
                    }}>
                    Reset password
                </Button>
                </Box>
            </form>
        </div>)
    }


    if (pageState.accountRecoveryResetPasswordSuccess) {
        return (<Box display="flex" justifyContent="space-between" alignItems="center" mt={2}  mb={1}>
                    <div>Success</div>
                    <div onClick={e => clearState(e)} className={inputStyle.back}><Link to={routes.LOGIN}>Back to Sign In</Link></div>
                </Box>)
    }

    return (<div>
        <form noValidate autoComplete="off">
            <TextField
                name="email"
                label="Email"
                className={inputStyle.root}
                autoComplete="email"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                onClick={e => fillFormEmail(e)}
                onKeyUp={e => fillFormEmail(e)}
                onChange={e => fillFormEmail(e)}
                helperText={null !== validateEmail ? validateEmail : ''}
                error={null !== validateEmail ? true : false}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}  mb={1}>
                <div className={inputStyle.back}><Link to={routes.LOGIN}>Back to Sign In</Link></div>
            <Button
                onClick={e => dispatchRequestAccountRecovery()}
                variant="contained"
                style={{
                    backgroundColor: '#02c9e3',
                    color: '#ffffff'
                }}>
                Submit
            </Button>
            </Box>
        </form>
    </div>)
    
};

export default ForgotPasswordForm;