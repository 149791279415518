import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { SketchPicker } from "react-color";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsButton(props) { 
  const {
    handleChangeComplete,
    handleChangeCompleteFooter,
    headerColor,
    footerColor,
    handleChangeHeaderFontColor,
    handleChangeFooterFontColor,
    headerFontColor,
    footerFontColor
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Header" {...a11yProps(0)} />
          <Tab label="Footer" {...a11yProps(1)} />
          <Tab label="Header font" {...a11yProps(2)} />
          <Tab label="Footer font" {...a11yProps(3)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{display: "flex", justifyContent:"center"}}>
      <SketchPicker
          color={headerColor || '#02c9e3'}
          onChange={handleChangeComplete}
        />
      </TabPanel>
      <TabPanel value={value} index={1} style={{display: "flex", justifyContent:"center"}}>
      <SketchPicker
          color={footerColor || '#383939'}
          onChange={handleChangeCompleteFooter}
        />
      </TabPanel>
      <TabPanel value={value} index={2} style={{display: "flex", justifyContent:"center"}}>
      <SketchPicker
          color={headerFontColor || '#ffffff'}
          onChange={handleChangeHeaderFontColor}
        />
      </TabPanel>
      <TabPanel value={value} index={3} style={{display: "flex", justifyContent:"center"}}>
      <SketchPicker
          color={footerFontColor || '#d3d3d3'}
          onChange={handleChangeFooterFontColor}
        />
      </TabPanel>

    </div>
  );
}