import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from '@material-ui/icons/Timeline';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import {routes} from '../../../route/routes';

const navList = [
    {
        icon: HomeIcon,
        name: 'Home',
        link: routes.MAIN_PAIGE
    },
    {
        icon: TimelineIcon,
        name: 'Dashboard',
        link: routes.DASHBOARD
    },
    {
        icon: PublishIcon,
        name: 'Import Data',
        link: routes.IMPORT_DATA
    },
    {
        icon: SettingsIcon,
        name: 'Settings',
        link: routes.SETTINGS
    }
];

const navListRole3 = [
    {
        icon: HomeIcon,
        name: 'Home',
        link: routes.MAIN_PAIGE
    },
    {
        icon: TimelineIcon,
        name: 'Dashboard',
        link: routes.DASHBOARD
    }
];

const navListRole5 = [
    {
        icon: HomeIcon,
        name: 'Home',
        link: routes.MAIN_PAIGE
    },
    {
        icon: TimelineIcon,
        name: 'Dashboard',
        link: routes.DASHBOARD
    },
    {
        icon: SettingsIcon,
        name: 'Settings',
        link: routes.SETTINGS
    }
];


const getNavList = (role) => {
    switch (true) {
        case 3 === role:
            return navListRole3;
        case 5 === role || 6 === role:
            return navListRole5;
        default:
            return navList;
    }
};
export {navList, navListRole3, navListRole5, getNavList};