import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class DashboardApi extends AuthApiClient {

    presignedRequest(presignedRequestForDataUpload, file) {
        let formData = new FormData();
        for (let key in presignedRequestForDataUpload.fields) {
            formData.append(key, presignedRequestForDataUpload.fields[key]);
        }
        formData.append('file', file);
        return this._sendRequest(presignedRequestForDataUpload.url, 'POST', formData, null, {'mode': 'no-cors'})
            .then(response => {
                return Promise.resolve({message: 'The file is successfuly sent to the secure location'});
            })
            .catch(e => e);
    }

    sendDashboardClaims(headers, body, companyId)
    {
        headers = headers ?? {};

        let endpoint = routes.DASHBOARD_CLAIMS;
        if ('' !== companyId) {
            const searchParams = this._searchParamsFactory.createSearchParams({companyId: companyId});
            const searchString = searchParams.toString();
            endpoint = `${routes.DASHBOARD_CLAIMS}?${searchString}`;
        }

        return this._sendAuthRequest(endpoint, 'POST', body,  headers)
            .then(response => {
                return this._filterNonJsonResponse(response)
                        .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                        .then(response => response.json())
                        .then(data => {

                            if(data.error.presignedRequestForDataUpload) {
                                return Promise.reject({
                                    'presignedRequestForDataUpload': data.error.presignedRequestForDataUpload,
                                    'file': body.get('claims')
                                });
                            }
                            return Promise.reject(data);

                        });
            });

    }

    sendPatientDemographics(headers, body, companyId)
    {
        headers = headers ?? {};

        let endpoint = routes.PATIENT_DEMOGRAPHICS;
        if ('' !== companyId) {
            const searchParams = this._searchParamsFactory.createSearchParams({companyId: companyId});
            const searchString = searchParams.toString();
            endpoint = `${routes.PATIENT_DEMOGRAPHICS}?${searchString}`;
        }

        return this._sendAuthRequest(endpoint, 'POST', body,  headers)
            .then(response => {
                return this._filterNonJsonResponse(response)
                    .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                        .then(response => response.json())
                        .then(data => {
                            if(data.error.presignedRequestForDataUpload) {
                                return Promise.reject({
                                    'presignedRequestForDataUpload': data.error.presignedRequestForDataUpload,
                                    'file': body.get('patientDemographics')
                                });
                            }
                            return Promise.reject(data);

                        });
            })

    }
}