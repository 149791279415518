import {actionTypes} from '../actions/actionTypes';
import {loader_remove, setAdminCompanyList} from '../actions/actions';
import SettingsApi from '../Api/SettingsApi';
const _settingsApi = new SettingsApi();

export default function adminCompanyList(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_ADMIN_COMPANY_LIST:
            _settingsApi.getAdminCompanyList({}, action.body)
                .then(({ data }) => {
                    action.dispatch(setAdminCompanyList(data, action.dispatch));
                    action.dispatch(loader_remove());
                    return data;
                })
                .catch(e => {
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.SET_ADMIN_COMPANY_LIST:
            return action.companyList;

        default:
            break;
    }

    return state;
};