const isEmpty = (value) => {
    return value === '';
};

export const validation = {
    checkLink: (link, setState) => {
        if(isEmpty(link)) {
            setState('This value should not be blank.');
            return false;
        }
        if(0 > link.indexOf('https')){
            setState('This value is not a valid Link.');
            return false;
        }
        setState(null);
        return true;
    },
    checkTitle: (title, setState) => {
        if(isEmpty(title)) {
            setState('This value should not be blank.');
            return false;
        }
        setState(null);
        return true;
    }
};

export const checkIfDomainIsInvalid =(subdomain) => {
    var inValid = /\s/;
    return inValid.test(subdomain);
}