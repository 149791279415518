import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { Router} from 'react-router-dom';
import createStore from './store/store';
import './css/index.css';
import App from './App';

import Amplify from 'aws-amplify';
import config from  './aws-exports';
Amplify.configure(config);

const storeWrapper = createStore();

ReactDOM.render(
  <React.StrictMode>
      <Provider store={storeWrapper.store}>
          <Router history={storeWrapper.history}>
                <App history={storeWrapper.history}/>
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
