import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {sendNewPassword, loader_add, clearRecoveryPasswordState} from '../../actions/actions';
import {inputStyles, useStyles} from '../../css/styles'
import {Box, Paper, Tab, Tabs, Container} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {routes} from "../../route/routes";
import {validation} from "./helper/validationHelper";


const NewPasswordForm = () => {
    const inputStyle = inputStyles();
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [validationPsw, setvalidationPsw] = useState([]);

    const clearState = (e) => {
        dispatch(clearRecoveryPasswordState(dispatch));
    };

    const setNewPassword = (e) => {
        let value = e.target.value;
        if(0 < value.length) {
            validation.checkPassword(value, setvalidationPsw);
        }

        setPassword(value);
    };

    const dispatchNewPassword = () => {
        if ('' === password) {
            return setvalidationPsw(['Password should contain at least 10 characters']);
        }

        if (validationPsw.length === 0) {
            dispatch(loader_add());
            dispatch(sendNewPassword(user, password, history, dispatch));
        }
    };

    return (<Container maxWidth={"xl"}>
        <Paper className={classes.root}>
                <Tabs
                    value={0}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={true}
                >
                    <Tab label="New password"/>
                </Tabs>
                <div className={classes.content}>
                    <div>
                        <form noValidate autoComplete="off">
                            <TextField
                                name="password"
                                type="password"
                                label="New password"
                                className={inputStyle.root}
                                value={password}
                                autoComplete="password"
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                onChange={e => setNewPassword(e)}
                                helperText={0 < validationPsw.length ? validationPsw.join('\n') : ''}
                                error={0 < validationPsw.length ? true : false}
                            />
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
                                <div onClick={e => clearState(e)} className={inputStyle.back}>
                                    <Link to={routes.LOGIN}>Back to Sign In</Link>
                                </div>
                                <Button
                                    onClick={e => dispatchNewPassword()}
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#02c9e3',
                                        color: '#ffffff'
                                    }}>
                                    Submit password
                                </Button>
                            </Box>
                        </form>
                    </div>
                </div>
        </Paper>
    </Container>)
};

export default NewPasswordForm;