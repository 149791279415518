import React from "react";
import {Button, Tooltip} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {popupAddNewUser} from "../../../../actions/actions";


const AddUserButton = (props) => {
    const dispatch = useDispatch();

    return <Tooltip title="Add New User" placement="top" arrow>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={e => dispatch(popupAddNewUser(props.companyId))}
                >
                    New User
                </Button>
            </Tooltip>
};

export default AddUserButton;