import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ManageBranchesPopup from "./ManageBranchesPopup";
import CompanyUsers from "../body/pages/settings/CompanyUsers";
import AddUserPopup from "./AddUserPopup";
import UserEditPopup from "./UserEditPopup";
import {popupTypes} from "../../helper/popupTypes";
import Popup from "./Popup";
import {getCompanyBranches} from "../../actions/actions";

const PopupPages = (props) => {
    const dispatch = useDispatch();
    const popup = useSelector(state => state.popup);
    const companyBranches = useSelector(state => state.companyBranches);

    useEffect(() => {
        if(popupTypes.updateUser === popup.type) {
            dispatch(getCompanyBranches(popup.user.companyId, dispatch));
        }
        if(popupTypes.addNewUser === popup.type) {
            dispatch(getCompanyBranches(popup.companyId, dispatch));
        }
        //eslint-disable-next-line
    }, []);

    if(popupTypes.manageBranches === popup.type) {

        return <Popup><ManageBranchesPopup /></Popup>;
    }

    if (popupTypes.userList === popup.type) {
        return <Popup><CompanyUsers companyId={popup.id}/></Popup>
    }

    if(popupTypes.addNewUser === popup.type) {
        return <Popup><AddUserPopup companyBranches={companyBranches}/></Popup>;
    }

    if(popupTypes.updateUser === popup.type ) {
        const query = {limit: 10, skip: 0} || popup.queryParams;
        return <Popup><UserEditPopup companyBranches={companyBranches} user={popup.user} queryParams={query}/></Popup>
    }

    return <div></div>;
};

export default PopupPages;