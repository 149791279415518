import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getEmbededDashboardsNames,
  getEmbededDashboardUrl,
  loader_add,
} from "../../../../actions/actions";

const DashboardSelect = (props) => {
  const dispatch = useDispatch();
  const dashboards = useSelector(
    (state) =>
      state.embededDashboardsNames &&
      state.embededDashboardsNames.dashboardsNames
  );
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getEmbededDashboardsNames(dispatch));
    dispatch(loader_add());
  }, [dispatch]);

  useEffect(() => {
    if(value!==""){
      dispatch(getEmbededDashboardUrl(dispatch, {dashboardNameFilter: value}, true));
      dispatch(loader_add());  
    }
}, [value, dispatch]);


  const handleChange = (e) => {
    let name = e.target.value;
    setValue(name);
  };


  if (!dashboards) return <div> </div>;

  return (
    <div
      style={{
        width: "300px",
        margin: 20,
        marginTop: 0,
      }}
    >
      <form
        noValidate
        autoComplete="off"
        style={{ display: "flex", alignItems: "center" }}
      >
        <TextField
          id="standard-select-currency"
          select
          label="Desired dashboard"
          value={value}
          onClick={handleChange}
          onChange={handleChange}
          onKeyUp={handleChange}
          helperText=""
          variant="outlined"
          fullWidth={true}
          margin="normal"
          error={false}
          style={{
            color: "#000",
          }}
        >
          {dashboards.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      </form>
    </div>
  );
};

export default DashboardSelect;
