import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TextField, Button, Divider, Chip} from '@material-ui/core';
import {userUnlinkFromCompany, getManagedUserCompany, userLinkToCompany} from '../../../../../actions/actions';
import {getCognitoUserRole} from '../../../../../helper/userHelper';

const ManageCompany = (props) => {
    const dispatch = useDispatch();
    const managerUser = useSelector(state => state.user);
    const managedCompanies = useSelector(state => state.managedCompanies);
    const [companyId, setCompanyId] = useState('');
    const {user, queryParams} = props;

    useEffect(() => {
        if (4 === user.roleCode && 1 === getCognitoUserRole(managerUser)) {
            dispatch(getManagedUserCompany(user, queryParams, dispatch))
        }
        //eslint-disable-next-line
    }, []);

    if (4 !== user.roleCode || 1 !== getCognitoUserRole(managerUser)) {
      return <div></div>;
    }

    const addCompany = () => {
        if ('' === companyId) {
            return;
        }
        dispatch(userLinkToCompany(user, companyId, dispatch));
        setCompanyId('');
    };

    return (
        <div style={{padding: 16, width: '90%'}}>
            <div><Divider light /><div style={{margin: '16px 0 0'}}>Link to company:</div></div>
                <TextField
                  label="Company Id"
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  margin='normal'
                  value={companyId}
                  onChange={e => setCompanyId(e.target.value)}
                />
            <Button
                onClick={e => addCompany()}
                variant="contained"
                color="primary">
                Add Company
            </Button>
            {
                null !== managedCompanies && undefined !== managedCompanies[user.id] && 0 !== managedCompanies[user.id].length
                    ?   <div style={{marginTop: 24}}>
                            <div><Divider light /><div style={{margin: '16px 0'}}>User managed companies:</div></div>
                            {managedCompanies[user.id].list.map(company => {
                                return <Chip
                                    label={company.groupId || company.id}
                                    onDelete={e => dispatch(userUnlinkFromCompany(user, company.id, dispatch))}
                                    variant="outlined"
                                    style={{margin: 2}}
                                />
                            })}
                        </div>
                    : null
            }

        </div>
    )
};

export default ManageCompany;