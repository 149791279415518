import AuthApiClient from './AuthApiClient';

export default class CompanyBranchesApi extends AuthApiClient {
    getCompanyBranches(headers, companyId) {
        return this._sendAuthRequest(`/api/company/${companyId}/branches/`, 'GET', null, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    addCompanyBranch(headers, body) {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(`/api/branch/`, 'POST', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    deleteCompanyBranch(headers, id) {
        headers = headers ?? {};

        return this._sendAuthRequest(`/api/branch/${id}`, 'DELETE', null, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

}