import React from "react";
import { Button} from "@material-ui/core";

const ClearButton = ({handleClearClick}) => {
  return (
    <div
      style={{
        width: 50,
        height: 30,
        marginLeft: 10,
        marginRight: 30,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={(e) => handleClearClick(e)}
      >
        Clear
      </Button>
    </div>
  );
};

export default ClearButton;
