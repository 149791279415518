import React, {createRef} from "react";
import {Box} from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';
import {uploadArea} from '../../../css/styles';

const UploadFileArea = (props) => {
    const styles = uploadArea();
    const [hover, setHover] = React.useState(false);
    const fileInput = createRef();

    const {uploadFile} = props;

    if(undefined !== props.default && true === props.default) {
        return  (<Box
                    style={{ border: '2px dashed #aaaaaa', background: '#ffffff' }}
                    className={styles.wrapper}
                    onClick={e =>  uploadFile(e)}
                    onDragOver={(e) => {
                        e.preventDefault();
                    }}
                    onDrop={(e) => {
                        e.preventDefault();
                        uploadFile(e)
                    }}
                >
            <div
                className={styles.text}
                style={{ color: '#aaaaaa'}}>
                <BackupIcon />
                Drag and drop files here!
            </div>
                </Box>)
    }



    return <Box
        onDragEnter={() => setHover(true)}
        onDragLeave={() => setHover(false)}
        onClick={e => fileInput.current.click()}
        onDragOver={(e) => {
            e.preventDefault();
        }}
        onDrop={(e) => {
            e.preventDefault();
            setHover(false);
            [...e.dataTransfer.files]
                .forEach(file => uploadFile(file, e))
        }}
        className={styles.wrapper}
        style={{
            border: (hover === false) ? '2px dashed #89bfba' : '2px dashed #0e9096',
            background: (hover === false) ? '#ffffff' : '#f5fffe'
        }}
    >
        <div
            className={styles.text}
            style={{ color: (hover === false) ? '#89bfba' : '#0e9096'}}>
            <BackupIcon />
            Drag and drop files here!
        </div>
        <input type='file' style={{display: 'none'}} onChange={e => uploadFile(e.target.files[0], e)} ref={fileInput} />
    </Box>
};

export default UploadFileArea;