import {actionTypes} from '../actions/actionTypes';
import CompanyLogoApi from "../Api/CompanyLogoApi";
import {getCompanyLogo, setCompanyLogo, getWLCompanyLogo} from "../actions/actions"
const _companyLogoApi = new CompanyLogoApi();
 
export default function logo(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_COMPANY_LOGO:
             _companyLogoApi.getCompanyLogo({})
                 .then(data => {
                     action.dispatch(setCompanyLogo(data));
                     return data;
            })
            .catch(e => console.log(e));
             return state;

        case actionTypes.GET_WLCOMPANY_LOGO:
             _companyLogoApi.getWLCompanyLogo({}, action.companyId)
                 .then(data => {
                     action.dispatch(setCompanyLogo(data));
                     return data;
            })
            .catch(e => console.log(e));
             return state;


        case actionTypes.SET_COMPANY_LOGO:
            let newState = action.logo;
            if (null === state) {
                newState.show = true;
            } else {
                newState.show = !state.show;
            }
            return newState;

        case actionTypes.UPLOAD_COMPANY_LOGO:
            _companyLogoApi.uploadCompanyLogo(
        {},
                action.logo
            ).then(data => {
                    action.dispatch(getCompanyLogo(action.dispatch));
                    return state;
            })
            .catch(e => console.log(e));

            return state;

        case actionTypes.UPLOAD_WLCOMPANY_LOGO:
            _companyLogoApi.uploadWLCompanyLogo(
        {},
                action.logo,
                action.companyId
            ).then(data => {
                    action.dispatch(getWLCompanyLogo(action.companyId, action.dispatch));
                    return state;
            })
            .catch(e => console.log(e));

            return state;

        default:
            break;
    }

    return state;
};