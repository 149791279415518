import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import {popup_close, popupUserList} from '../../actions/actions';
import {popupTypes} from "../../helper/popupTypes";


const Popup = (props) => {
    const dispatch = useDispatch();
    const popup = useSelector(state => state.popup);


    const backOnClick = () => {
        if ((popupTypes.addNewUser === popup.type || popupTypes.manageBranches === popup.type) && undefined !== popup.companyId ) {
            if ('' === popup.companyId) {
                dispatch(popup_close());
                return;
            }
            dispatch(popupUserList(popup.companyId))
        } else if (popupTypes.updateUser === popup.type && undefined !== popup.user) {
            dispatch(popupUserList(popup.user.companyId));
        } else {
            dispatch(popup_close())
        }
    };

    return <Box style={{color: '#000000'}}>
                <Box style={{display: 'flex', justifyContent: 'space-between', marginBottom: 30}}>
                    <div onClick={e => backOnClick()}
                         style={{cursor: 'pointer', display: 'flex', alignItems: 'flex-start', width: 70, justifyContent: 'space-between'}}>
                        <KeyboardBackspaceIcon />back
                    </div>
                    <div
                        onClick={e => dispatch(popup_close())}
                        style={{cursor: 'pointer'}}><CloseIcon /></div>
                </Box>
                {props.children}
            </Box>
};

export default Popup;