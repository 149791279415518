import React, {useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import SvgIcon from '@material-ui/core/SvgIcon';
import {getNavList} from './helper/helper';
import {Box} from "@material-ui/core";
import {navigation, navigationmobile} from '../../css/styles'
import {useSelector} from "react-redux";
import {getCognitoUserRole} from "../../helper/userHelper";

const NavBarHeader = (props) => {
    const location = useLocation();
    const user = useSelector(state => state.user);
    const styles = props.mobile ? navigationmobile() : navigation();
    const whiteLabel = useSelector((state) => state.whiteLabel);
    const isWLLoading = useSelector((state) => state.wlLoader);
    const headerFontColor = useSelector(state => state.colors?.colors?.headerFontColor);
    const companyAccess = useSelector(state => state.companyAccess);

    const [hover, setHover] = useState(false)

    const isActive = (link) => location.pathname === link;
    const list = undefined !== user.attributes ? getNavList(getCognitoUserRole(user)) : [];
    const color = (link) => (hover === link || isActive(link)) ? '#12525f' : '#fff';

return (<Box style={{color: '#000000'}}>
            <div className={styles.root}>
                {list.map(item => {
                   return <Link style={{textDecoration: 'none'}} to={item.link} key={item.link}>
                       { isWLLoading ? <div></div> : <div className={styles.btn} onMouseEnter={e=> setHover(item.link)} onMouseLeave={e=> setHover(false)}>
                           <SvgIcon className={isActive(item.link) ? styles.iconActive : styles.icon} style={{fontSize: 20, color:companyAccess==="whiteLabel" && whiteLabel ? headerFontColor : color(item.link)}} component={item.icon} />
                           <span className={isActive(item.link) ? styles.textActive : styles.text} style={{color: companyAccess==="whiteLabel" && whiteLabel ? headerFontColor : color(item.link)}}>{item.name}</span>
                       </div>}
                   </Link>
                })}
            </div>
        </Box>)
};

export default NavBarHeader;