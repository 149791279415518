import {actionTypes} from '../actions/actionTypes';
import CompanyUsersApi from '../Api/CompanyUsersApi';
import {
    setCompanyUsers,
    getCompanyUsers,
    loader_remove,
    exeption_add,
    exeption_close,
    popup_close,
    getCompanyList,
    popupUserList,
    getCompanyUsersBranch
} from "../actions/actions";
import {getCognitoUserRole, getCognitoUserId} from '../helper/userHelper';
const _companyUsersApi = new CompanyUsersApi();

export default function companyUsers(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_COMPANY_USERS:

            _companyUsersApi.getCompanyUsers({}, action.companyId, action.body)
                .then(data => {
                    action.dispatch(setCompanyUsers(data.data, action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => {
                    action.dispatch(exeption_add(e));
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.GET_COMPANY_USERS_BRANCH:

            _companyUsersApi.getCompanyUsersBranch({}, action.userId, action.body)
                .then(data => {
                    action.dispatch(setCompanyUsers(data.data, action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => {
                    action.dispatch(exeption_add(e));
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.SET_COMPANY_USERS:
            return action.users;

        case actionTypes.DELETE_COMPANY_USERS:
            _companyUsersApi.deleteUser({}, action.userId)
                .then(data => {

                    action.dispatch(getCompanyUsers(action.companyId, action.body, action.dispatch ));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => {
                    action.dispatch(exeption_add(e));
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.DISABLE_COMPANY_USERS:
            _companyUsersApi.disableUser({}, action.userId)
                .then(data => {
                    if (5 === getCognitoUserRole(action.userAdmin) || 6 === getCognitoUserRole(action.userAdmin)) {
                        action.dispatch(getCompanyUsersBranch(getCognitoUserId(action.userAdmin),{limit: 10, skip: 0}, action.dispatch));
                    } else {
                        action.dispatch(getCompanyUsers(action.companyId, action.body, action.dispatch));
                    }
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => {
                    action.dispatch(exeption_add(e));
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.ENABLE_COMPANY_USERS:
            _companyUsersApi.enableUser({}, action.userId)
                .then(data => {
                    if (5 === getCognitoUserRole(action.userAdmin) || 6 === getCognitoUserRole(action.userAdmin)) {
                        action.dispatch(getCompanyUsersBranch(getCognitoUserId(action.userAdmin),{limit: 10, skip: 0}, action.dispatch));
                    } else {
                        action.dispatch(getCompanyUsers(action.companyId, action.body, action.dispatch));
                    }
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => {
                    action.dispatch(exeption_add(e));
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;


        case actionTypes.UPDATE_COMPANY_USER:
            _companyUsersApi.updateUser({}, action.userId, action.user)
                .then(data => {
                    if (5 === getCognitoUserRole(action.userAdmin) || 6 === getCognitoUserRole(action.userAdmin)) {
                        action.dispatch(getCompanyUsersBranch(getCognitoUserId(action.userAdmin),{limit: 10, skip: 0}, action.dispatch));
                    } else {
                        action.dispatch(getCompanyUsers(action.companyId, action.body, action.dispatch));
                    }
                    action.dispatch(popupUserList(action.companyId));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => {
                    if (undefined !== e.error) {
                        action.dispatch(exeption_add(e.error));
                    } else {
                        action.dispatch(exeption_add(e));
                    }
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.ADD_NEW_USER:
            _companyUsersApi.addNewUser({},  action.user)
                .then(data => {
                    if ('' === action.user.companyId || action.popupId === undefined || action.popupId === '') {
                        action.dispatch(popup_close());
                        action.dispatch(getCompanyList({limit: 10, skip: 0}, action.dispatch))
                    }
                    else if ('' !== action.user.companyId){
                        action.dispatch(popupUserList(action.user.companyId));
                    } else {
                        action.dispatch(popup_close());
                    }

                    if(!!action.userAdmin && 5 <= getCognitoUserRole(action.userAdmin)) {
                        action.dispatch(getCompanyUsersBranch(getCognitoUserId(action.userAdmin),{limit: 10, skip: 0}, action.dispatch));
                    }

                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());

                })
                .catch(e => {
                    if (undefined !== e.error) {
                        action.dispatch(exeption_add(e.error));
                    } else if (e.DataValidationError === "Signup to company with free access error") {
                        action.dispatch(exeption_add({name: "SignupToExistingCompanyWithFreeAccessError"}));
                    } else {
                        action.dispatch(exeption_add(e));
                    }
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.USER_LINK_TO_BRANCH:
            _companyUsersApi.userLinkToBranch(
                {},
                action.user.id,
                {branchId: action.branchId}
                )
                .then(data => {
                    action.dispatch(getCompanyUsers(action.user.companyId, action.queryParams, action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(err => {
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_add(err));
                    return state;
                });

            return state;

        case actionTypes.USER_UNLINK_FROM_BRANCH:
            _companyUsersApi.userUnlinkToBranch(
                {},
                action.user.id,
                {branchId: action.branchId}
            )
                .then(data => {
                    action.dispatch(getCompanyUsers(action.user.companyId, action.queryParams, action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return state;
                })
                .catch(err => {
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return state;
                });
            return state;


        default:
            break;
    }

    return state;
};