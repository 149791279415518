import React, {useEffect} from "react";
import {Box, Typography, Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {routes} from "../../../route/routes";
import {grey} from '@material-ui/core/colors';
import {signOut} from '../../../actions/actions';
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";


const ThankYou = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const companyDetails = useSelector(state => state.whiteLabel);

  useEffect(() => {
      dispatch(signOut(dispatch, history));
      //eslint-disable-next-line
  }, [user])

    return (<Box mt={5} style={{color: grey[700], textAlign: 'center'}}>
        <Typography variant="h4" >
            Thank You!
        </Typography>
        <div> For visiting {companyDetails?.companyName || companyDetails?.name || 'us'}</div>
        <Link style={{textDecoration: 'none'}} to={routes.LOGIN}>
            <Button variant="contained" style={{backgroundColor: '#02c9e3', color: '#ffffff', marginTop: 20}}>Back to Sign In</Button>
        </Link>
    </Box>)
};

export default ThankYou;