import React from "react";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {useSelector} from "react-redux";

const LoginHeaderMessages = (props) => {
    const exeption = useSelector(state => state.exeption);
    const companyDetails = useSelector(state => state.whiteLabel);
    
    const messageCondition = `Log into ${companyDetails?.companyName || companyDetails?.name || 'eSimplify Solution'}`

    let message = { 
        message: messageCondition,
        error: false};

    if(null !== props.params) {
        if(props.params.verified) {
            message = {
                message: 'Your account is verified',
                error: false};
        }
        if(props.params.verificationFailed) {
            message = {
                message: 'Something went wrong. Please, contact support about this issue: support@esimplifysolutions.com',
                error: true};
        }
    };

    if(null !== exeption && 'login' === exeption.code) {
        message = {
            message: exeption.message,
            error: true
        }
    };

    let bgColor = message.error ? '#e53935' : '#02c9e3';

    return (<div className='loin-text-info' >
                <AccountCircleIcon style={{ color: bgColor, fontSize: 30, marginRight: 16 }} />
                <p>{message.message}</p>
            </div>);

};

export default LoginHeaderMessages;