import { actionTypes } from './actionTypes';

export const register = (identity, attributeList, dispatch) => ({
    type: actionTypes.SIGN_UP_REQUEST,
    identity: identity,
    attributeList: attributeList,
    dispatch: dispatch
});

export const signin = (identity, dispatch, history) => ({
    type: actionTypes.SIGN_IN_REQUEST,
    identity: identity,
    dispatch: dispatch,
    history: history
});

export const requestAccountRecovery = (username, dispatch) => ({
    type: actionTypes.ACCOUNT_RECOVERY_REQUEST,
    username: username,
    dispatch
});

export const requestAccountRecoverySuccess = () => ({
    type: actionTypes.ACCOUNT_RECOVERY_REQUEST_SUCCESS
});

export const passwordReset = (actionPayload, dispatch) => ({
    type: actionTypes.ACCOUNT_RECOVERY_PASSWORD_RESET,
    ...actionPayload,
    dispatch
});

export const clearRecoveryPasswordState = (dispatch) => ({
    type: actionTypes.RECOVERY_PASSWORD_CLEAR_STATE,
    dispatch: dispatch
});

export const accountRecoveryResetPasswordSuccess = () => ({
    type: actionTypes.ACCOUNT_RECOVERY_PASSWORD_RESET_SUCCESS
});

export const exeption_add = (err) => ({
    type: actionTypes.EXEPTION_ADD,
    err: err
});

export const exeption_close = () => ({
    type: actionTypes.EXEPTION_CLOSE
});

export const notification_add = (msg) => ({
    type: actionTypes.NOTIFICATION_ADD,
    msg: msg
});

export const notification_close = () => ({
    type: actionTypes.NOTIFICATION_CLOSE
});

export const getUser = (dispatch) => ({
    type: actionTypes.GET_USER,
    dispatch: dispatch
});

export const setUser = (user) => ({
    type: actionTypes.SET_USER,
    user: user
});

export const setColor = (colors) => ({
    type: actionTypes.SET_COLOR,
    colors
});

export const confirmSignUp = (userName, code, dispatch) => ({
    type: actionTypes.CONFIRM_SIGN_UP,
    userName: userName,
    code: code,
    dispatch: dispatch
});

export const signOut = (dispatch, history) => ({
    type: actionTypes.SIGN_OUT,
    dispatch: dispatch,
    history: history
});

export const preferredMFA = (mfa) => ({type: actionTypes.GET_PREFERRED_MFA, mfa: mfa});

export const getQrUrl = (user, dispatch) => ({type: actionTypes.GET_QR_URL, user: user, dispatch: dispatch});
export const setQrUrl = (str) => ({type: actionTypes.SET_QR_URL, str: str});
export const setMfaKey = (key, value) => ({type: actionTypes.SET_KEY_MFA, key, value});
export const totpVerify = (user, code, dispatch, history) => ({
    type: actionTypes.TOTP_VERIFY,
    user: user,
    code: code,
    dispatch: dispatch,
    history: history
});

export const loginConfirm = (user, code, history, dispatch) => ({
    type: actionTypes.LOGIN_CONFIRM,
    user: user,
    code: code,
    history: history,
    dispatch: dispatch
});
export const loginConfirmSelectMfa = (user, code, mfaType, history, dispatch) => ({
    type: actionTypes.LOGIN_CONFIRM_SELECT_MFA,
    user: user,
    code: code,
    mfaType: mfaType,
    history: history,
    dispatch: dispatch
});

export const sendNewPassword = (user, newPassword, history, dispatch) => ({
    type: actionTypes.NEW_PASSWORD_CONFIRM,
    user: user,
    newPassword: newPassword,
    history: history,
    dispatch: dispatch
});

export const sendVerificationCodeToPhoneNumber = (dispatch) => ({
    type: actionTypes.GET_PHONE_VERIFICATION_CODE,
    dispatch: dispatch
});

export const setPreferedMfaSms = (user, dispatch) => ({
    type: actionTypes.SET_PREFERRED_MFA_SMS,
    user: user,
    dispatch: dispatch
});

export const setPreferedMfaTotp = (user, dispatch) => ({
    type: actionTypes.SET_PREFERRED_MFA_TOTP,
    user: user,
    dispatch: dispatch
});

export const sendPhoneVerificationCode = (code, user, dispatch, history) => ({
    type: actionTypes.SEND_PHONE_VERIFICATION_CODE,
    user: user,
    code: code,
    dispatch: dispatch,
    history: history
});

export const sendPhoneCorfirmationCode = (code, user, dispatch, history, setup) => ({
    type: actionTypes.SEND_PHONE_CONFIRMATION_CODE,
    code,
    user,
    dispatch,
    history,
    setup
});

export const setMfaSetupStatus = (setupStatus) => ({
    type: actionTypes.SET_MFA_SETUP_STATUS,
    setupStatus
});

export const resetMfaSetupStatus = () => ({
    type: actionTypes.RESET_MFA_SETUP_STATUS
});

export const sendMFASelectionAnswer = (user, mfaType, dispatch) => ({
    type: actionTypes.SEND_MFA_SELECTION_ANSWER,
    user: user,
    mfaType: mfaType,
    dispatch: dispatch
});


export const userMfaWasSet = (dispatch, bothMfaWasSet) => ({
    type: actionTypes.USER_MFA_WAS_SET,
    dispatch,
    bothMfaWasSet
});

export const loader_add = () => ({type: actionTypes.LOADER_ADD});
export const wlLoader_add = () => ({type: actionTypes.WL_LOADER_ADD});

export const loader_remove = () => ({type: actionTypes.LOADER_REMOVE});
export const wlLoader_remove = () => ({type: actionTypes.WL_LOADER_REMOVE});


export const sendNews = (formData, dispatch) => ({
    type: actionTypes.SEND_NEWS,
    formData: formData,
    dispatch: dispatch
});
export const getNews = (dispatch) => ({type: actionTypes.GET_NEWS, dispatch: dispatch});
export const setNews = (news) => ({type: actionTypes.SET_NEWS, news: news});

export const getCompanyLogo = (dispatch) => ({
    type: actionTypes.GET_COMPANY_LOGO,
    dispatch: dispatch
});

export const getWLCompanyLogo = (companyId, dispatch) => ({
    type: actionTypes.GET_WLCOMPANY_LOGO,
    companyId,
    dispatch: dispatch
});

export const uploadCompanyLogo = (logo, dispatch) => ({
    type: actionTypes.UPLOAD_COMPANY_LOGO,
    logo: logo,
    dispatch: dispatch
});

export const uploadWLCompanyLogo = (logo, companyId, dispatch) => ({
    type: actionTypes.UPLOAD_WLCOMPANY_LOGO,
    logo: logo,
    companyId,
    dispatch: dispatch
});

export const setCompanyLogo = (logo) => ({
    type: actionTypes.SET_COMPANY_LOGO,
    logo: logo
});

export const setNewSubdomain = (subdomain) => ({
    type: actionTypes.SET_NEW_SUBDOMAIN,
    subdomain: subdomain
});

export const updateCompanyUrl= (companyId, subdomain, dispatch) => ({
    type: actionTypes.UPDATE_COMPANY_URL,
    companyId: companyId,
    subdomain: subdomain,
    dispatch: dispatch
});

export const updateWhiteLabelColor= (companyId, color, dispatch) => ({
    type: actionTypes.UPDATE_WHITE_LABEL_COLOR,
    companyId: companyId,
    color: color,
    dispatch: dispatch
});

export const getHomepageLink = (dispatch) => ({
    type: actionTypes.GET_HOMEPAGE_LINK,
    dispatch: dispatch
});

export const createHomepageLink = (link, dispatch) => ({
    type: actionTypes.CREATE_HOMEPAGE_LINK,
    dispatch: dispatch,
    link: link
});

export const deleteHomepageLink = (id, dispatch) => ({
    type: actionTypes.DELETE_HOMEPAGE_LINK,
    id: id,
    dispatch: dispatch
});

export const editHomepageLink = (link, id, dispatch) => ({
    type: actionTypes.EDIT_HOMEPAGE_LINK,
    link: link,
    id: id,
    dispatch: dispatch
});

export const setHomepageLink = (links) => ({
    type: actionTypes.SET_HOMEPAGE_LINK,
    links: links
});

export const sendDashboardClaims = (formData, dispatch, companyId) => ({
    type: actionTypes.SEND_DASHBOARD_CLAIMS,
    formData: formData,
    dispatch: dispatch,
    companyId: companyId
});

export const sendPatientDemographics = (formData, dispatch, companyId) => ({
    type: actionTypes.SEND_PATIENT_DEMOGRAPHICS,
    formData: formData,
    dispatch: dispatch,
    companyId: companyId
});

export const setImportDataNotification = (typeNotfication, massage) => ({
    type: actionTypes.SET_IMPORT_NOTIFICATION,
    typeNotification: typeNotfication,
    massage: massage
});

export const setImportInProgressFlag = () => ({
    type: actionTypes.SET_IMPORT_IN_PROGRESS_FLAG,
});

export const removeImportInProgressFlag = () => ({
    type: actionTypes.REMOVE_IMPORT_IN_PROGRESS_FLAG,
});

export const removeImportDataNotification = () => ({
    type: actionTypes.REMOVE_IMPORT_NOTIFICATION
});

export const setS3FieldsForRequest = (data, dispatch) => ({
    type: actionTypes.SET_S3_FIELDS_FOR_REQUEST,
    data: data,
    dispatch: dispatch
});

export const sendFileToS3 = (dispatch) => ({
    type: actionTypes.SEND_FILE_TO_S3,
    dispatch: dispatch
});
export const getEmbededDashboardUrl = (dispatch, dashboardNameFilter=null, query=false) => ({
    type: actionTypes.GET_EMBEDED_DASHBOARD_URL,
    dispatch: dispatch,
    dashboardNameFilter: dashboardNameFilter,
    query:query
});

export const  setEmbededDashboardUrl = (data, dispatch) => ({
    type: actionTypes.SET_EMBEDED_DASHBOARD_URL,
    data: data,
    dispatch: dispatch
});

export const getEmbededDashboardsNames = (dispatch) => ({
    type: actionTypes.GET_EMBEDED_DASHBOARDS_NAMES,
    dispatch: dispatch,
});

export const  setEmbededDashboardsNames = (data, dispatch) => ({
    type: actionTypes.SET_EMBEDED_DASHBOARDS_NAMES,
    data: data,
    dispatch: dispatch
});


export const getCompanyList = (body, dispatch) => ({
    type: actionTypes.GET_COMPANY_LIST,
    body: body,
    dispatch: dispatch
});

export const setCompanyList = (companyList, dispatch) => ({
    type: actionTypes.SET_COMPANY_LIST,
    companyList: companyList,
    dispatch: dispatch
});

export const getAdminCompanyList = (body, dispatch) => ({
    type: actionTypes.GET_ADMIN_COMPANY_LIST,
    body: body,
    dispatch: dispatch
});

export const setAdminCompanyList = (companyList, dispatch) => ({
    type: actionTypes.SET_ADMIN_COMPANY_LIST,
    companyList: companyList,
    dispatch: dispatch
});

export const getCompanyUsers = (companyId, body, dispatch) => ({
    type: actionTypes.GET_COMPANY_USERS,
    companyId: companyId,
    body: body,
    dispatch: dispatch
});

export const getCompanyUsersBranch = (userId, body, dispatch) => ({
    type: actionTypes.GET_COMPANY_USERS_BRANCH,
    userId: userId,
    body: body,
    dispatch: dispatch
});

export const setCompanyUsers = (users, dispatch) => ({
    type: actionTypes.SET_COMPANY_USERS,
    users: users,
    dispatch: dispatch
});

export const deleteCompanyUsers = (userId, companyId, body, dispatch) => ({
    type: actionTypes.DELETE_COMPANY_USERS,
    userId: userId,
    companyId: companyId,
    body: body,
    dispatch: dispatch
});

export const disableUser = (userId, companyId, body, dispatch, userAdmin) => ({
    type: actionTypes.DISABLE_COMPANY_USERS,
    userId: userId,
    companyId: companyId,
    body: body,
    dispatch: dispatch,
    userAdmin: userAdmin
});

export const enableUser = (userId, companyId, body, dispatch, userAdmin) => ({
    type: actionTypes.ENABLE_COMPANY_USERS,
    userId: userId,
    companyId: companyId,
    body: body,
    dispatch: dispatch,
    userAdmin: userAdmin
});


export const updateUser = (userId, companyId, user, body, dispatch, userAdmin) => ({
    type: actionTypes.UPDATE_COMPANY_USER,
    userId: userId,
    companyId: companyId,
    user: user,
    body: body,
    dispatch: dispatch,
    userAdmin: userAdmin
});

export const getCompanyBranches = (companyId, dispatch) => ({
    type: actionTypes.GET_COMPANY_BRANCHES,
    companyId,
    dispatch
});

export const getCompany = (companyId, dispatch) => ({
    type: actionTypes.GET_COMPANY,
    companyId,
    dispatch
});

export const getUserCompany = (companyId, dispatch) => ({
    type: actionTypes.GET_USER_COMPANY,
    companyId,
    dispatch
});


export const getCompanyWhiteLabel = (companyId, companyAccess, whiteLabel, dispatch) => ({
    type: actionTypes.GET_COMPANY_WHITE_LABEL,
    companyId,
    companyAccess,
    whiteLabel,
    dispatch
});

export const setCompany = (company) => ({
    type: actionTypes.SET_COMPANY,
    company,
});

export const setUserCompany = (company) => ({
    type: actionTypes.SET_USER_COMPANY,
    company,
});
export const setCompanyWhiteLabel = (companyWL) => ({
    type: actionTypes.SET_COMPANY_WHITE_LABEL,
    companyWL,
});
export const setCompanyBranches = (branches) => ({
    type: actionTypes.SET_COMPANY_BRANCHES,
    branches
});
export const addCompanyBranches = (name, companyId, dispatch) => ({
    type: actionTypes.ADD_COMPANY_BRANCH,
    name,
    companyId,
    dispatch
});
export const deleteCompanyBranch = (branchId, companyId, dispatch) => ({
    type: actionTypes.DELETE_COMPANY_BRANCH,
    branchId,
    companyId,
    dispatch
});


export const addNewUser = (user, dispatch, popupId, userAdmin) => ({
    type: actionTypes.ADD_NEW_USER,
    user: user,
    dispatch: dispatch,
    popupId: popupId,
    userAdmin: userAdmin
});

export const userLinkToBranch = (user, branchId, queryParams, dispatch) => ({
    type: actionTypes.USER_LINK_TO_BRANCH,
    user,
    branchId,
    queryParams,
    dispatch
});

export const userUnlinkFromBranch = (user, branchId, queryParams, dispatch) => ({
    type: actionTypes.USER_UNLINK_FROM_BRANCH,
    user,
    branchId,
    queryParams,
    dispatch
});

export const userLinkToCompany = (user, companyId, dispatch) => ({
    type: actionTypes.USER_LINK_TO_COMPANY,
    user,
    companyId,
    dispatch
});

export const userUnlinkFromCompany = (user, companyId, dispatch) => ({
    type: actionTypes.USER_UNLINK_FROM_COMPANY,
    user,
    companyId,
    dispatch
});

export const getManagedUserCompany = (user, filters, dispatch) => ({
    type: actionTypes.GET_MANAGED_USER_COMPANY,
    user,
    filters,
    dispatch
});

export const setManagedUserCompany = (companies, id, dispatch) => ({
    type: actionTypes.SET_MANAGED_USER_COMPANY,
    companies,
    id,
    dispatch
});

export const getWhiteLableSettings = (domain, dispatch) => ({
    type: actionTypes.GET_WHITE_LABEL_SETTINGS,
    domain,
    dispatch
});

 
export const popupAddNewUser = (companyId) => ({type: actionTypes.POPUP_ADD_NEW_USER, companyId});
export const popupManageBranches = (companyId) => ({type: actionTypes.POPUP_MANAGE_BRANCHES, companyId});
export const popupUpdateUser = (user, queryParams) => ({type: actionTypes.POPUP_UPDATE_USER, user, queryParams});
export const popupUserList = (id) => ({type: actionTypes.POPUP_USER_LIST, id: id});
export const popup_close = () => ({type: actionTypes.POPUP_CLOSE});

export const mfaSetupToLocalStorage = (key, value) => ({type: actionTypes.MFA_SETUP_TO_LOCALSTORAGE, key, value});
export const removeMfaSetupToLocalStorage = () => ({type: actionTypes.REMOVE_MFA_FROM_LOCALSTORAGE});

export const setFilters = (filters) => ({type: actionTypes.COMPANY_LIST_FILTERS, filters: filters});
export const setCompanyAccess = (access) => ({type: actionTypes.SET_COMPANY_ACCESS, access});
export const updateCompanyStatus = (companyId, queryParams,params, dispatch) => ({type: actionTypes.UPDATE_COMPANY_STATUS, companyId, queryParams, params, dispatch});
export const setLogoutStatus = (status) => ({type: actionTypes.SET_LOGOUT_STATUS, status: status});
export const setSubdomainStatus = (subdomainStatus) => ({type: actionTypes.SET_SUBDOMAIN_STATUS, subdomainStatus});
export const updateCompanyName = (companyId, companyName, dispatch) => ({type: actionTypes.UPDATE_COMPANY_NAME, companyId, companyName, dispatch});
export const setCompanyDetails = (payload) => ({type: actionTypes.SET_COMPANY_DETAILS, payload});


 





