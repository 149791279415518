import React from "react";
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import { Paper, Tab, Tabs} from '@material-ui/core';
import {forgotPassword} from '../../css/styles';

export default () => {
    const styles = forgotPassword();
    return (
        <Paper className={styles.root}>
            <Tabs
                value={0}
                indicatorColor="primary"
                textColor="primary"
                centered={true}
            >
                <Tab label="Forgot Password"/>
            </Tabs>
            <div className={styles.content}>
            <ForgotPasswordForm />
            </div>
        </Paper>
    )
}