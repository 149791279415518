import React from "react";
import {FormControl, InputLabel, Select} from '@material-ui/core'
import {useDispatch} from "react-redux";
import {removeImportDataNotification, setS3FieldsForRequest} from '../../../../actions/actions';

const SelectType = (props) => {
    const dispatch = useDispatch();

    const onChangeSelect = (e) => {
        props.setTypeValue(e.target.value);
        dispatch(removeImportDataNotification());
        dispatch(dispatch(setS3FieldsForRequest(null, dispatch)))
    };


    return (
        <FormControl style={{marginRight: 8}}>
            <InputLabel htmlFor="import-data-file-type">Import file</InputLabel>
            <Select
                native
                onChange={e => onChangeSelect(e)}
                inputProps={{
                    name: 'file-type',
                    id: 'import-data-file-type',
                }}
            >
                <option aria-label="None" value='' />
                <option value={'patient'}>Patient demographics</option>
                <option value={'claims'}>Claims</option>
            </Select>
        </FormControl>
    )
};

export default SelectType;