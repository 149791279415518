import React from "react";
import {foterLinks} from './helper';
import {Box, Container, Grid} from "@material-ui/core";
import { useSelector } from "react-redux";

const MobileFooter = (props) => {
    const companyAccess = useSelector(state => state.companyAccess);
    const bgcolor = useSelector(state => state.colors?.colors?.footerColor);
    const footerTextColor =useSelector(state => state.colors?.colors?.footerFontColor);

    return (
        <Box>
            <Container style={{color: '#000000', background: companyAccess==="whiteLabel" && bgcolor ? bgcolor : '#383939'}}>
                <Grid container spacing={3} style={{padding: '16px 0'}}>
                    {companyAccess === null? null:
                     companyAccess!=="whiteLabel" ?
                        foterLinks.map(parentLink => {
                            return (<Grid key={parentLink.link} item xs={12} sm={6} style={{ padding: 0}}>
                                    <div style={{padding: '8px 0'}}><a style={{color: '#d3d3d3', fontWeight: 600, textDecoration: 'none'}} href={parentLink.link}>{parentLink.name}</a></div>
                                    {parentLink.children ? parentLink.children.map(link => <div key={link.link} style={{padding: '8px 0'}}><a style={{color: '#d3d3d3', fontWeight: 300, textDecoration: 'none'}} href={link.link}>{link.name}</a></div>) : null}
                                </Grid>

                            )
                        }) : 
                        <div style={{ textDecoration: 'none', color:footerTextColor ? footerTextColor :'#d3d3d3', fontWeight: 300, margin: "0 auto", padding: "15px"}}
                        >
                            Powered by eSimplify
                        </div>
                        }
                </Grid>
            </Container>
        </Box>
    )
};

export default MobileFooter;