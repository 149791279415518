import React from "react";
import {useDispatch} from "react-redux";

import {popupUpdateUser} from "../../../../../actions/actions";

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {Tooltip} from "@material-ui/core";


const UpdateUser = (props) => {
    const dispatch = useDispatch();

    const {user, queryParams} = props;

    return (
            <Tooltip title="Update User" placement="top" arrow>
                <div onClick={e =>  dispatch(popupUpdateUser(user, queryParams))} style={{display: 'flex'}}>
                    <EditRoundedIcon  style={{margin: 0, width: 50}}/>
                    <span>Update User</span>
                </div>
            </Tooltip>
    )
};

export default UpdateUser;