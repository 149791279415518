import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import {
    exeption_add, exeption_close,
    getEmbededDashboardUrl,
    loader_add,
    notification_close
} from '../../../../actions/actions';
import {useDispatch} from "react-redux";
import { embedDashboard  } from 'amazon-quicksight-embedding-sdk';
import EmbededDashboard from './EmbededDashboard'
import Search from './Search';
import DashboardSelect from './DashboardSelect';

const Dashboard = () => {
    let dashboard;
    const dispatch = useDispatch();

    const onDashboardLoad = (payload) => {
        dispatch(notification_close());
        dispatch(exeption_close());
    };

    const onError = (payload) => {
        dispatch(exeption_add({name: 'err'}));
    };

    const embedDashboardInit = (url) => {
        const containerDiv = document.getElementById("dashbordtest");
        containerDiv.innerHTML = '';
        const options = {
            url: url,
            container: containerDiv,
            parameters: {
                country: "United States",
                states: "[ALL]"
            },
            scrolling: "no",
            height: "AutoFit",
            loadingHeight: "450px",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: true,
            defaultEmbeddingVisualType: 'TABLE',
            printEnabled: true
        };
        dashboard = embedDashboard(options);
        dashboard.on("error", onError);
        dashboard.on("load", onDashboardLoad);
    };

    useEffect(() => {
        dispatch(getEmbededDashboardUrl(dispatch));
        dispatch(loader_add());
    }, [dispatch]);

 

    return <Box style={{color: '#000000'}}>
                 <Search/>  
                 <DashboardSelect/>     
                 <EmbededDashboard embedDashboardInit={embedDashboardInit}/>
            </Box>
};

export default Dashboard;