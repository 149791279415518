import React from 'react';
import {useDispatch} from "react-redux";
import {exeption_close} from '../../actions/actions';
import CancelIcon from '@material-ui/icons/Cancel';


const ExeptionPopup = (props) => {
    const dispatch = useDispatch();
    return (
        <div className="exeption-popup-wrapper">
            <div>{props.exeption.message}</div>
            <div onClick={e => {dispatch(exeption_close())}}>
                <CancelIcon className='close' />
            </div>
        </div>
    );
};
export default ExeptionPopup;