import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Container} from "@material-ui/core";
import PatientDemographics from './importData/PatientDemographics';
import Claims from "./importData/Claims";
import SelectType from './importData/SelectType';
import UploadFileArea from "./UploadFileArea";
import Notification from "./importData/Notification";
import Example from "./Example";
import {
    setImportDataNotification,
    sendFileToS3,
    setS3FieldsForRequest,
    loader_remove,
    removeImportInProgressFlag,
    removeImportDataNotification,
    notification_close,
    notification_add
} from "../../../actions/actions";
import {signUpKeys} from '../../forms/helper/attributesKey';
import {routes} from '../../../route/routes';
import { disconnectSocket, initiateSocket, subscribeTo } from "../../../socket/socket";


const ImportData = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [typeValue, setTypeValue] = useState(false);
    const importNotification = useSelector(state => state.importNotification);
    const isImportInProgress = useSelector(state => state.dashboard.importInProgress);
    const user = useSelector(state => state.user);
    const importS3 = useSelector(state => state.importS3);

    useEffect(() => {
        const handleBeforeunload = (event) => {
            const returnValue = 'If you leave page, import process will be canceled';
            
            // Chrome requires `returnValue` to be set.
            if (event.defaultPrevented) {
                event.returnValue = '';
            }
            
            if (typeof returnValue === 'string') {
                event.returnValue = returnValue;
                return returnValue;
            }
        };
        
        if (isImportInProgress) {
            window.addEventListener('beforeunload', handleBeforeunload);
        }
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeunload);
        };
      }, [isImportInProgress]);

    useEffect(() => {
        const userId = user && user.signInUserSession.idToken.payload.email;

        if (userId) {
            initiateSocket(userId)
        };

        subscribeTo('import.finish', (err, data) => {
            if (err) {
                return;
            }

            dispatch(setS3FieldsForRequest(null, dispatch));
            dispatch(removeImportDataNotification());
            dispatch(notification_add('Data imported successfully'));
            dispatch(loader_remove());
            dispatch(removeImportInProgressFlag());
        });
        
        subscribeTo('import.fail', (err, data) => {
            if (err) {
                console.log({ err });
                return;
            }

            dispatch(setImportDataNotification('exaption', {message: 'Oops!  There appears to be something wrong.  Please try to upload again, if this problem persists, you can send the file to this secure location and the eSimplify team will manually move the file.'}));
            dispatch(setS3FieldsForRequest(data.data, dispatch));
            dispatch(loader_remove());
            dispatch(removeImportInProgressFlag());
            dispatch(notification_close());
        });
        
        subscribeTo('import.client-error', (err, data) => {
            if (err) {
                console.log({ err });
                return;
            }
            
            dispatch(setS3FieldsForRequest(null, dispatch));
            dispatch(setImportDataNotification('exaption', data.data))
            dispatch(loader_remove());
            dispatch(removeImportInProgressFlag());
        });
        
        return () => {
            disconnectSocket();
        }
      }, [user, dispatch]);

    useEffect(() => {
        if(null !== user) {
            if(undefined !== user.attributes && '3' === user.attributes[signUpKeys.DESIRED_ROLE.name]) {
                history.push(routes.MAIN_PAIGE);
            }
        }
        //eslint-disable-next-line
    }, []);


    const uploadFile = (e) => {
        dispatch(setImportDataNotification('exaption', {details: [{message: 'Please, select type of imported data'}]}))
    };

    return <Container maxWidth={"xl"} style={{color: '#000000'}}>
                <div style={{display: 'flex', alignItems: 'flex-end', marginBottom: 30}}>
                    <SelectType setTypeValue={setTypeValue}/>
                    {false === typeValue || '' === typeValue ? <div style={{marginLeft: 16}}>Please, select type of imported data</div> : null}
                    {false !== typeValue && '' !== typeValue ? <Example typeValue={typeValue}/> : null}
                </div>
                {null !== importNotification ? <Notification notification={importNotification}/> : null}
                {null !== importS3
                    ? <Box display="flex" alignItems="center" mt={2} mb={2}>
                        <Button
                            onClick={e => dispatch(sendFileToS3(dispatch))}
                            variant="contained"
                            style={{
                                backgroundColor: '#02c9e3',
                                color: '#ffffff'
                            }}>
                            send
                        </Button>
                        <Box ml={2} style={{color: '#ababab', fontSize: 14}}>Send file to secure location</Box>
                    </Box>
                    : null}
                {false === typeValue || '' === typeValue ? <UploadFileArea uploadFile={uploadFile} default={true} /> : null}
                {'claims' === typeValue ? <Claims /> : null}
                {'patient' === typeValue ? <PatientDemographics /> : null}
            </Container>
};

export default ImportData;