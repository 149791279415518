import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../../actions/actions";
import { signUpKeys } from "../../forms/helper/attributesKey";
import UploadFile from "./UploadFile";
import NewsCard from "./NewsCard";
import { Typography } from '@material-ui/core';
import Example from './Example';

const News = () => {
    const dispatch = useDispatch();
    const news = useSelector(state => state.news);
    const user = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getNews(dispatch));
    }, [dispatch]);

    const checkPermission = user?.attributes &&
        (user.attributes[signUpKeys.DESIRED_ROLE.name] === '1' || user.attributes[signUpKeys.DESIRED_ROLE.name] === '7')

    if (news?.data?.length || checkPermission) {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', padding: 20 }}>
                    <Typography variant="h5" component="h5">News</Typography>
                    {checkPermission ? <Example typeValue={'news'} /> : null}
                </div>
                {checkPermission ? <UploadFile /> : null}
                {news?.data?.length ? news.data.reverse().map((item, index) => <NewsCard key={item.title + index} item={item} />) : null}
            </div>
        )
    }

    return <div></div>;
};

export default News;