import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom'
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import logo from "../../img/esimplify.svg";
import NavBarHeader from "./NavBarHeade";
import {headerLogoStyles} from '../../css/styles'
import {loader_add, signOut} from "../../actions/actions";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';


const MobileHeader = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = headerLogoStyles();
    const [isOpen, setOpen] = useState(false);
    const companyAccess = useSelector(state => state.companyAccess);
    const bgcolor = useSelector(state => state.colors?.colors?.headerColor);
    const headerFontColor = useSelector(state => state.colors?.colors?.headerFontColor);
    const isLoading = useSelector((state) => state.wlLoader);

    const logout = (e) => {
        dispatch(loader_add());
        dispatch(signOut(dispatch, history));
    };

    if(isLoading) return <div></div>


    return ( <Box width="100%" bgcolor={companyAccess==="whiteLabel" && bgcolor ? bgcolor : "#02c9e3"}>
            <Container  style={{color: '#000000', width: '95%'}}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                {isLoading ? null: companyAccess!=="whiteLabel" && 
                <div className={classes.root}><img className={classes.logo} src={logo} alt="logo"/> </div>}
                </Box>
            </Container>
        <Container style={{color: '#000000', width: '95%'}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{borderBottom: '1px solid #78dcea', padding: '8px 0'}}>
                {isOpen
                    ? <CloseIcon onClick={e => setOpen(false)} fontSize="small" />
                    : <MenuIcon onClick={e => setOpen(true)} fontSize="small" /> }
                <Box style={{cursor: 'pointer', color: companyAccess==="whiteLabel" && headerFontColor ? headerFontColor : "#ffffff"}} onClick={e => logout(e)}>Logout</Box>
            </Box>
            {isOpen ? <NavBarHeader mobile={props.mobile}/> : null}
        </Container>
        </Box>)
};

export default MobileHeader;