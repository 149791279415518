import React from "react";
import {Paper, Tab, Tabs} from "@material-ui/core";
import SignUpForm from '../forms/SignUpForm';
import {useStyles} from "../../css/styles";


const AddUserPopup = (props) => {
    const classes = useStyles();
    const {companyBranches} = props;

    return <Paper className={classes.root}>
            <Tabs
                value={0}
                indicatorColor="primary"
                textColor="primary"
                centered={true}
            >
                <Tab label="New User"/>
            </Tabs>
            <div className={classes.content}>
                <SignUpForm type='addUser' companyBranches={companyBranches}/>
            </div>
        </Paper>

};

export default AddUserPopup;