import {actionTypes} from '../actions/actionTypes';
import {exeption_close, loader_remove, setEmbededDashboardUrl} from '../actions/actions';
import EmbededDashboardApi from "../Api/EmbededDashboardApi";
const _embededDashboardApi = new EmbededDashboardApi();

export default function embededDashboard(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_EMBEDED_DASHBOARD_URL:
            _embededDashboardApi.getEmbededDashboardUrl({}, action.dashboardNameFilter, action.query)
                .then(data => {
                    action.dispatch(setEmbededDashboardUrl(data.data, action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return state;
                })
                .catch(e => {
                    if(undefined !== e.DashboardNotFoundError) {
                        action.dispatch(setEmbededDashboardUrl("You don't have any dashboards yet", action.dispatch));
                    } else {
                        action.dispatch(setEmbededDashboardUrl("Something went wrong", action.dispatch));
                    }
                    action.dispatch(loader_remove());
                    return state;
                });
            return 'Please wait, for dashboard refresh';

        case actionTypes.SET_EMBEDED_DASHBOARD_URL:
            return action.data;



        default:
            break;
    }

    return state;
};