import React, { useEffect, useState } from "react";
import {inputStyles} from "../../../css/styles";
import TOTPSetup from "./TOTPSetup";
import SMSSetup from "./SMSSetup";
import { Button, Tab, Tabs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { resetMfaSetupStatus, setPreferedMfaSms, setPreferedMfaTotp, userMfaWasSet } from "../../../actions/actions";
import { useHistory } from "react-router-dom";
import { routes } from "../../../route/routes";


const SetupMFA = (props) => {
    const inputStyle = inputStyles();
    const user = useSelector(state => state.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const mfaSetupStatus = useSelector(state => state.mfa.setupStatus);
    const [tab, setTab] = useState(0);
    
    const handleTabChange = (_, value) => {
        setTab(value);
    };

    useEffect(() => {
        if (mfaSetupStatus.totpConfigured && mfaSetupStatus.smsConfigured) {
            // console.log('before userMfaWasSet(dispatch, true)');
            dispatch(userMfaWasSet(dispatch, true));
            // console.log('before getUser(dispatch)');
            // dispatch(getUser(dispatch));
            // console.log('before history.push(routes.MAIN_PAIGE);');
            history.push(routes.MAIN_PAIGE);
            dispatch(resetMfaSetupStatus());
        }
    }, [mfaSetupStatus, dispatch, history]);

    const submitMfaSetup = () => {
        if (!(mfaSetupStatus.totpConfigured || mfaSetupStatus.smsConfigured)) return;

        if (mfaSetupStatus.totpConfigured && !mfaSetupStatus.smsConfigured) {
            dispatch(setPreferedMfaTotp(user, dispatch));
            dispatch(userMfaWasSet(dispatch, false));
        }

        if (mfaSetupStatus.smsConfigured && !mfaSetupStatus.totpConfigured) {
            dispatch(setPreferedMfaSms(user, dispatch));
            dispatch(userMfaWasSet(dispatch, false));
        }

        if (mfaSetupStatus.smsConfigured && mfaSetupStatus.totpConfigured) {
            dispatch(userMfaWasSet(dispatch, true));
        }

        history.push(routes.MAIN_PAIGE);
    }
    
    return (
        <>
            <p>
                For safety reasons we require multi-factor authentication on each sign in.<br />
                You need to set up <b>at least one type of MFA</b>: TOTP or SMS.<br />
                You can set up both of them, if you wish. <br />
                In this case, you will be able to choose preferred type on each sign in.
            </p>

            <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered={true}
            >
                <Tab label="Setup TOTP MFA"/>
                <Tab label="Setup SMS MFA"/>
            </Tabs>
            <TabPanel value={tab} index={0}>
                {mfaSetupStatus.totpConfigured && (
                    <>
                        <p>TOTP code was successfully confirmed. You can either finish by clicking button bellow or setup SMS MFA.</p>
                        <Button
                            onClick={() => submitMfaSetup()}
                            variant="contained"
                            style={{
                                backgroundColor: '#02c9e3',
                                color: '#ffffff'
                            }}>
                            Finish
                        </Button>
                    </>
                )}
                {!mfaSetupStatus.totpConfigured && (
                    <>
                        <div style={{marginBottom: 16}}>
                            <div className='loin-text-info' >
                                <p>With TOTP 2-Step Verification, 
                                    signing into your account will require both your password and 
                                    a verification code you can generate with Google Authenticator app.
                                    Once configured, you can get verification codes without the need for a network or cellular connection.
                                </p>
                            </div>
                            <div style={{marginBottom: 8}}>Download Google Authenticator:</div>
                            <div className={inputStyle.back} >
                                <a target="_blank" rel="noopener noreferrer"
                                 href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'>
                                    Google play: Google Authenticator
                                </a>
                            </div>
                            <div className={inputStyle.back} >
                                <a target="_blank" rel="noopener noreferrer"
                                 href='https://apps.apple.com/app/google-authenticator/id388497605'>
                                    App Store: Google Authenticator
                                </a>
                            </div>
                        </div>
                        <TOTPSetup />
                    </>
                )}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {mfaSetupStatus.smsConfigured && (
                    <>
                        <p>SMS code was successfully confirmed. You can either finish by clicking button bellow or setup TOTP MFA.</p>
                        <Button
                            onClick={() => submitMfaSetup()}
                            variant="contained"
                            style={{
                                backgroundColor: '#02c9e3',
                                color: '#ffffff'
                            }}>
                            Finish
                        </Button>
                    </>
                )}
                {!mfaSetupStatus.smsConfigured && (
                    <>
                        <div className='loin-text-info' >
                            <p>You can also use your phone number to get verification codes verification for your account</p>
                        </div>
                        <p className="err-massage">
                            Some mobile carriers (for example TMobile and Sprint) block certain SMS (messages).
                            If you use one of these carriers, 
                            chose the Google Authenticator authentication method otherwise you will not be able to complete the registration.
                        </p>
                        <SMSSetup setup={true}/>
                    </>
                )}
            </TabPanel>
        </>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

export default SetupMFA;