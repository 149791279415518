import React from "react";
import {Container} from "@material-ui/core";
import News from "./News";
import HomePageLink from "./HomePageLink"
import {useSelector} from "react-redux";

const Home = () => {
    const user = useSelector(state => state.user);

    if(null === user) {
        return <div></div>;
    }

    return <Container maxWidth={"xl"} style={{color: '#000000'}}>
                {/* <UpdateSubdomainForm/> */}
                <HomePageLink />
                <News/>
            </Container>
};

export default Home;