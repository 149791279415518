import {actionTypes} from '../actions/actionTypes';

import WhiteLabelApi from '../Api/WhiteLabelApi';
import {
  setCompanyWhiteLabel,
  setNewSubdomain,
  exeption_add,
  exeption_close,
  wlLoader_remove,
  setColor,
  setCompanyAccess,
  setCompanyDetails
} from "../actions/actions";


const _whiteLabelApi = new WhiteLabelApi();

export default function whiteLabel(state = null, action) {
    switch (action.type) { 
      case actionTypes.SET_COMPANY_WHITE_LABEL:
        return action.companyWL;

          case actionTypes.UPDATE_COMPANY_URL:
          _whiteLabelApi.updateCompanyUrl({}, action.companyId, action.subdomain, action.dispatch)
                .then(data => {
                  if(data && data.data && data.data.message==="Subdomain was successfully set"){
                    action.dispatch(setNewSubdomain(action.subdomain));
                  }
                    return data;
                }) 
                .catch(err => {
                  console.log(err)
                  action.dispatch(exeption_add(err));
                  return state;
                });
            return state;

            case actionTypes.UPDATE_WHITE_LABEL_COLOR:
              _whiteLabelApi.updateWhiteLableColor({}, action.companyId, action.color)
                    .then(data => {
                        return data;
                    }) 
                    .catch(err => {
                      action.dispatch(exeption_add(err));
                      return state;
                    });
                return state;
    
  
          case actionTypes.GET_COMPANY_WHITE_LABEL:
            _whiteLabelApi.getCompanyWhiteLabel({}, action.companyId, action.dispatch)
                  .then(data => {
                    const wlData = data.data;
                    const headerColor = wlData.headerColor;
                    const footerColor = wlData.footerColor;
                    const headerFontColor = wlData.headerFontColor;
                    const footerFontColor = wlData.footerFontColor;
                      action.dispatch(setCompanyDetails(wlData))
                      action.dispatch(setCompanyWhiteLabel(wlData));
                      action.dispatch(setColor({headerColor, footerColor, headerFontColor, footerFontColor}));
                      action.dispatch(exeption_close());
                      if(action.companyAccess){
                        action.dispatch(wlLoader_remove());
                      }
                      return data;
                  })
                  .catch(err => {
                    console.log(err)
                    action.dispatch(wlLoader_remove());
                      return state;
                  });
                  return state;

         case actionTypes.GET_WHITE_LABEL_SETTINGS:
           if(action.domain===undefined) return state;
            _whiteLabelApi.getWhiteLableSettings({}, action.domain, action.dispatch)
                  .then(data => {
                    const wlData = data.data;
                    const headerColor = wlData.headerColor;
                    const footerColor = wlData.footerColor;
                    const headerFontColor = wlData.headerFontColor;
                    const footerFontColor = wlData.footerFontColor;
                      action.dispatch(setCompanyWhiteLabel(wlData));
                      action.dispatch(setColor({headerColor, footerColor, headerFontColor, footerFontColor}));
                      action.dispatch(exeption_close());
                      action.dispatch(setCompanyAccess("whiteLabel"))

                      action.dispatch(wlLoader_remove());
                      return data;
                  })
                  .catch(err => {
                    console.log(err)
                    action.dispatch(wlLoader_remove());
                      return state;
                  });
              return state; 

        default:
            break;
    }

    return state;
}