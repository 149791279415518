import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, TextField, Chip} from "@material-ui/core";
import {getCompanyBranches, addCompanyBranches, deleteCompanyBranch} from "../../actions/actions";

import {manageBranch} from '../../css/styles';


const BranchPopup = () => {
    const classes = manageBranch();
    const dispatch = useDispatch();
    const popup = useSelector(state => state.popup);
    const companyBranches = useSelector(state => state.companyBranches);
    const [branchName, setBranchName] = useState('');

    useEffect(() => {
        if(popup.companyId) {
            dispatch(getCompanyBranches(popup.companyId, dispatch));
        }
        //eslint-disable-next-line
    }, []);

    const addBranch = () => {
        dispatch(addCompanyBranches(branchName, popup.companyId, dispatch));
        setBranchName('')
    };


    return (
        <div>
            <TextField
                value={branchName}
                name="location"
                label="Location"
                margin="normal"
                variant="outlined"
                required
                fullWidth={true}
                onChange={e => setBranchName(e.target.value)}
            />
            <Button
                onClick={e => addBranch()}
                variant="contained"
                color="primary"
                className={classes.btn}>
                Add Location
            </Button>
            {null !== companyBranches ? <div className={classes.title}>Company Locations:</div> : null}
            {null !== companyBranches ?
                <div className={classes.root}> {companyBranches.map(item => <Chip
                    label={item.location}
                    onDelete={e => dispatch(deleteCompanyBranch(item.id, popup.companyId, dispatch))}
                    variant="outlined"
                /> )} </div>
                : null

            }
        </div>
    );
};

export default BranchPopup;