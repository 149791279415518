import {actionTypes} from '../actions/actionTypes';
import {setManagedUserCompany, getManagedUserCompany, exeption_add, exeption_close} from '../actions/actions';
import {getCognitoUserId} from '../helper/userHelper'
import ManagedCompanyApi from '../Api/ManagedCompanyApi';
const _managedCompanyApi = new ManagedCompanyApi();

export default function managedCompanies(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_MANAGED_USER_COMPANY:
            let userId = undefined !== action.user.id ? action.user.id : getCognitoUserId(action.user);
            _managedCompanyApi.getManagedCompany({}, action.filters, userId)
                .then(data => {
                    action.dispatch(setManagedUserCompany(data.data, userId, action.dispatch));
                    return state;
                })
                .catch(err => {
                    action.dispatch(setManagedUserCompany([], userId, action.dispatch));
                    return state;
                });

            return state;

        case actionTypes.SET_MANAGED_USER_COMPANY:
            let newState = state ? {...state} : {};
            return {...newState, [action.id]: action.companies};

        case actionTypes.USER_LINK_TO_COMPANY:
            _managedCompanyApi.userLinkToCompany({}, {companyId: action.companyId}, action.user.id)
                .then(data => {
                    action.dispatch(getManagedUserCompany(action.user, action.filters, action.dispatch));
                    action.dispatch(exeption_close());
                    return state;
                })
                .catch(err => {
                    action.dispatch(exeption_add(err));
                    return state;
                });

            return state;

        case actionTypes.USER_UNLINK_FROM_COMPANY:
            _managedCompanyApi.userUnlinkFromCompany({}, {companyId: action.companyId}, action.user.id)
                .then(data => {
                    action.dispatch(getManagedUserCompany(action.user, action.filters, action.dispatch));
                    return state;
                })
                .catch(err => {
                    return state;
                });

            return state;

        default:
            break;
    }

    return state;
};