import React, {useEffect, useState} from "react";
import {Box, Button, TextField} from "@material-ui/core";
import QRCode from 'qrcode.react';
import {useHistory} from 'react-router-dom';
import {getQrUrl, loader_add, totpVerify} from "../../../actions/actions";
import {useDispatch, useSelector} from "react-redux";

const TOTPSetup = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.user);
    const mfa = useSelector(state => state.mfa);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (undefined === mfa.qrUrl && 'not authenticated' !== user && "NOMFA" === user.preferredMFA) {
            dispatch(getQrUrl(user, dispatch));
        }
        //eslint-disable-next-line
    }, []);

    const getQRCode = () => {
        if (undefined !== mfa.qrUrl) {
            return <QRCode value={mfa.qrUrl} />;
        }
        return '';
    };

    const confirm = () => {
        dispatch(totpVerify(user, code, dispatch, history));
        dispatch(loader_add());
    };

    return <div>
                <div>{getQRCode()}</div>
                <TextField
                    name='Code'
                    label='Code'
                    variant="outlined"
                    fullWidth={true}
                    margin="normal"
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={e => setCode(e.target.value)}
                />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
                <Button
                    onClick={e => confirm()}
                    variant="contained"
                    style={{
                        backgroundColor: '#02c9e3',
                        color: '#ffffff'
                    }}>
                    Confirm
                </Button>
            </Box>
        </div>
};

export default TOTPSetup;