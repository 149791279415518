import {actionTypes} from '../actions/actionTypes';

import CompanyApi from '../Api/CompanyApi';
import {
    setUserCompany,
    loader_remove,
} from "../actions/actions";

const _companyApi = new CompanyApi();

export default function userCompany(state = null, action) {
    switch (action.type) {

        case actionTypes.SET_USER_COMPANY:
            return action.company;

        case actionTypes.GET_USER_COMPANY:
            _companyApi.getCompany({}, action.companyId)
                .then(data => {
                    action.dispatch(setUserCompany(data.data));
                    action.dispatch(loader_remove())
                    return data.data;
                })
                .catch(err => {
                    action.dispatch(setUserCompany(null));
                    action.dispatch(loader_remove())
                    return state;
                });
            return state;
        
        default:
            break;
    }

    return state;
}