import React from "react";
import {Paper, Tab, Tabs} from "@material-ui/core";
import BranchPopup from './BranchPopup';
import {useStyles} from "../../css/styles";

const ManageBranchesPopup = () => {
    const classes = useStyles();

    return <Paper className={classes.root}>
        <Tabs
            value={0}
            indicatorColor="primary"
            textColor="primary"
            centered={true}
        >
            <Tab label="Manage Location"/>
        </Tabs>
        <div className={classes.content}>
            <BranchPopup />
        </div>
    </Paper>
};

export default ManageBranchesPopup;
