import React, {useState} from "react";
import UploadFileArea from "../UploadFileArea";
import {useDispatch} from "react-redux";
import {
    loader_add,
    removeImportDataNotification,
    sendPatientDemographics,
    setImportInProgressFlag,
    setS3FieldsForRequest
} from "../../../../actions/actions";
import {getType} from './helper';
import CompanyIdInput from './CompanyIdInput'

const PatientDemographics = () => {
    const dispatch = useDispatch();
    const [companyId, setCompanyId] = useState('');

    const uploadFile = (file, e) => {
        const xlsxData = new Blob([file], {type: getType(file.name)});
        let formData = new FormData();
        formData.append("patientDemographics", xlsxData);
        dispatch(setImportInProgressFlag());
        dispatch(removeImportDataNotification());
        dispatch(sendPatientDemographics(formData, dispatch, companyId));
        dispatch(setS3FieldsForRequest(null, dispatch));
        dispatch(loader_add());
        e.target.value = null
        setCompanyId('');
    };

    return <div>
                <CompanyIdInput companyId={companyId} setCompanyId={setCompanyId}/>
                <UploadFileArea uploadFile={uploadFile}/>
            </div>
};

export default PatientDemographics;