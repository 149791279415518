import {actionTypes} from '../actions/actionTypes';
import {Auth} from "@aws-amplify/auth";
import {setUser} from '../actions/actions'


export default function user(state = null, action)
{
    switch (action.type) {
        case actionTypes.GET_USER:
            Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(user => {
                action.dispatch(setUser(user));

                return user;
            }).catch(e => {
                action.dispatch(setUser(e));
                console.log(e)
                return e;
            });

            return state;

        case actionTypes.SET_USER:
            return action.user;

        default:
            break;
    }

    return state;
};