import React, { useState } from "react";
import { Button } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useSelector } from "react-redux";
import { getCognitoUserRole } from "../../helper/userHelper";
import { UserRole } from "../../helper/userHelper";
import TabsButton from './changeColorTab'; 

const ChangeBgColorBtn = (props) => {
  const { user, handleChangeComplete, handleChangeCompleteFooter, headerColor, footerColor,
    handleChangeHeaderFontColor, handleChangeFooterFontColor, headerFontColor, footerFontColor,
    handleSaveChanges
  } = props;
  const [open, setOpen] = useState(false);
  const userRole = getCognitoUserRole(user);
  const companyAccess = useSelector(state => state.companyAccess);
  const btnTextColor = useSelector(state => state.colors?.colors?.headerFontColor);
  const whiteLabel = useSelector((state) => state.whiteLabel);
  const isLoading = useSelector((state) => state.wlLoader);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  if (
    userRole === UserRole.WhiteLabelAdmin
  ) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ position: "relative" }}>
          {isLoading ? null : 
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              color:companyAccess==="whiteLabel" && whiteLabel && btnTextColor ? btnTextColor : "#ffffff",
              marginRight: "20px",
              width: "30px",
              height: "35px",
              padding: "5px",
              lineHeight: "20px",
              fontSize: "16px",
              textTransform: "none"
            }}
  
          >
            Change color
          </Button>}
          {open ? (
            <div style={{ position: "absolute", top: "50px", right: "-20px", maxWidth: "400px", zIndex: 11, textAlign: "center",
              border: "1px solid #999", backgroundColor: "white"}}>
              <TabsButton 
                handleChangeComplete={handleChangeComplete}
                handleChangeCompleteFooter={handleChangeCompleteFooter}
                headerColor={headerColor}
                footerColor={footerColor}
                handleChangeHeaderFontColor={handleChangeHeaderFontColor}
                handleChangeFooterFontColor={handleChangeFooterFontColor}
                headerFontColor={headerFontColor}
                footerFontColor={footerFontColor}
               />
               <Button
                 onClick={handleSaveChanges}
                 style={{background: "#02c9e3", color: "#ffffff", marginBottom: "30px"}}
               >
                 Save
               </Button>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    );
  } else return null
};

export default ChangeBgColorBtn;
