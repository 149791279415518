import React from "react";
import Container from '@material-ui/core/Container';
import {contentWrapper} from '../../css/styles'
import { Route, Switch, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
import {routes} from '../../route/routes';
import SignUpSignInTab from '../forms/SignUpSignInTabs';
import Home from "./pages/Home";
import ForgotPasswordPage from  '../forgot-password/ForgotPasswordPage';
import Settings from './pages/Settings';
import ImportData from './pages/ImportData';
import Dashboard from "./pages/Dashboard/Dashboard";
import ThankYou from "./pages/ThankYou";
import NewPasswordForm from "../forms/NewPasswordForm";
import MultiFactorAuth from "../forms/multifactor-auth/MultiFactorAuth";
import {Box} from "@material-ui/core";


export default (props) => {
    const styles = contentWrapper();
    const user = useSelector(state => state.user);
    const isMfaSetuped = useSelector(state => state.isMfaSetup);
    if(user === null) {
        return <Container className={styles.root}><div></div></Container>;
    }

    const isMfaSetup = () => {
        if (undefined === user || undefined === user.attributes) {
            return false;
        }
        return (user.attributes['phone_number_verified'] || 
            (null !== isMfaSetuped && undefined !== isMfaSetuped.TOTP && undefined !== isMfaSetuped.SMS_MFA));
    };

    if (undefined !== user.preferredMFA && "NOMFA" === user.preferredMFA && false === isMfaSetup() ) {
        return <Container className={styles.root}>
                    <MultiFactorAuth />
                </Container>
    }

    return (
        <Box className={styles.root}>
            <Switch>
                <Route exact path={routes.MAIN_PAIGE}>
                    {('not authenticated' === user || (null !== user && false === user.userConfirmed))
                        ? <Redirect to={{
                            pathname: routes.LOGIN,
                            search: props.history.location.search,
                        }} />
                        : <Home/>}
                </Route>
                {('not authenticated' === user || (null !== user && false === user.userConfirmed))
                    ?  <Route exact path={routes.LOGIN} render={() => <SignUpSignInTab  history={props.history}/>}/>
                    : null}

                <Route exact path={routes.FORGOT_PASSWORD} history={props.history} component={ForgotPasswordPage} />
                <Route exact path={routes.NEW_PASSWORD} history={props.history} component={NewPasswordForm} />
                <Route exact path={routes.DASHBOARD}>
                    {('not authenticated' === user || (null !== user && false === user.userConfirmed))
                        ? <Redirect to={{
                            pathname: routes.LOGIN,
                            search: props.history.location.search,
                        }} />
                        : <Dashboard/>}
                </Route>
                <Route exact path={routes.IMPORT_DATA}>
                    {('not authenticated' === user || (null !== user && false === user.userConfirmed))
                        ? <Redirect to={{
                            pathname: routes.LOGIN,
                            search: props.history.location.search,
                        }} />
                        : <ImportData/>}
                </Route>
                <Route exact path={routes.SETTINGS}>
                    {('not authenticated' === user || (null !== user && false === user.userConfirmed))
                        ? <Redirect to={{
                            pathname: routes.LOGIN,
                            search: props.history.location.search,
                        }} />
                        : <Settings/>}
                </Route>
                    {('not authenticated' === user || 
                        (user && user.Session===null) || (user && user.Session===undefined) || user===null)
                    ? <Route  path={routes.THANK_YOU} component={ThankYou}/>
                    : null}
                <Route exact path={routes.SET_MULTIFACTOR_AUTH} component={MultiFactorAuth}></Route>
            </Switch>
        </Box>
    )
}
