export const routes = {
    MAIN_PAIGE: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    NEW_PASSWORD: '/new-password',
    DASHBOARD: '/dashboard',
    IMPORT_DATA: '/import-data',
    ACTION_ITEMS: '/action-items',
    SETTINGS: '/settings',
    THANK_YOU: '/thank-you',
    SET_MULTIFACTOR_AUTH: '/multifactor-auth'
};