import React, {useState} from "react";
import {Button, TextField} from "@material-ui/core";
import {loader_add, loginConfirm, wlLoader_add} from "../../../actions/actions";
import {inputStyles} from "../../../css/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

const LoginVerification = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const inputStyle = inputStyles();
    const user = useSelector(state => state.user);
    const [code, setCode] = useState('');


    const confirm = (e) => {
        e.preventDefault()
        dispatch(loginConfirm(user, code, history, dispatch));
        dispatch(loader_add());
        dispatch(wlLoader_add());
    };

    return   <div>
                <form noValidate autoComplete="off" onSubmit={confirm}>
                    <TextField
                        name='Code'
                        label='Code'
                        className={inputStyle.root}
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        autoFocus
                        required
                        onChange={e => setCode(e.target.value)}
                        onKeyUp={e => setCode(e.target.value)}
                    />
                    <Button
                        type='submit'
                        onClick={confirm}
                        variant="contained"
                        style={{
                            backgroundColor: '#02c9e3',
                            color: '#ffffff',
                            marginTop: 20
                        }}>
                        Confirm
                    </Button>
                </form>
            </div>;

};

export default LoginVerification;