import {signUpKeys} from '../components/forms/helper/attributesKey';

const getCognitoUserRole = (user) => {
    if(user && user.attributes){
        const role = user.attributes[signUpKeys.DESIRED_ROLE.name];
        if (typeof role === 'string') {
            return Number(role);
        }
        return role;    
    }
};

const getCognitoUserId = (user) => {
    if(user && user.attributes) return user.attributes['custom:userId'];
    
}

const getCognitoUserCompanyId = (user) => {
    if(user && user.attributes) return user.attributes['custom:groupId'];
}

const hideLinkToCompany = (role) => {
    return (1 === role || 2 === role || 4 === role || 3 === role);
};

const getUserRoleFromUserList = (user) => user['roleCode'];


const hideToolsForUser = (userfromList, user)  => {
   const userRole = getCognitoUserRole(user);
   const rolefromlist = getUserRoleFromUserList(userfromList);

   if (userRole === 2 && rolefromlist === 1 ) {
       return false;
   }
    if (userRole === 4 && rolefromlist === 1 ) {
        return false;
    }
    if (5 === userRole && rolefromlist < 5 && 3 !== rolefromlist) {
        return false;
    }

    if (6 === userRole && rolefromlist < 6 && 3 !== rolefromlist) {
        return false;
    }
   return true;
};


export const UserRole = {
    SuperAdmin: 1,
    GroupAdmin: 2,
    User: 3,
    AccountAdmin: 4,
    PracticeAdmin: 5,
    LocationAdmin: 6,
    WhiteLabelAdmin: 7
   }

export {
    getCognitoUserRole,
    getCognitoUserId,
    hideLinkToCompany,
    hideToolsForUser,
    getCognitoUserCompanyId
};