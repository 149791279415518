import {actionTypes} from '../actions/actionTypes';
import {getexeptionMSG, getexeptionMSGByName} from './helper/exeptionMassages';

export default function exeption(state = null, action) {
    switch (action.type) {
        case actionTypes.EXEPTION_ADD:
            if(undefined === action.err.code && undefined === action.err.name) {
                return {message: action.err.message || action.err.error || action.err.error.message || 'Something went wrong'};
            }
            if(undefined === action.err.code) {

                if(undefined !== action.err.DataValidationError) {
                    return {code: 'withoutCode', message: action.err.DataValidationError};
                }
                const exeptionMsg = getexeptionMSGByName(action.err);
                return exeptionMsg;
            }
            const msg = getexeptionMSG(action.err);

            if(msg === 'SERWER ERROR' && action.err.message.indexOf('<JSONError') === -1) {
                return {code: action.err.code, message: action.err.message};
            }
            if (msg === 'SERWER ERROR') {
                return {code: action.err.code, message: 'Something went wrong'};
            }

            return {code: action.err.code, message: msg};

        case actionTypes.EXEPTION_CLOSE:
            return null;

        default:
            break;
    }

    return state;

}