import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {loader} from '../../css/styles';

export default () => {
    const styles = loader();
    return (
        <div className={styles.root} >
            <CircularProgress disableShrink />
        </div>
    );
};