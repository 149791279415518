import React, {useEffect, useState} from "react";
import {Paper, Box} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getCompanyBranches, getCompany, getCompanyUsers, getCompanyUsersBranch, loader_add} from "../../../../actions/actions";
import {Pagination} from '@material-ui/lab';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {getheaderList} from "./tableComponents/helper";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Row from "./tableComponents/Row";
import AddUserButton from "./AddUserButton";
import ControlePanel from "./ControlPanel";
import PopupPages from "../../../popupPages/PopupPages";
import {popupTypes} from "../../../../helper/popupTypes";
import {getCognitoUserRole} from "../../../../helper/userHelper";

const CompanyUsers = (props) => {
    const dispatch = useDispatch();
    const companyUsers = useSelector(state => state.companyUsers);
    const user = useSelector(state => state.user);
    const roleCode = getCognitoUserRole(user);
    const popup = useSelector(state => state.popup);
    const filters = useSelector((state) => state.filters);
    const titles = getheaderList('userlist');
    const {userId, companyId} = props;
    const [page, setPage] = useState(1);
    const limit = 10

    const getSkip = () => {
        return 1 === page ? 0 : (page - 1) * limit;
    };

    const getQueryParams = (page) => {
        return {limit: limit, skip: 1 === page ? 0 : (page - 1) * limit, ...filters.filters};
    };

    const getPageCount = () => {
        const count = companyUsers.meta.totalAmount;
        return count % limit > 0 ? Math.floor(count / limit) + 1 : Math.floor(count / limit);
    };

    useEffect(() => {
        if (5 === roleCode ||  6 === roleCode) {
            dispatch(getCompanyUsersBranch(
                userId,
                {limit: limit, skip: getSkip(), ...filters.filters},
                dispatch
            ));
        } else {
            dispatch(getCompanyUsers(
                companyId,
                {limit: limit, skip: getSkip(), ...filters.filters},
                dispatch
            ));
        }
        dispatch(getCompanyBranches(companyId, dispatch));
        dispatch(loader_add());
        //eslint-disable-next-line
    }, [filters, companyId, popup ]);

    useEffect(() => {
        dispatch(getCompany(companyId, dispatch));
        //eslint-disable-next-line
    }, [])

    const handleChange = (event, value) => {
        setPage(value);
        if (5 === roleCode ||  6 === roleCode) {
            dispatch(getCompanyUsersBranch(
                userId,
                getQueryParams(value),
                dispatch
            ));
        } else {
            dispatch(getCompanyUsers(
                companyId,
                getQueryParams(value),
                dispatch
            ));
        }

        dispatch(loader_add());
    };

    if(null !== popup && popupTypes.userList !== popup.type) {
        return <PopupPages queryParams={getQueryParams(page)} />;
    }

    if(null === companyUsers || undefined === companyUsers.list || 0 === companyUsers.list.length) {
        return <div style={{display: "flex", alignItems: "center"}}><AddUserButton companyId={companyId}/>
                    <p style={{marginLeft: 30}}>There is not any user in the company.</p>
                </div>;
    }

    return <Box>
        <ControlePanel companyId={companyId}/>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Group NPI</TableCell>
                        {titles.map(title => <TableCell key={title.key} style={{paddingRight: "8px", paddingLeft: "8px"}}>{title.name}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companyUsers.list.map(user => {
                        return <Row key={user.id} titles={titles} queryParams={getQueryParams(page)} user={user}/>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
               {undefined === companyUsers.meta.totalAmount || companyUsers.meta.totalAmount < limit
                   ? null
                   : <Pagination
                       count={getPageCount()}
                       page={page}
                       onChange={handleChange}
                       style={{marginTop: 40}}/>
               }
            </Box>
};

export default CompanyUsers;