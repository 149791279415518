import React from "react";
import {exeption_add, loader_add, sendNews} from "../../../actions/actions";
import {useDispatch} from "react-redux";
import UploadFileArea from "./UploadFileArea";
import {getType} from "./importData/helper";

const UploadFile = () => {
    const dispatch = useDispatch();

    const uploadFile = (file, e) => {
        if(-1 === file.name.indexOf('.csv')) {
            dispatch(exeption_add({'DataValidationError': 'the file format should be csv'}));
            return;
        }
        const csvData = new Blob([file], {type: getType(file.name)});

        let formData = new FormData();
        formData.append("newsFile", csvData);
        dispatch(sendNews(formData, dispatch));
        dispatch(loader_add());
        e.target.value = null
    };

    return <UploadFileArea uploadFile={uploadFile}/>
};

export default UploadFile;