const exeptionMSG = [
    {
        code: 'SignupToExistingCompanyWithFreeAccessError',
        message: "You're trying to sign up for a company that is already registered in our system. Unfortunately, it has reached the limit of registered users. Please contact eSimplify Solutions to discuss adding additional users."
    },
    {
        code: 'DesiredRoleIsntAllowedError',
        message: "Desired role isn't allowed"
    },
    {
        code: 'CompanyWithGivenIdDoesntExistsError',
        message: "Company with given group id doesn't exist"
    },
    {
        code: 'UserWithGivenEmailAlreadyExistsError',
        message: "User with given email already exists"
    },
    {
        code: 'UserLambdaValidationException',
        message:'User with given NPI already exists'
    }

];

const exeptionMSGName = [
    {
        name: "UserWithGivenEmailAlreadyExistsError",
        message: "User with given email already exists"
    },
    {
        name: "UserWithGivenNpiAlreadyExistsError",
        message: "User with given NPI already exists",
    },
    {
        name: "SignupToExistingCompanyWithFreeAccessError",
        message: "You're trying to sign up for a company that is already registered in our system. Unfortunately, it has reached the limit of registered users. Please contact eSimplify Solutions to discuss adding additional users."
    },
    {
        name: "UserAlreadyLinkedError",
        message: "User already linked to this company"
    }
];



const getexeptionMSGByName = (err) => {
    if (err.name === "NotFoundError") {
        return {code: err.name, message: err.message}
    }

    const msg = exeptionMSGName.filter(exeption => exeption.name === err.name);
    if (0 >= msg.length) {
        return {code: 'SERWER ERROR', message: 'Something went wrong'};
    }
    return {code: msg[0].name, message: msg[0].message};
};


const getexeptionMSG = (err) => {
    const msg = exeptionMSG.filter(exeption => exeption.code === err.code || err.message.indexOf(exeption.code) !== -1);
    if (0 >= msg.length) {
        return 'SERWER ERROR';
    }
    return msg[0].message;
};

export {getexeptionMSG, getexeptionMSGByName};