import {actionTypes} from '../actions/actionTypes';

export default function importNotification(state =  null, action)
{

    switch (action.type) {

        case actionTypes.SET_IMPORT_NOTIFICATION:
            return {
                type : action.typeNotification,
                message: action.massage
            };

        case actionTypes.REMOVE_IMPORT_NOTIFICATION:
            return null;

        default:
            break;
    }
    return state;
};