import {actionTypes} from '../actions/actionTypes';
import NewsApiClient from "../Api/NewsApiClient";
import {exeption_add, exeption_close, getNews, loader_remove, setNews} from "../actions/actions";
const _newsApiClient = new NewsApiClient();

export default function news(state = null, action)
{
    switch (action.type) {
        case actionTypes.SEND_NEWS:
            _newsApiClient.sendNews(action.formData, {})
                .then(data => {
                    action.dispatch(getNews(action.dispatch));
                    action.dispatch(loader_remove());
                    action.dispatch(exeption_close());
                    return state;
                })
                .catch(e => {
                    action.dispatch(exeption_add(e));
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        case actionTypes.GET_NEWS:
            _newsApiClient.getNews({})
                .then(data => {
                    action.dispatch(setNews(data));
                    action.dispatch(exeption_close());
                    return data;
                })
                .catch(e => e);

            return state;

        case actionTypes.SET_NEWS:
            return action.news;

        default:
            break;
    }

    return state;
};