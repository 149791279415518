import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class NewsApiClient extends AuthApiClient {
    sendNews(body, headers)
    {
        return this._sendAuthRequest(routes.SEND_NEWS, 'POST', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    getNews(headers)
    {
        return this._sendAuthRequest(routes.GET_NEWS, 'GET', null,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }
}