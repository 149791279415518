import React, {useState} from "react";
import {Button, TextField} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {editHomepageLink, loader_add} from '../../../../actions/actions';
import {validation} from "./helper";

const EditLink = (props) => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState(props.url);
    const [title, setTitle] = useState(props.title);
    const [validationUrl, setvalidationUrl] = useState(null);
    const [validationTitle, setvalidationTitle] = useState(null);

    const submitLink = () => {

        let validUrl = validation.checkLink(url, setvalidationUrl);
        let validTitle = validation.checkTitle(title, setvalidationTitle);

        if(false === validUrl || false === validTitle) {
            return;
        }
            dispatch(editHomepageLink(
                {
                    "url": url,
                    "title": title
                },
                props.id,
                dispatch
            ));
            dispatch(loader_add());
            setUrl('');
            setTitle('');
            props.setEdit(false);

    };

    return <form noValidate autoComplete="off" style={{display: "flex", alignItems: 'flex-end'}}>
                <TextField
                    name='url'
                    label='Url'
                    value={url}
                    multiline
                    required
                    onClick={e => setUrl(e.target.value)}
                    onChange={e => setUrl(e.target.value)}
                    onKeyUp={e => setUrl(e.target.value)}
                    style={{margin: '0 8px'}}
                    helperText={null !== validationUrl ? validationUrl : ''}
                    error={null !== validationUrl ? true : false}
                />
                <TextField
                    name='title'
                    label='Title'
                    value={title}
                    multiline
                    required
                    onClick={e => setTitle(e.target.value)}
                    onChange={e => setTitle(e.target.value)}
                    onKeyUp={e => setTitle(e.target.value)}
                    style={{margin: '0 8px'}}
                    helperText={null !== validationTitle ? validationTitle : ''}
                    error={null !== validationTitle ? true : false}
                />
                <Button
                    onClick={e => submitLink()}
                    variant="contained"
                    size={"small"}
                    style={{
                        backgroundColor: '#02c9e3',
                        color: '#ffffff'
                    }}>
                    Send
                </Button>
            </form>
};

export default EditLink;