import { createStore, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { getValueFromLocalStorage } from '../reducer/helper/localStorage'
import auth from '../reducer/auth';
import exeption from "../reducer/exeption";
import user from "../reducer/user";
import loader from '../reducer/loader';
import mfa from '../reducer/mfa'
import news from "../reducer/news";
import notification from "../reducer/notification";
import logo from "../reducer/logo";
import homepageLink from "../reducer/homepageLink";
import dashboard from '../reducer/dashboard';
import importNotification from "../reducer/importNotification";
import importS3 from "../reducer/importS3";
import embededDashboard from '../reducer/embededDashboard';
import companyList from '../reducer/companyList';
import companyUsers from '../reducer/companyUsers';
import popup from '../reducer/popup';
import companyBranches from '../reducer/companyBranches';
import company from '../reducer/company';
import managedCompanies from '../reducer/managedCompanies';
import isMfaSetup from '../reducer/isMfaSetup';
import filters from '../reducer/filters';
import whiteLabel from '../reducer/whiteLabel';
import companyAccess from '../reducer/companyAccess';
import colors from '../reducer/colors';
import newSubdomain from '../reducer/newSubdomain';
import wlLoader from '../reducer/wlLoader';
import embededDashboardsNames from '../reducer/embededDashboardsNames';
import logoutStatus from '../reducer/logoutStatus';
import isSubdomainWrong from '../reducer/isSubdomainWrong';
import userCompany from '../reducer/userCompany';
import whiteLabelInfo from '../reducer/whiteLabelInfo';
import adminCompanyList from '../reducer/adminCompanyList';

const browserHistory = createBrowserHistory();

const isMfaSetupStateFromLocalStorage = getValueFromLocalStorage('isMfaSetup', true);

export default () => {
    return {
        history: browserHistory,
        store: createStore(
            combineReducers({
                auth,
                exeption,
                notification,
                user,
                loader,
                mfa,
                news,
                logo,
                homepageLink,
                dashboard,
                importNotification,
                importS3,
                embededDashboard,
                companyList,
                companyUsers,
                popup,
                company,
                companyBranches,
                managedCompanies,
                isMfaSetup,
                filters,
                whiteLabel,
                companyAccess,
                colors,
                newSubdomain,
                wlLoader,
                embededDashboardsNames,
                logoutStatus,
                isSubdomainWrong,
                userCompany,
                whiteLabelInfo,
                adminCompanyList
            }),
            {
                isMfaSetup: null !== isMfaSetupStateFromLocalStorage ? isMfaSetupStateFromLocalStorage : {},
            },
            // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    };
};