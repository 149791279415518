import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class CompanyLogoApi extends AuthApiClient {
    getCompanyLogo(headers)
    {
        return this._sendAuthRequest(routes.GET_COMPANY_LOGO, 'GET', null,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    getWLCompanyLogo(headers, companyId)
    {
        return this._sendAuthRequest(`api/white-label/${companyId}/logo`, 'GET', null,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    uploadCompanyLogo(headers, body)
    {
        return this._sendAuthRequest(routes.GET_COMPANY_LOGO, 'POST', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    uploadWLCompanyLogo(headers, body, companyId)
    {
        return this._sendAuthRequest(`api/white-label/${companyId}/logo`, 'POST', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

}