import {actionTypes} from '../actions/actionTypes';

import CompanyApi from '../Api/CompanyApi';
import {
    setCompany,
    loader_remove,
    getCompanyList,
    wlLoader_remove

} from "../actions/actions";

const _companyApi = new CompanyApi();

export default function company(state = null, action) {
    switch (action.type) {

        case actionTypes.SET_COMPANY:
            return action.company;

        case actionTypes.GET_COMPANY:
            _companyApi.getCompany({}, action.companyId)
                .then(data => {
                    action.dispatch(setCompany(data.data));
                    action.dispatch(loader_remove())
                    return data.data;
                })
                .catch(err => {
                    action.dispatch(setCompany(null));
                    action.dispatch(loader_remove());
                    action.dispatch(wlLoader_remove())
                    return state;
                });
            return state;
        
            case actionTypes.UPDATE_COMPANY_STATUS:
                _companyApi.updateCompanyStatus({}, action.companyId, action.queryParams)
                .then(data => {
                    action.dispatch(getCompanyList(action.params, action.dispatch))
                    return data;
                })
                .catch(err => {
                    console.log(err);
                    return state;
                });
                return state;

        default:
            break;
    }

    return state;
}