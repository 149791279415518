import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton } from "@material-ui/core";
import { getCognitoUserRole } from "../../../../helper/userHelper";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getEmbededDashboardUrl, loader_add } from "../../../../actions/actions";

const Search = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userRole = getCognitoUserRole(user);
  const [value, setValue] = useState("");

  useEffect(() => {
    if ("" === value) {
      dispatch(getEmbededDashboardUrl(dispatch));
      dispatch(loader_add());
    }
  }, [value, dispatch]);

  const removeFilters = () => {
    setValue("");
    dispatch(getEmbededDashboardUrl(dispatch));
    dispatch(loader_add());
  };

  const searchByNPI = () => {
    dispatch(getEmbededDashboardUrl(dispatch, {dashboardNameFilter: value}, true));
    dispatch(loader_add());
  };

  if (1 === userRole || 4 === userRole) {
    return (
      <div
        style={{
          margin: 20,
          marginTop: 0,
        }}
      >
        <form
          noValidate
          autoComplete="off"
          style={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            value={value}
            label="Company NPI"
            onChange={(e) => setValue(e.target.value)}
          />
          {"" !== value ? (
            <IconButton
              color="primary"
              aria-label="Search"
              component="span"
              onClick={(e) => removeFilters()}
            >
              <HighlightOffIcon />
            </IconButton>
          ) : null}
          <IconButton
            color="primary"
            aria-label="Search"
            component="span"
            onClick={(e) => searchByNPI()}
          >
            <SearchIcon />
          </IconButton>
        </form>
      </div>
    );
  }
  return <div></div>;
};

export default Search;
