import React, {useEffect, useState} from "react";
import {Grid, Container, Box, Paper, MenuList, MenuItem} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {foterLinks} from './helper';
import {footer} from '../../css/styles';
import MobileFooter from "./MobileFooter";
import { useSelector } from "react-redux";

const Footer = () => {
    const style = footer();
    const [mobile, setMobile] = useState(false);
    const [indexNav, setIndex] = useState(null);
    const companyAccess = useSelector(state => state.companyAccess);
    const bgcolor = useSelector(state => state.colors?.colors?.footerColor);
    const isWLLoading = useSelector((state) => state.wlLoader);
    const footerTextColor =useSelector(state => state.colors?.colors?.footerFontColor);

    useEffect(() => {
        if(769 >= window.innerWidth) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, []);

    if(isWLLoading) return <div></div>

    if(true === mobile) {
        return <MobileFooter mobile={mobile}/>
    }

    const makeLinks = (links) => {
        return (<Paper   elevation={1} className={style.popup}>
            <MenuList>
            {links.map(link => {
                return (<MenuItem key={link.link}>
                            <a href={link.link} style={{textDecoration: 'none'}} 
                                target='_blank' rel="noopener noreferrer">{link.name}</a>
                        </MenuItem>)
            })}
            </MenuList>
        </Paper>);
    };

    const rotate = (index) => {
        return index !== indexNav ? 'rotate(0deg)' : 'rotate(180deg)';
    };

    return (<Box  className={style.root} bgcolor={companyAccess==="whiteLabel" && bgcolor ? bgcolor : '#383939'}>
        <Container maxWidth="md" >
            <Grid container spacing={3} > 
                {isWLLoading? null:
                 companyAccess!=="whiteLabel" ? 
                    foterLinks.map((parentLink, index) => {
                        return (<Grid  key={parentLink.link} item xs={12} sm={6} md={3} style={{ padding: 0}}>
                            <div className={style.wrapBtnFooter}
                                 onMouseEnter={e => setIndex(index)}
                                 onMouseLeave={e => setIndex(null)}
                            >
                                {(parentLink.children && indexNav === index) ? makeLinks(parentLink.children) : null}
                                <div className={style.mainBtn}>
                                    {parentLink.children ? 
                                        <ExpandMoreIcon className={style.icon} style={{transform: rotate(index)}} /> 
                                    : null}
                                    <a style={{textDecoration: 'none', color: indexNav === index ? '#fff' : '#d3d3d3', fontWeight: 300}} 
                                        href={parentLink.link} target='_blank' rel="noopener noreferrer">{parentLink.name}</a>
                                </div>
                            </div>

                        </Grid>)
                    }) : 
                    <div style={{ textDecoration: 'none', color: footerTextColor ? footerTextColor :'#d3d3d3', fontWeight: 300, margin: "0 auto", padding: "15px"}}
                    >
                        Powered by eSimplify
                    </div>
                }
            </Grid>
        </Container>
    </Box>);


};
export default Footer;