
const buildMap = (keys) => {
    const attributesMap = {};
    Object.values(keys).map(item => {
        if (item.name !== keys.CONFIRM_PSW.name) {
            attributesMap[item.name] = '';
        }
        return item
    });
    return attributesMap;
};



const setDesiredRole = (state, keys) => {
    const USER_ROLE_CODE = '3';
    const ADMIN_ROLE_CODE = '2';

    const groupIdValue = state[keys.GROUP_ID.name];

    if (groupIdValue) {
        return USER_ROLE_CODE;
    }

    return ADMIN_ROLE_CODE;
};

export const helper = {
    prettyPhone: (value) => {
        if (value.length >= 12 && -1 !== value.indexOf(' ')) {
            value = value.replace(/\s+/g, '');
        }
        if (value.indexOf('+1') !== -1) {
            value = value.substring(2);
        }
        let char = value.slice(0, 1);
        if (char === '1' && value.length === 11) {
            value = value.substring(1);
        }
        return value;
    },

    getSignUpSketch: (signUpKeys) => {
        return buildMap(signUpKeys);
    },

    getIdentity: (state, keys) => {
        let identity = {
            username: state[keys.EMAIL.name],
            password: state[keys.PASSWORD.name]
        };
        return identity
    },

    getAttributesList: (state, keys) => {

        let attributeList = {};
        for (let key in state) {
            if (key !== keys.PASSWORD.name && 'undefined' !== key && key !== keys.CONFIRM_PSW.name) {

                attributeList[key] = state[key];
                if (key === keys.DESIRED_ROLE.name) {
                    attributeList[keys.DESIRED_ROLE.name] = setDesiredRole(state, keys);
                }
                if (key === keys.MOBILE_NUMBER.name) {
                    attributeList[keys.MOBILE_NUMBER.name] = '+1' + state[key];
                }
            }

        }
        return attributeList;
    },

    getAttributes: (state, keys, companyId, userRole) => {

        let attributeList = {};
        keys.forEach(key => {

            if (key.key === 'role') {
                let role = state[key.name] === '' ? 3 : Number(state[key.name]);
                attributeList["desiredRole"] = role;
            } else {
                attributeList[key.key] = state[key.name];
            }
            if (key.key === 'phoneNumber') {
                attributeList[key.key] = '+1' + state[key.name];
            }
            if (key.key === 'branchId') {
                attributeList[key.key] = state[key.key];
            }
            if (key.key === 'companyId') {
                attributeList[key.key] = state[key.key];
            }
        });
        if (companyId) { attributeList['companyId'] = companyId }
        return attributeList;
    }
};
