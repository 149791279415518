import AuthApiClient from './AuthApiClient';

export default class CompanyUsersApi extends AuthApiClient {

    getCompanyUsers(headers, companyId, body) {
        body = body ?? {};

        return this._sendAuthRequest(`/api/company/${companyId}/users/`, 'GET', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    getCompanyUsersBranch(headers, userId, body) {
        body = body ?? {};

        return this._sendAuthRequest(`/api/user/${userId}/managed-branches-users/`, 'GET', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    deleteUser(headers, userId) {

        return this._sendAuthRequest(`/api/user/${userId}`, 'DELETE', null, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    disableUser(headers, userId) {

        return this._sendAuthRequest(`/api/user/${userId}/disable`, 'PATCH', null, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    enableUser(headers, userId) {

        return this._sendAuthRequest(`/api/user/${userId}/enable`, 'PATCH', null, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    addNewUser(headers, body) {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(`/api/user`, 'POST', body, headers)
            .then(response => {
                return this._filterNonJsonResponse(response)
                    .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                    .then(response => response.json())
                    .then(data => {

                        if('DataValidationError' === data.error.name && undefined !== data.error.details && undefined !== data.error.details[0].message) {
                            return Promise.reject({
                                'DataValidationError': data.error.details[0].message
                            });
                        }

                        if('SignupToExistingCompanyWithFreeAccessError' === data.error.name) {
                            return Promise.reject({
                                'DataValidationError': data.error.message
                            });
                        }


                        return Promise.reject(data);

                    });
            });
    }

    updateUser(headers, userId, body) {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(`/api/user/${userId}`, 'PATCH', body, headers)
            .then(response => {
                return this._filterNonJsonResponse(response)
                    .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                    .then(response => response.json())
                    .then(data => {

                        if('DataValidationError' === data.error.name && undefined !== data.error.details && undefined !== data.error.details[0].message) {
                            return Promise.reject({
                                'DataValidationError': data.error.details[0].message
                            });
                        }
                        return Promise.reject(data);

                    });
            });
    }

    userLinkToBranch(headers, userId, body) {
        headers = headers ?? {};

        return this._sendAuthRequest(`/api/user/${userId}/link-to-branch`, 'POST', body, headers, null, true)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    userUnlinkToBranch(headers, userId, body) {
        headers = headers ?? {};

        return this._sendAuthRequest(`/api/user/${userId}/unlink-from-branch`, 'DELETE', body, headers, null, true)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

}