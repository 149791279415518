import {actionTypes} from '../actions/actionTypes';

export default function isSubdomainWrong(state = false, action)
{
    switch (action.type) {
        case actionTypes.SET_SUBDOMAIN_STATUS:
          return   action.subdomainStatus

        default:
            break;
    }

    return state;
};