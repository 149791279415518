import React from "react";
import {useDispatch} from "react-redux";

import {updateCompanyStatus, loader_add} from "../../../../actions/actions";


const StatusHandlerBtn = (props) => {
    const dispatch = useDispatch();

    const {companyId, queryParams, status, params} = props;

    return (
        <div 
            onClick = {e => {
                e.stopPropagation();
                dispatch(updateCompanyStatus(companyId, queryParams, params, dispatch));
                dispatch(loader_add());
            }}
            style = {{
                width: '100%'
            }}
        >
            <span>{status}</span>
        </div>
    )
};

export default StatusHandlerBtn;