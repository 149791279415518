import React from "react";
import {FormControlLabel, Switch, Tooltip} from "@material-ui/core";
import {disableUser, enableUser, loader_add} from "../../../../../actions/actions";
import {useDispatch, useSelector} from "react-redux";

const EnableDisableUser = (props) => {
    const dispatch = useDispatch();
    const userAdmin = useSelector(state => state.user);
    const {user, queryParams} = props;

    const disableCompanyUser = () => {

        if(false === user.disabled) {
            dispatch(disableUser(user.id, user.companyId, queryParams, dispatch, userAdmin));
        } else {
            dispatch(enableUser(user.id, user.companyId, queryParams, dispatch, userAdmin));
        }

        dispatch(loader_add());
    };

    return (
        <div>
            <Tooltip title={user.disabled ? 'Enable User': 'Disable User'} placement="top" arrow>
                <div>
                    <FormControlLabel
                        style={{margin: 0, width: 50}}
                        control={
                            <Switch
                                size="small"
                                checked={!user.disabled}
                                onChange={e => disableCompanyUser(user.id)}
                                name="checkedB"
                                color="primary"
                            />
                        }
                    />
                    <span>{!user.disabled ? 'Disable User' : "Enable User"}</span>
                </div>
            </Tooltip>
        </div>
    )
};

export default EnableDisableUser;