import React, {useEffect} from "react";
import {useSelector} from "react-redux";

const EmbededDashboard = (props) => {
 const embededDashboard = useSelector(state => state.embededDashboard);

 useEffect(() => {
  if (null !== embededDashboard && typeof embededDashboard !== "string") {
     props.embedDashboardInit(embededDashboard.url)
  }
  //eslint-disable-next-line
 }, [embededDashboard]);

 if(null === embededDashboard || typeof embededDashboard === "string") {
      return <div style={{position: 'relative',  height: '70vh'}}>
          <div style={{background: '#ffffff', color: '#000000', position: 'absolute', zIndex: 10, height: '100%', width: '100%', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 22 }}>
              {embededDashboard}
          </div>
      </div>
 }

 return <div id="dashbordtest"></div>
};
export default EmbededDashboard;