import React from "react";
import { TextField } from "@material-ui/core";

const SearchField = ({ setValue, value, label }) => {
  const onClick=(e)=>{
    e.stopPropagation();
  }
  return (
    <div
      style={{
        width: 153,
        height: 40,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
      }}
    >
      <TextField
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
        onClick={e=>onClick(e)}
      />
    </div>
  );
};

export default SearchField;
