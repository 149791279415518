import React, {useEffect, useState} from "react";
import {Paper, Tabs, Tab} from '@material-ui/core';
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import { useStyles} from '../../../css/styles';
import {routes} from "../../../route/routes";
import LoginVerification from "./LoginVerification";
import SMSSetup from "./SMSSetup";
import TOTPSetup from "./TOTPSetup";
import {getQrUrl} from "../../../actions/actions";
import SelectMFA from "./SelectMFAType";
import SetupMFA from "./SetupMFA";

const MultiFactorAuth = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const mfa = useSelector(state => state.mfa);
    const [mfaValue, setMfaValue] = useState(false);

    const isMfaSetup = () => {
        if (undefined === user || undefined === user.attributes) {
            return false;
        }
        return user.attributes['phone_number_verified'];        
    };

    useEffect(() => {
        if('not authenticated' === user ||
            (undefined === user.preferredMFA && undefined === user.challengeName)) {
            history.push(routes.LOGIN);
        }
        if ('SMS_MFA' === user.preferredMFA || "SOFTWARE_TOKEN_MFA" === user.preferredMFA) {
            history.push(routes.MAIN_PAIGE);
        }
        if (undefined === mfa.qrUrl && 'not authenticated' !== user && "NOMFA" === user.preferredMFA) {
            dispatch(getQrUrl(user, dispatch));
        }
    });

    const setTabTitle = (challengeName) => {
        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            return 'TOTP multi-factor authentication';
        }

        if (user.challengeName === 'SMS_MFA') {
            return 'SMS multi-factor authentication';
        }

        return 'multi-factor authentication';
    };

    if(isMfaSetup() && undefined !== user.challengeName && user.challengeName === "SELECT_MFA_TYPE" ) {
        return <div></div>;
    }

    return  <Paper className={classes.root}>
                <Tabs
                    value={0}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={true}
                >
                    {(undefined !== user.challengeName)
                        ? <Tab label={setTabTitle(user.challengeName)}/>
                        : <Tab label="Setup multi-factor authentication"/>
                    }
                </Tabs>
                <div className={classes.content}>
                    {(undefined !== user.challengeName && (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA'))
                        ? <LoginVerification />
                        : null
                    }
                    {((undefined !== user.challengeName && user.challengeName === "SELECT_MFA_TYPE") || isMfaSetup()) ? <SelectMFA/> : null}
                    {(undefined !== user.preferredMFA && false === mfaValue && !isMfaSetup()) ? <SetupMFA /> : null}
                    {(undefined !== user.preferredMFA && 'SMS' === mfaValue) ? <SMSSetup setup={false} setMfaValue={setMfaValue}/> : null}
                    {(undefined !== user.preferredMFA && 'TOTP' === mfaValue) ? <TOTPSetup setMfaValue={setMfaValue}/> : null}
                </div>
            </Paper>;
};

export default MultiFactorAuth;