import React, {useEffect, useState} from "react";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CreateIcon from '@material-ui/icons/Create';
import {links} from "../../../../css/styles";
import {useDispatch} from "react-redux";
import {deleteHomepageLink, loader_add} from '../../../../actions/actions';
import EditLink from './EditLink';

const EditHomePageLink = (props) => {
    const dispatch = useDispatch();
    const styless = links();
    const [isShow, setShow] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [mobile, setMobile] = useState(false); //eslint-disable-line

    useEffect(() => {
        if(800 >= window.innerWidth) {
            setMobile(true);
            setShow(true);
        } else {
            setMobile(false);
        }
    }, []);


    const deleteLink = (id) => {
        dispatch(deleteHomepageLink(id, dispatch));
        dispatch(loader_add());
    };


    return <div
                onMouseEnter={e => setShow(true)}
                onMouseLeave={e => setShow(false)}
                style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                {isShow
                    ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '10%'}}>
                        <CreateIcon onClick={e => setEdit(!isEdit)} style={{fontSize: 18, cursor: "pointer"}}/>
                        <DeleteForeverRoundedIcon onClick={e => deleteLink(props.link.id)} style={{ fontSize: 20, cursor: "pointer"}}/>
                      </div>
                    : <div style={{width: '10%'}}></div>
                }
                {isEdit
                    ?    <EditLink url={props.link.url} title={props.link.title} id={props.link.id} setEdit={setEdit}/>
                    :    <a href={props.link.url} target="_blank" rel="noopener noreferrer"
                    style={{width: '90%'}} className={styless.link} >
                            <div>{props.link.title}</div>
                            <ArrowRightAltIcon style={{ fontSize: 24 }} />
                        </a>
                }

            </div>
};

export default EditHomePageLink;