import React, { useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
    getCompanyList,
    getManagedUserCompany,
    popupUserList,
    getAdminCompanyList
} from "../../../../actions/actions";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { getheaderList, getPrettyDate } from "./tableComponents/helper";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { Pagination } from "@material-ui/lab";
import AddUserButton from "./AddUserButton";
import { signUpKeys } from "../../../forms/helper/attributesKey";
import PopupPages from "../../../popupPages/PopupPages";
import { getCognitoUserRole, getCognitoUserId } from "../../../../helper/userHelper";
import { UserRole } from '../../../../helper/userHelper';
import ActionButton from './ActionButton';
import EditCompanyName from './EditCompanyName';
import EditCompanySubdomain from './EditCompanySubdomain';

const CompanyList = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);
    const companyList = useSelector(state => state.companyList);
    const adminCompanyList = useSelector(state => state.adminCompanyList);
    const managedCompanies = useSelector(state => state.managedCompanies);
    const user = useSelector(state => state.user);
    const userRole = getCognitoUserRole(user);
    const groupUserId = user.attributes[signUpKeys.GROUP_ID.name];
    const popup = useSelector(state => state.popup);
    const titles = getheaderList('companylist');
    const limit = 10;
    const [page, setPage] = useState((adminCompanyList?.meta?.skip / limit + 1) || 1);

    const getQueryParams = (page) => {
        return { limit: limit, skip: 1 === page ? 0 : (page - 1) * limit, ...filters.filters };
    };

    const getPageCount = () => {
        let count;
        if (UserRole.AccountAdmin === userRole && companyList) {
            count = companyList.meta.totalAmount;
        }
        if (UserRole.SuperAdmin === userRole && adminCompanyList) {
            count = adminCompanyList.meta.totalAmount;
        }
        return count % limit > 0 ? Math.floor(count / limit) + 1 : Math.floor(count / limit);
    };

    const getCompanies = () => {
        if (UserRole.SuperAdmin === userRole && null !== adminCompanyList) {
            return adminCompanyList.list || [];
        }

        if (UserRole.AccountAdmin === userRole && null !== managedCompanies) {
            return managedCompanies[getCognitoUserId(user)].list || [];
        }

        return [];
    };
    
    const getTitles = () => {
        if (UserRole.AccountAdmin === userRole) return titles.filter(title => title.key !== "companyName" && title.key !== "companySubdomain")
        if (UserRole.SuperAdmin === userRole) return titles
    }

    useEffect(() => {
        if (UserRole.AccountAdmin === userRole) {
            dispatch(getManagedUserCompany(user, getQueryParams(page), dispatch))
        }
        if (null === companyList && UserRole.AccountAdmin !== userRole && UserRole.SuperAdmin !== userRole) {
            dispatch(getCompanyList(getQueryParams(page), dispatch));
        }
        if (null === adminCompanyList && UserRole.SuperAdmin === userRole) {
            dispatch(getAdminCompanyList(getQueryParams(page), dispatch));
        }
        //eslint-disable-next-line
    }, []);

    const handleChange = (event, value) => {
        setPage(value);
        if (UserRole.AccountAdmin === userRole) {
            dispatch(getCompanyList(
                getQueryParams(value),
                dispatch
            ));
        }
        if (UserRole.SuperAdmin === userRole) {
            dispatch(getAdminCompanyList(
                getQueryParams(value),
                dispatch
            ));
        }
    };

    const getColor = (userId, companyId) => {
        return userId === companyId ? '#efefef' : '#ffffff';
    };

    const paginationIsShown = (UserRole.SuperAdmin === userRole && adminCompanyList?.meta?.totalAmount > limit) ||
        (UserRole.AccountAdmin === userRole && managedCompanies && managedCompanies[getCognitoUserId(user)].list?.length > 10)

    return (
        <div>
            {null !== popup && <PopupPages queryParams={getQueryParams(page)} />}
            {null === popup && (
                <Box>
                    {(UserRole.AccountAdmin !== userRole)
                        ? <div style={{ marginBottom: 20, marginRight: 58, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <AddUserButton companyId='' />
                        </div>
                        : null}
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {getTitles().map(title => <TableCell align="center" key={title.key} style={{ paddingRight: "8px", paddingLeft: "8px" }}>{title.name}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getCompanies().map(company => {
                                    return <TableRow key={company.id} onClick={e => {
                                        e.stopPropagation();
                                        dispatch(popupUserList(company.id))
                                    }}
                                        style={{ cursor: 'pointer', backgroundColor: getColor(groupUserId, company.id) }}>
                                        <TableCell style={{ paddingRight: "8px", paddingLeft: "8px" }}><ActionButton user={user} companyId={company.id} params={getQueryParams(page)} /></TableCell>
                                        <TableCell style={{ paddingRight: "8px", paddingLeft: "8px" }}>{company['groupId']}</TableCell>
                                        <TableCell align="center" style={{ paddingRight: "8px", paddingLeft: "8px" }}>{company['accessType']}</TableCell>
                                        {UserRole.SuperAdmin === userRole && <TableCell onClick={e => e.stopPropagation()} style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                                            {company['accessType'] === "whiteLabel" && <EditCompanyName companyId={company.whiteLabelClientId} defaultCompanyName={company.name} />}
                                        </TableCell>}
                                        {UserRole.SuperAdmin === userRole && <TableCell onClick={e => e.stopPropagation()} style={{ paddingRight: "8px", paddingLeft: "8px" }}>
                                            {company['accessType'] === "whiteLabel" && <EditCompanySubdomain companyId={company.whiteLabelClientId} defaultWLSubdomain={company.subdomain} />}
                                        </TableCell>}
                                        <TableCell style={{ padding: 4 }} align="center">
                                            {company['logoUrl'] ? <img style={{ width: 100, height: 44, objectFit: 'contain' }} src={company['logoUrl']} alt='' /> : null}
                                        </TableCell>
                                        <TableCell align="center" style={{ paddingRight: "8px", paddingLeft: "8px" }}>{getPrettyDate(company['createdAt'])}</TableCell>
                                        <TableCell align="center" style={{ paddingRight: "8px", paddingLeft: "8px" }}>{getPrettyDate(company['updatedAt'])}</TableCell>
                                        <TableCell onClick={e => e.stopPropagation()} align="center" style={{ paddingRight: "8px", paddingLeft: "8px" }}>{company['id']}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        {paginationIsShown
                            ? <Pagination
                                count={getPageCount()}
                                page={page}
                                onChange={handleChange}
                                style={{ marginTop: 20, marginBottom: 20 }} />
                            : null
                        }

                    </TableContainer>
                </Box>
            )}
        </div>
    )
};

export default CompanyList;
