import AuthApiClient from './AuthApiClient';
import {wlLoader_remove, exeption_add} from '../actions/actions';

export default class WhiteLabelApi extends AuthApiClient {

  updateCompanyName(headers, companyId, body, dispatch) {
    headers = headers ?? {};
    headers['Content-type'] = 'application/json';
    return this._sendAuthRequest(`/api/white-label/${companyId}`, 'PATCH', body, headers)
          .then(response => response.json())
          .catch(err => {
            console.log(err);
            dispatch(exeption_add(err));
        });
  }

    updateCompanyUrl(headers, companyId, body, dispatch) {
      headers = headers ?? {};
      headers['Content-type'] = 'application/json';
      return this._sendAuthRequest(`/api/white-label/${companyId}/subdomain`, 'PATCH', body, headers)
            .then(response => response.json())
            .catch(err => {
              console.log(err);
              dispatch(exeption_add(err));
          });
            ;
    }

    updateWhiteLableColor(headers, companyId, body) {
      headers = headers ?? {};
      headers['Content-type'] = 'application/json';
      return this._sendAuthRequest(`/api/white-label/${companyId}`, 'PATCH', body, headers)
          .then(response => response.json())
          .catch(err => {
            console.log(err);
        });
    }

    getCompanyWhiteLabel(headers, companyId, dispatch) {
      return this._sendAuthRequest(`/api/company/${companyId}/white-label`, 'GET', null, headers)
          .then(response => response.json())
          .catch(err => {
            console.log(err);
            dispatch(wlLoader_remove());
        })
  }

    getWhiteLableSettings(headers, subdomain, dispatch) {
      return this._sendInitRequest(`/api/white-label/subdomain/${subdomain}`, 'GET', null, headers)
          .then(response => response.json())
          .catch(err => {
            console.log(err);
            dispatch(wlLoader_remove());
        })
  }

}
