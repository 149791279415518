import {actionTypes} from '../actions/actionTypes';

export default function colors(state = null, action)
{
    switch (action.type) {
        case actionTypes.SET_COLOR:
            return  {colors: action.colors}

        default:
            break;
    }

    return state;
};