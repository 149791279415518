import {helper} from '../../../../forms/helper/helper';
import {getCognitoUserRole} from '../../../../../helper/userHelper';
const getheaderList = (type) => {
    switch (type) {
        case 'userlist':
            return [{key: 'npi', name: 'Npi'},
                {key: 'practiceName', name: 'Practice Name'},
                {key: 'firstName', name: 'First Name'},
                {key: 'lastName', name: 'Last Name'},
                {key: 'phoneNumber', name: 'Phone Number'},
                {key: 'email', name: 'Email'},
                {key: 'roleCode', name: 'Role'},
                {key: 'managedBranchesIds', name: 'Managed Locations'},
                {key: 'branchId', name: 'Location'},
                {key: 'state', name: 'State'},
                {key: 'city', name: 'City'},
                {key: 'streetAddress', name: 'Street Address'},
                {key: 'zipCode', name: 'Zip Code'},
                {key: 'accessType', name: 'Access'},
                {key: 'id', name: 'id'},
            ];
        case 'companylist':
            return [
                {key: 'actionBtnCol', name: ''},
                {key: 'groupId', name: 'Group Id'},
                {key: 'accessType', name: 'Access'},
                {key: 'companyName', name: 'Company name'},
                {key: 'companySubdomain', name: 'Subdomain'},
                {key: 'logoUrl', name: 'Logo'},
                {key: 'createdAt', name: 'Created at'},
                {key: 'updatedAt', name: 'Updated at'},
                {key: 'id', name: 'id'},
            ];
        default:
            return [];
    }
};

const getRoleName = (code) => {

    switch (code) {
        case code === '1' || 1:
            return 'Super Admin';
        case code === '2' ||  2:
            return 'Group Admin';
        case code === '3' ||  3:
            return 'User';
        case code === '5' || 5:
            return 'Practice Admin';
        case code === '6' ||  6:
            return 'Location Admin';
        case code === '4' ||  4:
            return 'Account Admin';
        case code === '7' ||  7:
            return 'White Label Admin';
    
        default:
            return '';
    }
};

const getUserState = (user, userAdminRole, keys) => {
    const userState = {};
    Object.values(keys).forEach(key => {
        let value;
        if('role' === key.key || 'desiredRole' === key.key) {
            value = undefined === user['roleCode'] ? 2 : user['roleCode'];
        } else if('phoneNumber' === key.key) {
            value = helper.prettyPhone(user[key.key])
        } else {
            value = undefined === user[key.key] || null === user[key.key] ? '' : user[key.key];
        }

        if (!(2 === userAdminRole && key.key === 'companyId')) {
            userState[key.key] = value;
        }
    });
    return userState
};

export const getPrettyDate = (data) => {
    const date = new Date(data);
    return date.toLocaleString('en-US');
};



export {getheaderList, getUserState, getRoleName};

export const getEditUserKeys = {
    EMAIL: {label: 'Email', key: 'email'},
    COMPANY_ID : {label: 'Company Id', key: 'companyId'},
    DESIRED_ROLE: {label: 'Desired Role', key: 'desiredRole'},
    BIND_BRANCH: {label: 'Bind to Location', key: 'branchId'},
    FIRST_NAME: {label: 'First Name', key: 'firstName'},
    LAST_NAME: {label: 'Last Name', key: 'lastName'},
    PRACTICE_NAME: {label: 'Practice Name', key: 'practiceName'},
    STREET_ADDRESS: {label: 'Street Address', key: 'streetAddress'},
    CITY: {label: 'City', key: 'city'},
    ZIP_CODE: {label: 'Zip Code', key: 'zipCode'},
    PHONE_NUMBER: {label: 'Phone Number', key: 'phoneNumber'}
};

export const getUserErrors = {

    email: null,
    companyId: null,
    lastName: null,
    role: null,
    phoneNumber: null,

};

export const role = [
    {
        value: '3',
        label: 'User',
    },
    {
        value: '1',
        label: 'Super Admin'
    },
    {
        value: '4',
        label: 'Account Admin'
    },
    {
        value: '2',
        label: 'Group Admin',
    },
    {
        value: '5',
        label: 'Practice Admin'
    },
    {
        value: '6',
        label: 'Location Admin'
    },
    {
        value: '7',
        label: 'White Label Admin'
    }

];

const userRoleShow = (user) => {
    const role = getCognitoUserRole(user);
    switch (role) {
        case 1:
            return [1, 4, 2, 5, 6, 3, 7];
        case 2:
            return [2, 5, 6, 3];
        case 4:
            return [2, 4, 5, 6, 3];
        case 5:
            return [3, 5, 6];
        case 6:
            return [3, 6];
        case 3:
            return [3];
        case 7:
            return [1, 4, 5, 6, 3, 7];
    
        default:
            return [1, 4, 2, 5, 6, 3, 7];
    }
};

export const getRole = (user) => {
    const list = userRoleShow(user);
    return role.filter(role => -1 < list.indexOf(Number(role.value)));
};





