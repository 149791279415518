import {actionTypes} from '../actions/actionTypes';

export default function newSubdomain(state = null, action)
{
    switch (action.type) {
        case actionTypes.SET_NEW_SUBDOMAIN:
            return  {newSubdomain: action.subdomain}

        default:
            break;
    }

    return state;
};