import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import {FormControlLabel, RadioGroup, Radio, Button, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    exeption_close,
    getUser,
    loader_add,
    loginConfirmSelectMfa, notification_close,
    sendMFASelectionAnswer
} from '../../../actions/actions'
import {routes} from "../../../route/routes";
import {signIpFormStyles} from '../../../css/styles';

const SelectMFAType = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = signIpFormStyles();
    const [value, setValue] = useState(0);
    const [code, setCode] = useState('');

    const user = useSelector(state => state.user);

    const handle = (value) => {
        setValue(value);
        setCode('');
        if('SOFTWARE_TOKEN_MFA' === value) {
            dispatch(sendMFASelectionAnswer(user, value, dispatch));
        }
    };

    const sendCode = (type) => {
        dispatch(sendMFASelectionAnswer(user, type, dispatch));
    };

    const back = () => {
        history.push(routes.LOGIN);
        dispatch(getUser(dispatch));
        dispatch(exeption_close());
        dispatch(notification_close());
    };


    const confirm = (type) => {
        dispatch(loginConfirmSelectMfa(user, code, type, history, dispatch));
        dispatch(loader_add());
    };

    return (
        <div>
            <RadioGroup aria-label="quiz" style={{color: '#000000'}}  name="quiz" value={value} onChange={e => handle(e.target.value)}>
                <FormControlLabel value='SOFTWARE_TOKEN_MFA' control={<Radio color="primary"/>} label="Select Time-based One-time Password" />
                {'SOFTWARE_TOKEN_MFA' === value
                    ? <div style={{marginBottom: 16}}>
                            <TextField
                                name='Code'
                                label='Code'
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                onChange={e => setCode(e.target.value)}
                                onKeyUp={e => setCode(e.target.value)}
                            />
                            <Button
                                onClick={e => confirm('SOFTWARE_TOKEN_MFA')}
                                variant="contained"
                                style={{
                                    backgroundColor: '#02c9e3',
                                    color: '#ffffff',
                                    marginBottom: 20
                                }}>
                                Confirm
                            </Button>
                        </div>
                        : null
                }
                <FormControlLabel value='SMS_MFA' control={<Radio color="primary"/>} label="Select SMS" />
                {
                    'SMS_MFA' === value
                        ? <div style={{marginBottom: 16}}>
                            <Button
                                onClick={e => sendCode('SMS_MFA')}
                                variant="contained"
                                style={{
                                    backgroundColor: '#02c9e3',
                                    color: '#ffffff',
                                    marginBottom: 20
                                }}>
                                Send Code
                            </Button>
                            <TextField
                                name='Code'
                                label='Code'
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                onChange={e => setCode(e.target.value)}
                                onKeyUp={e => setCode(e.target.value)}
                            />
                            <Button
                                onClick={e => confirm('SMS_MFA')}
                                variant="contained"
                                style={{
                                    backgroundColor: '#02c9e3',
                                    color: '#ffffff',
                                    marginBottom: 20
                                }}>
                                Confirm
                            </Button>
                        </div>
                        : null

                }
            </RadioGroup>
            <div onClick={back} className={classes.forgot}>Back to Sign In</div>
        </div>
    )
};

export default SelectMFAType;