import {Auth} from "@aws-amplify/auth";
import ApiClient from "./ApiClient";

export default class AuthApiClient extends ApiClient {
     _getSessionToken() {
        return Auth.currentSession()
            .then(data => {
                return data;
            })
            .catch(e => console.log(e));

    }

    _sendAuthRequest(endpoint, method, body, headers, addOptions = null, query = false)
    {
        return this._getSessionToken()
            .then(token => {
                headers = headers ?? {};
                headers.idtoken = token.idToken.jwtToken;
                if(null !== body && undefined !== body.accessToken) {
                    body.accessToken = token.accessToken.jwtToken
                }

                return super._sendRequest(endpoint, method, body, headers, addOptions, query);
            });
    }

    _sendInitRequest(endpoint, method, body, headers, addOptions = null, query = false)
    {
      return super._sendRequest(endpoint, method, body, headers, addOptions, query);
    }

}