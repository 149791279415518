import {actionTypes} from '../actions/actionTypes';

export default function companyAccess(state = null, action) {
    switch (action.type) {

        case actionTypes.SET_COMPANY_ACCESS:
            return action.access;

        default:
            break;
    }

    return state;
}