import React from "react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {getCognitoUserRole} from "../../../../helper/userHelper";
import {UserRole} from '../../../../helper/userHelper';
import StatusHandlerBtn from './StatusHandlerBtn';
const ActionButton = (props) => {
  const { user, companyId, params } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userRole = getCognitoUserRole(user);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  }; 

  if (userRole !==UserRole.SuperAdmin) {
    return <div></div>;
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ maxWidth: 450 }}
      >
        <MenuItem onClick={handleClose}>
          <StatusHandlerBtn status="Free" companyId={companyId} queryParams={{accessType: "free"}} params={params}/>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <StatusHandlerBtn status="Paid" companyId={companyId} queryParams={{accessType: "paid"}} params={params}/>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <StatusHandlerBtn status="White Label" companyId={companyId} queryParams={{accessType: "whiteLabel"}} params={params}/>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionButton;