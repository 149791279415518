export const signUpKeys = {
    EMAIL: {name: 'email', label: 'Email'},
    PASSWORD: {name: 'password', label: 'Password'},
    PRACTICE_NAME: {name: 'custom:practiceName', label: 'Practice Name '},
    NPI: {name: 'custom:npi', label: 'NPI'},
    FIRST_NAME: {name: 'custom:firstName', label: 'First Name'},
    LAST_NAME: {name: 'custom:lastName', label: 'Last Name'},
    STREET_ADDRESS: {name: 'custom:streetAddress', label: 'Street Address'},
    CITY: {name: 'custom:city', label: 'City'},
    STATE_REGION: {name: 'custom:state', label: 'State/Region'},
    POSTAL_CODE: {name: 'custom:zipcode', label: 'Zipcode'},
    MOBILE_NUMBER: {name: 'phone_number', label: 'Mobile Number'},
    GROUP_ID: {name: 'custom:groupId', label: 'Group id'},
    DESIRED_ROLE:  {name: 'custom:role'},
    CONFIRM_PSW: {name: 'confirm', label: 'Confirm Password'}
};

export const cognitoUserKey = {
    USER_ID: {name: 'custom:userId', label: 'User Id'}
};

export const signInKeys = {
    EMAIL: {name: 'email', label: 'Email'},
    PASSWORD: {name: 'password', label: 'Password'},
};

export const keys = [
    {name: signUpKeys.EMAIL.name, key: 'email'},
    {name: signUpKeys.PASSWORD.name, key: 'password'},
    {name: signUpKeys.PRACTICE_NAME.name, key: 'practiceName'},
    {name: signUpKeys.NPI.name, key: 'npi'},
    {name: signUpKeys.FIRST_NAME.name, key: 'firstName'},
    {name: signUpKeys.LAST_NAME.name, key: 'lastName'},
    {name: signUpKeys.STREET_ADDRESS.name, key: 'streetAddress'},
    {name: signUpKeys.CITY.name, key: 'city'},
    {name: signUpKeys.POSTAL_CODE.name, key: 'zipCode'},
    {name: signUpKeys.MOBILE_NUMBER.name, key: 'phoneNumber'},
    {name: signUpKeys.GROUP_ID.name, key: 'groupNpi'},
    {name: signUpKeys.DESIRED_ROLE.name, key: 'role'},
    {key: 'companyId'},
    {key: 'branchId'}
];

