import React, {useState} from "react";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import {newsStyles} from "../../../css/styles";

const NewsCard = (props) => {
    const styless = newsStyles();
    const item = props.item;
    const [show, setShow] = useState(false);

    const createMarkup = (description) => {
        return {__html: description};
    };

    const date = new Date(item.publishAt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const indexTitle = item.title;
    return <div key={indexTitle} className={styless.wrapper}>
        <div className={styless.dateWrapper}><div className={styless.date}>{month} {date.getDate()} {year}</div><div className={styless.line}></div></div>
        <div className={styless.title}>{item.title}</div>
        <div className={show ? 'fullText' : 'shortText'} dangerouslySetInnerHTML={createMarkup(item.description)}></div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={e => setShow(!show)}>
            {show
                ? <div className={styless.showbtn}><div>Hide</div> <TrendingFlatIcon className={styless.showbtnIconR} fontSize="small" /></div>
                : <div className={styless.showbtn}><div>Show</div><TrendingFlatIcon className={styless.showbtnIcon} fontSize="small" /></div>}
        </div>
    </div>
};

export default NewsCard;