import {actionTypes} from '../actions/actionTypes';
import {Auth} from "@aws-amplify/auth";
import {
    setQrUrl,
    exeption_add,
    loader_remove,
    getUser,
    notification_add,
    notification_close,
    userMfaWasSet,
    exeption_close,
    setMfaSetupStatus
} from '../actions/actions';
import {routes} from "../route/routes";
import VerificationApi from "../Api/VerificationApi";

const _verificationApi = new VerificationApi();

const INITIAL_STATE = {
    setupStatus: {
        smsConfigured: false,
        totpConfigured: false
    }
};

export default function mfa(state = INITIAL_STATE, action) {
    const newState = {...state};

    switch (action.type) {
        case actionTypes.GET_QR_URL:
            Auth.setupTOTP(action.user)
                .then((code) => {
                    const generatedQrCode = 'otpauth://totp/AWSCognito:' + action.user.username + '?secret=' + code + '&issuer=esimplify';
                    action.dispatch(setQrUrl(generatedQrCode));

                    return generatedQrCode;

                })
                .catch(e => {
                    return e;
                });

            return newState;


        case actionTypes.SET_QR_URL:
            newState.qrUrl = action.str;

            return newState;

        case actionTypes.TOTP_VERIFY:
            Auth.verifyTotpToken(action.user, action.code).then((data) => {
                action.dispatch(setMfaSetupStatus({ totpConfigured: true }));
                action.dispatch(loader_remove());
                action.dispatch(exeption_close());
                return newState;
            })
            .catch( err => {
                action.dispatch(loader_remove());
                action.dispatch(exeption_add(err));
            });

            return  state;

        case actionTypes.GET_PHONE_VERIFICATION_CODE:
            _verificationApi.sendPhoneVerificationCode(
                {"accessToken": true},
                {}
            )
            .then(data => {
                action.dispatch(notification_add('Verification code has been sent to your mobile number'));
                return state;
            })
            .catch(err => {
                action.dispatch(notification_close());
                action.dispatch(exeption_add(err));
                return state;
            });
            return  state;


        case actionTypes.SEND_PHONE_VERIFICATION_CODE:
            _verificationApi.verifyPhone(
                {"accessToken": true,
                    "code": action.code},
                {}
            )
            .then(data => {
                action.dispatch(notification_add('Your mobile number is verified'));
                action.dispatch(userMfaWasSet());
                action.dispatch(loader_remove());
                action.history.push(routes.MAIN_PAIGE);
                action.dispatch(getUser(action.dispatch));
            })
            .catch(err => {
                action.dispatch(notification_close());
                action.dispatch(exeption_add(err));
                action.dispatch(loader_remove());
                return err;
            });
            return  state;

        case actionTypes.SEND_PHONE_CONFIRMATION_CODE:
            _verificationApi.verifyPhone(
                {"accessToken": true,
                    "code": action.code},
                {}
            )
                .then(() => {
                    action.dispatch(loader_remove());

                    if(action.setup) {
                        action.dispatch(setMfaSetupStatus({ smsConfigured: true }));
                    } else {
                        action.history.push(routes.MAIN_PAIGE);
                        action.dispatch(getUser(action.dispatch));
                    }
                })
                .catch(err => {
                    action.dispatch(notification_close());
                    action.dispatch(exeption_add(err));
                    action.dispatch(loader_remove());
                    return err;
                });
            return  state;

        case actionTypes.SET_MFA_SETUP_STATUS:
            return {
                ...state,
                setupStatus: {
                    ...state.setupStatus,
                    ...action.setupStatus
                }
            };

        case actionTypes.RESET_MFA_SETUP_STATUS:
            return {
                ...state,
                setupStatus: INITIAL_STATE.setupStatus
            };

        case actionTypes.SET_PREFERRED_MFA_SMS:
            Auth.setPreferredMFA(action.user, 'SMS')
                .then(() => {
                    action.dispatch(getUser(action.dispatch));
                })
                .catch(e => {
                   return e;
                });
            return state;

        case actionTypes.SET_PREFERRED_MFA_TOTP:
            Auth.setPreferredMFA(action.user, 'TOTP')
                .then(() => {
                    action.dispatch(getUser(action.dispatch));
                })
                .catch(e => {
                    return e;
                });
            return state;

        case actionTypes.USER_MFA_WAS_SET:
            _verificationApi.userMfaWasSet({ bothMfaWereSelected: action.bothMfaWasSet }, {})
                .then(data => {
                    action.dispatch(getUser(action.dispatch));
                    return data;
                })
                .catch(e => e);
            return state;

        case actionTypes.LOGIN_CONFIRM:
            Auth.confirmSignIn(action.user, action.code, action.user.challengeName)
                .then(user => {
                    action.dispatch(notification_close());
                    action.history.push(routes.MAIN_PAIGE);
                    action.dispatch(getUser(action.dispatch));
                    action.dispatch(loader_remove());
                    return user;
                }).catch(err => {
                action.dispatch(exeption_add(err));
                action.dispatch(loader_remove());
                return err;
            });

            return state;

        case actionTypes.LOGIN_CONFIRM_SELECT_MFA:
            Auth.confirmSignIn(action.user, action.code, action.mfaType)
                .then(user => {
                    action.dispatch(notification_close());
                    action.history.push(routes.MAIN_PAIGE);
                    action.dispatch(getUser(action.dispatch));
                    action.dispatch(loader_remove());
                    return user;
                }).catch(err => {
                action.dispatch(exeption_add(err));
                action.dispatch(loader_remove());
                return err;
            });

            return state;

        case actionTypes.SEND_MFA_SELECTION_ANSWER:
            action.user.sendMFASelectionAnswer(action.mfaType, {
                onSuccess: (data) => {
                    action.dispatch(exeption_close());
                    action.dispatch(loader_remove());
                    if ('SMS_MFA' === action.mfaType ) {
                        action.dispatch(notification_add('Authentication code has been sent to your mobile number'))
                    }
                    return state;
                },
                onFailure: (data) => {
                    action.dispatch(loader_remove());
                    if ('SMS_MFA' === action.mfaType) {
                        action.dispatch(exeption_add({message: 'You can send the authentication code once. Please go back to the Sign In page and try again.'}));
                    }
                    if('SOFTWARE_TOKEN_MFA' === action.mfaType && undefined === data.code && "TypeError" === data.name) {
                        action.dispatch(exeption_close());
                    }
                    action.dispatch(notification_close());
                    return state;
                },
                mfaRequired: (challengeName, challengeParameters) => {
                    if ('SMS_MFA' === action.mfaType ) {
                        action.dispatch(notification_add('Authentication code has been sent to your mobile number'))
                    }
                    action.dispatch(loader_remove());
                    return state;
                },
            });
            return state;

        default:
            return newState;
    }
};