import React, { useState, useEffect, useRef } from "react";
import { Button, TextField } from "@material-ui/core";
import { updateCompanyUrl } from "../../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkIfDomainIsInvalid } from "../home/helper";
import { getCognitoUserRole, UserRole } from "../../../../helper/userHelper";

const useMountEffect = (fun) => useEffect(fun, []);

const UseFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef,  setFocus ] 
}

const EditCompanySubdomain = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const {companyId, defaultWLSubdomain} = props;
  const [subdomain, setSubdomain] = useState(defaultWLSubdomain);
  const [validationUrl, setvalidationUrl] = useState(null);
  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
  const [inputRef, setInputFocus] = UseFocus()

  const userRole = getCognitoUserRole(user);

  const submitSubdomain = (e) => {
    e.stopPropagation();
    let validUrl = checkIfDomainIsInvalid(subdomain);
    if (validUrl) {
      setvalidationUrl("Subdomain can't contain spaces!");
      return;
    }
    dispatch(updateCompanyUrl(companyId, {subdomain: subdomain}, dispatch));
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setInputFocus();
    setIsSaveBtnVisible(true); 
  };

  const handleOnFocus = () => {
    setvalidationUrl(null);
  };

  const handleChangeSubdomain = (e) => {
    e.stopPropagation();
    setSubdomain(e.target.value.trim())
  }

  useMountEffect( setInputFocus )

  return (
    <div className="domainEditor">
      {UserRole.SuperAdmin === userRole  ? (
      <form
        noValidate
        autoComplete="off"
        style={{ display: "flex", alignItems: "baseline", marginLeft: "10%" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            flexWrap: "nowrap",
            marginRight: "20px",
            width: "150px"
          }}
        >
          <TextField
            name="subdomain"
            inputRef={inputRef}
            value={subdomain}
            autoFocus={true}
            onFocus={handleOnFocus}
            onClick={handleChangeSubdomain}
            onChange={handleChangeSubdomain}
            onKeyUp={handleChangeSubdomain}
            style={{ margin: "0 8px", minWidth: "100px" }}
            helperText={null !== validationUrl ? validationUrl : ""}
            error={null !== validationUrl ? true : false}
          />
        </div>
        {!isSaveBtnVisible ? <Button
          onClick={handleEditClick}
          variant="contained"
          size={"small"}
          color="primary"
        >
          Edit
        </Button> :
         <Button
          onClick={submitSubdomain}
          variant="contained"
          size={"small"}
          color="primary"
        >
          Save
        </Button>}
      </form>
       ) : null} 
    </div>
  );
};

export default EditCompanySubdomain;
