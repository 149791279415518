import {actionTypes} from '../actions/actionTypes';
import {getHomepageLink, loader_remove, setHomepageLink} from '../actions/actions'
import HomepageLinkApi from '../Api/HomepageLinkApi';
const _homepageLinkApi = new HomepageLinkApi();

export default function homepageLink(state = null, action)
{
    switch (action.type) {

        case actionTypes.GET_HOMEPAGE_LINK:
            _homepageLinkApi.getHomepageLink({})
            .then(data => {
                action.dispatch(setHomepageLink(data));
                action.dispatch(loader_remove());
                return data;
            })
            .catch(err => {
                action.dispatch(loader_remove());
                return state;
            });
            return state;

        case actionTypes.SET_HOMEPAGE_LINK:
            return action.links.data;

        case actionTypes.CREATE_HOMEPAGE_LINK:
            _homepageLinkApi.createHomepageLink(
                {},
                action.link)
                .then(data => {
                    action.dispatch(getHomepageLink(action.dispatch))
                    return state;
                })
                .catch(e => {

                    return state;
                });

            return state;

        case actionTypes.DELETE_HOMEPAGE_LINK:
            _homepageLinkApi.deleteHomepageLink(
                {},
                action.id
            ).then(data => {
                action.dispatch(getHomepageLink(action.dispatch));
                return data;
            })
                .catch(e => {
                    action.dispatch(loader_remove());
                    return e;
                });
            return state;

        case actionTypes.EDIT_HOMEPAGE_LINK:
            _homepageLinkApi.editeHomepageLink(
                {},
                action.link,
                action.id
            ).then(data => {
                action.dispatch(getHomepageLink(action.dispatch));
                return data;
            })
                .catch(e => {
                    action.dispatch(loader_remove());
                    return state;
                });
            return state;

        default:
            break;
    }

    return state;
};