const getUrlSearchParams = (search) => {
    if ('' === search) {
        return null;
    }
    const params = {};
    const searchParams = new URLSearchParams(search);

    searchParams.forEach((value, key) =>{
        params[key] = value;
    });

    return params;
};

export { getUrlSearchParams }