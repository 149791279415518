import React, {useState} from "react";
import {deleteCompanyUsers, loader_add} from "../../../../../actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Tooltip} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {getCognitoUserRole} from '../../../../../helper/userHelper';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const DeleteUser = (props) => {
    const dispatch = useDispatch();
    const userAdmin = useSelector(state => state.user);
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const {user, queryParams} = props;

    const deleteUser = () => {
        dispatch(deleteCompanyUsers(user.id, user.companyId, queryParams, dispatch));
        dispatch(loader_add());
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Are you sure?</h2>
            <p id="simple-modal-description">
                That you want to delete user?
            </p>
            <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button onClick={e => deleteUser(user.id)}>Delete</Button>
                <Button onClick={e => setOpen(false)}>No</Button>
            </Box>
        </div>
    );

    if (5 <= getCognitoUserRole(userAdmin) && getCognitoUserRole(userAdmin) !== 7) {
        return <div></div>;
    }

    return (
        <div>
            <Tooltip title="Delete User" placement="top" arrow>
                <div  onClick={e => setOpen(true) } style={{display: 'flex', alignItems: 'center'}}>
                    <DeleteIcon size="10px"  style={{width: 50}}/>
                    <span>Delete User</span>
                </div>
            </Tooltip>
            <Modal
                open={open}
                onClose={(e) => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
};

export default DeleteUser;