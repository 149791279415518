import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import {Paper, Tabs, Tab, Container} from '@material-ui/core';
import { useStyles } from '../../css/styles';
import {useDispatch, useSelector} from "react-redux";
import SignUpForm from "./SignUpForm";
import SignInForm from './SignInForm';
import {getUrlSearchParams} from "../../helper/urlSearchParamsHelper";
import LoginHeaderMessages from "./LoginHeaderMessage";
import {getUser} from "../../actions/actions";


const MSG_CONFIRM = 'Please look in your email inbox for a confirmation and respond to the verification email. If you do not see the email, please look to see if the email was directed to a junk email box.'

const SignUpSignInTab = (props) => {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const params = getUrlSearchParams(useLocation().search);
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const parts = window.location.hostname.split('.');
    const subDomain = parts.shift();

    useEffect(() => {
        if(null === user) {
            dispatch(getUser(dispatch));
        }
        //eslint-disable-next-line
    }, []);

    if(null !== user) {
        if (false === user.userConfirmed) {
            return (<div className="confirm-registration-wrapper">{MSG_CONFIRM}</div>);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (<Container maxWidth={"xl"}><Paper className={classes.root}>
        <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            centered={true}
            onChange={handleChange}
        >
            <Tab label="Login"/>
            { (parts[0]==="esimplifysolutions"&& subDomain!=="portal" && subDomain!=="staging" && subDomain!=="beta" &&
             subDomain!=="localhost") || parts[0]==="portal" || parts[0]==="staging" 
             || parts[0]==="beta"|| parts[0]==="test" ? null:
            <Tab label="New User"/>}
        </Tabs>
        {(0 === value)
            ? <div className={classes.content}>
                <LoginHeaderMessages params={params} />
                <SignInForm history={props.history} params={params}/>
            </div>
            : null
        }
        {(1 === value)
            ? <div className={classes.content}><SignUpForm type='signUp' /></div>
            : null
        }

    </Paper>
    </Container>)
};

export default SignUpSignInTab;