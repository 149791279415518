import React from "react";
import CompanyList from "./settings/CompanyList";
import CompanyUsers from "./settings/CompanyUsers";

import {useSelector} from "react-redux";
import {signUpKeys} from "../../forms/helper/attributesKey";
import {Container} from '@material-ui/core';
import {getCognitoUserRole, getCognitoUserId} from '../../../helper/userHelper';
import FiltersPanel from './settings/FiltersPanel';

const Settings = () => {
    const user = useSelector(state => state.user);
    const roleCode = getCognitoUserRole(user);
    const groupId = user.attributes[signUpKeys.GROUP_ID.name];

    if((null !== user && 2 === roleCode) || 3 === roleCode || 5 === roleCode ||  6 === roleCode || 7 === roleCode) {

        return <Container maxWidth={"xl"} style={{color: '#000000'}}>
                    <FiltersPanel/>
                    <CompanyUsers userId={getCognitoUserId(user)} companyId={groupId}/>
                </Container>
    }

    if((null !== user && 1 === roleCode) || 4 === roleCode) {

        return <Container maxWidth={"xl"} style={{color: '#000000'}}>
                    <FiltersPanel/>
                    <CompanyList />
                </Container>
    }

    return <div></div>
};

export default Settings;