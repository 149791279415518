import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchButton from "./SearchBtn";
import SearchField from "./SearchField";
import ClearButton from "./ClearBtn";
import MultipleSelect from "./MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyList,
  getAdminCompanyList,
  popupUserList,
  loader_add,
  setFilters,
  getManagedUserCompany,
  getCompanyUsers,
  getCompanyUsersBranch
} from "../../../../actions/actions";
import {getCognitoUserRole, getCognitoUserId} from "../../../../helper/userHelper";
import {signUpKeys} from "../../../../components/forms/helper/attributesKey";
import {UserRole} from '../../../../helper/userHelper'

const FiltersPanel = () => {
  const [expanded, setExpanded] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [npi, setNpi] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [location, setLocation] = useState("");
  const [id, setId] = useState("");
  const [accessType, setAccessType] = useState([]);
  const [role, setRole] = useState([]);
  const [roleCode, setRoleCode] = useState("");
  const user = useSelector(state => state.user);
  const userRole = getCognitoUserRole(user);
  const page = 1;
  const limit = 10;


  const dispatch = useDispatch();
  const getFilters = () => {
    const filters = {};
    if (groupId !== "") {
      filters.groupId = groupId;
    }
    if (npi !== "") {
      filters.npi = npi;
    }
    if (firstName !== "") {
      filters.firstName = firstName;
    }
    if (lastName !== "") {
      filters.lastName = lastName;
    }
    if (practiceName !== "") {
      filters.practiceName = practiceName;
    }
    if (location !== "") {
      filters.location = location;
    }
    if (id !== "") {
      filters.id = id;
    }
    if (id !== "") {
      filters.id = id;
    }
    if (accessType.length) {
      const values = {
        'Free': 'free',
        'Paid': 'paid',
        'White Label': 'whiteLabel',
      }
      filters.accessType = accessType.map(value => values[value]).join();
    }
    if (practiceName !== "") {
      filters.practiceName = practiceName;
    }
    if (location !== "") {
      filters.location = location;
    }
    if (id !== "") {
      filters.id = id;
    }
    if (id !== "") {
      filters.id = id;
    }
    if (phoneNumber !== "") {
      filters.phoneNumber = phoneNumber;
    }
    if (email !== "") {
      filters.email = email;
    }
    if (roleCode !== "") {
      filters.roleCode = roleCode;
    }
    if (state !== "") {
      filters.state = state;
    }
    if (city !== "") {
      filters.city = city;
    }
    if (streetAddress !== "") {
      filters.streetAddress = streetAddress;
    }
    if (zipCode !== "") {
      filters.zipCode = zipCode;
    }
    return filters;
  };

  useEffect(() => {
    dispatch(setFilters(getFilters()));
    //eslint-disable-next-line
  }, [
    id,
    location,
    accessType,
    practiceName,
    groupId,
    npi,
    firstName,
    lastName,
    phoneNumber,
    email,
    roleCode,
    state,
    city,
    streetAddress,
    zipCode,
  ]);

  useEffect(() => {
    const convertedRole = role.map((item) => {
      if (item === "Super Admin") return 1;
      if (item === "Group Admin") return 2;
      if (item === "User") return 3;
      if (item === "Account Admin") return 4;
      if (item === "Practice Admin") return 5;
      if (item === "Location Admin") return 6;
      else return ''
    }).toString();
    setRoleCode(convertedRole);
  }, [role]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const popup = useSelector((state) => state.popup);

  const getQueryParams = (page) => {
    return { limit: limit, skip: 1 === page ? 0 : (page - 1) * limit, ...getFilters() };
  };

  const handleClearClick = (e) => {
    e.stopPropagation();
    setGroupId("");
    setId("");
    setPracticeName("");
    setLocation("");
    setAccessType([]);
    setNpi("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    setRoleCode("");
    setRole([]);
    setState("");
    setCity("");
    setStreetAddress("");
    setZipCode("");
    handleSearchClick(e)
    };

  const handleSearchClick = (e) => {
    e.stopPropagation();
    if (popup === null && UserRole.SuperAdmin === userRole) {
      dispatch(getAdminCompanyList(getQueryParams(page), dispatch));
      dispatch(loader_add());
    }
    if (popup === null && UserRole.AccountAdmin === userRole) {
      dispatch(getManagedUserCompany(user, getQueryParams(page), dispatch))
    }
    if(popup === null && UserRole.AccountAdmin !== userRole && UserRole.SuperAdmin !== userRole){
      dispatch(getCompanyList(getQueryParams(page), dispatch));
      dispatch(loader_add());
    }
    if (UserRole.PracticeAdmin === userRole ||  UserRole.LocationAdmin === userRole) {
      dispatch(getCompanyUsersBranch(
        getCognitoUserId(user),
          getQueryParams(page),
          dispatch
      ));
    }

    if (popup !== null) {
      dispatch(popupUserList(popup.id, getQueryParams(page)));
      if ( UserRole.SuperAdmin === userRole  || UserRole.GroupAdmin === userRole || UserRole.AccountAdmin === userRole) {
        const groupId = user.attributes[signUpKeys.GROUP_ID.name];
        dispatch(getCompanyUsers(groupId, getQueryParams(page), dispatch))
      }  
    }
  };
  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeAccordion("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginRight: 10,
              }}
            >
              { ( UserRole.SuperAdmin === userRole  || UserRole.GroupAdmin === userRole ) && <SearchField
                setValue={setGroupId}
                value={groupId}
                label="Group NPI"
              />}
              <SearchField
                setValue={setNpi}
                value={npi}
                label="NPI"
              />
              <SearchField
                setValue={setPracticeName}
                value={practiceName}
                label="Practice Name"
              />
              <SearchField
                setValue={setFirstName}
                value={firstName}
                label="First Name"
              />
              <SearchField
                setValue={setLastName}
                value={lastName}
                label="Last Name"
              />
              <SearchField
                setValue={setPhoneNumber}
                value={phoneNumber}
                label="Phone Number"
              />
              <SearchField
                setValue={setEmail}
                value={email}
                label="Email"
              />
              <SearchField
                setValue={setState}
                value={state}
                label="State"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <SearchButton handleSearchClick={handleSearchClick} />
              <ClearButton handleClearClick={handleClearClick} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginRight: 10,
            }}
          >
            <MultipleSelect
              items={[
                "Super Admin",
                "Group Admin",
                "User" ,
                "Account Admin",
                "Practice Admin",
                "Location Admin"
              ]}
              value={role}
              setValue={setRole}
              label="Select Role"
            />
            <SearchField
              setValue={setLocation}
              value={location}
              label="Location"
            />
            <SearchField
              setValue={setCity}
              value={city}
              label="City"
            />
            <SearchField
              setValue={setStreetAddress}
              value={streetAddress}
              label="Street"
            />
            <SearchField
              setValue={setZipCode}
              value={zipCode}
              label="ZIP"
            />
            {( UserRole.SuperAdmin === userRole  || UserRole.GroupAdmin === userRole ) && <MultipleSelect
              items={[
                "Free",
                "Paid",
                "White Label",
              ]}
              value={accessType}
              setValue={setAccessType}
              label="Access Type"
            />}
            <SearchField
              setValue={setId}
              value={id}
              label="Id"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FiltersPanel;
