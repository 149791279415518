import React from "react";
import {Tooltip, FormControlLabel, Checkbox, Chip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    exeption_add,
    loader_add,
    userLinkToBranch,
    userUnlinkFromBranch
} from "../../../../../actions/actions";
import {getCognitoUserRole, hideLinkToCompany} from '../../../../../helper/userHelper'

const AddUserToBranch = (props) => {
    const dispatch = useDispatch();
    const mainUserRole = getCognitoUserRole(useSelector(state => state.user));
    const companyBranches = useSelector(state => state.companyBranches);
    const {user, queryParams} = props;

    const isUserManagedBranch = (user, branchId) => {
        return (-1 < user.managedBranchesIds.indexOf(branchId))
    };

    const handleChange = (e, user, branchId) => {
        e.preventDefault();
        if(-1 < user.managedBranchesIds.indexOf(branchId)) {
            dispatch(userUnlinkFromBranch(user, branchId, queryParams, dispatch));
        } else {
            if ((6 === user.roleCode || 3 === user.roleCode) && 1 <= user.managedBranchesIds.length) {
                return dispatch(exeption_add({message: 'you can add only one location for this user'}));
            }
            dispatch(userLinkToBranch(user, branchId, queryParams, dispatch));
        }
        dispatch(loader_add());


    };

    if (3 === mainUserRole || 5 === mainUserRole || 6 === mainUserRole) {
        return <div></div>;
    }

    if (hideLinkToCompany(user.roleCode) || null === companyBranches || 0 >= companyBranches.length) {
        return <div></div>;
    }

    if (6 === user.roleCode &&  0 < user.managedBranchesIds.length) {

        let userBranches = companyBranches.filter(company => isUserManagedBranch(user, company.id));

       return (<div style={{padding: '6px 16px'}}>
            <div>Remove from Managed Location:</div>
               { userBranches.map(item => <Chip
                   label={item.location}
                   onDelete={e => dispatch(userUnlinkFromBranch(user, item.id, queryParams, dispatch))}
                   variant="outlined"
               />)}
        </div>)
    }

    return (
        <Tooltip title="Company locations" placement="top" arrow>
            <div style={{padding: '6px 16px'}}>
                <div>Managed Locations:</div>
                {companyBranches.map(item =>  <div
                                onClick={e => handleChange(e, user, item.id)}
                                key={item.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isUserManagedBranch(user, item.id)}
                                            color="primary"
                                        />
                                    }
                                    label={item.location}
                                />
                            </div>
                )}
            </div>
        </Tooltip>
    )

};

export default AddUserToBranch;