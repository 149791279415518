const BASE_URL = 'https://esimplifysolutions.com';
const SERVICES = 'solutionsservices';

export const foterLinks = [
    {
        name: 'Solutions / Services',
        link: `${BASE_URL}/${SERVICES}/`,
        children: [
            {
                name: 'Access to Dashboard',
                link: `${BASE_URL}/${SERVICES}/access-to-dashboard/`,
            },
            {
                name: 'Practice Analytics',
                link: `${BASE_URL}/${SERVICES}/practice-analytics/`,
            }
            ,
            {
                name: 'Audit Risk Monitoring',
                link: `${BASE_URL}/${SERVICES}/audit-monitoring/`,
            }
        ]
    },
    {
        name: 'About Us',
        link: `${BASE_URL}/about/`,
        children: [
            {
                name: 'Why Choose eSimplify?',
                link: `${BASE_URL}/about/why-choose-esimplify/`,
            },
            {
                name: 'Services for Practices',
                link: `${BASE_URL}/about/why-choose-esimplify/services-for-practices`,
            },
            {
                name: 'Customer Sign Up',
                link: `${BASE_URL}/about/customer-sign-up/`,
            }
        ]
    },
    {
        name: 'News Releases',
        link: `${BASE_URL}/blog/`
    },
    {
        name: 'Contact',
        link: `${BASE_URL}/contact/`,
        children: [
            {
                name: 'Privacy Policy',
                link: `${BASE_URL}/contact/privacy-policy/`
            },
            {
                name: 'Terms of Use',
                link: `${BASE_URL}/contact/terms-of-use/`
            }
        ]
    }
];