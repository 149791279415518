const getType = (fileName) => {
    switch (true) {
        case fileName.indexOf('.csv') > -1:
            return 'text/csv;charset=utf-8;';

        case fileName.indexOf('.xls') > -1:
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        case fileName.indexOf('.xlsx') > -1:
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        default:
            break
    }
};

export {getType};