import {actionTypes} from '../actions/actionTypes';

export default function logoutStatus(state = false, action)
{
    switch (action.type) {
        case actionTypes.SET_LOGOUT_STATUS:
            return  action.status

        default:
            break;
    }

    return state;
};