import {actionTypes} from '../actions/actionTypes';
import {removeValueFromLocalStorage, setValueToLocalStorage} from './helper/localStorage';

export default function isMfaSetup(state = null, action)
{
    switch (action.type) {
        case actionTypes.MFA_SETUP_TO_LOCALSTORAGE:
            let newState = null != state ? {...state} : {};
            newState[action.key] = action.value;
            setValueToLocalStorage('isMfaSetup', newState);

            return newState;

        case actionTypes.REMOVE_MFA_FROM_LOCALSTORAGE:
            removeValueFromLocalStorage('isMfaSetup');
            return {};
        default:
            break;
    }

    return state;
};