import React from "react";
import {Paper, Button, Tooltip} from "@material-ui/core";
import AddUserButton from "./AddUserButton";
import {popupManageBranches} from "../../../../actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {controlePanel} from  "../../../../css/styles";
import {getCognitoUserRole} from "../../../../helper/userHelper";

const ControlePanel = (props) => {
    const styles = controlePanel();
    const dispatch = useDispatch();
    const userRole = getCognitoUserRole(useSelector(state => state.user));
    const {companyId} = props;
    return (
                <Paper elevation={0} className={styles.root}>
                    {1 === userRole || 2 === userRole || 4 === userRole
                        ? <Tooltip title="Create or delete locations" placement="top" arrow>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={styles.btn}
                                style={{marginRight: 8}}
                                onClick={e => dispatch(popupManageBranches(props.companyId))}
                            >
                                Manage Locations
                            </Button>
                            </Tooltip>
                        : null}

                    <AddUserButton companyId={companyId}/>
                </Paper>
    )
};

export default ControlePanel;