/**
 * Is able to create URLSearchParams object with nested query properties, like:
 * {
 *     page: 0,
 *     filters: {
 *         category: 'Test',
 *         сompany: ['Test1', 'Test']
 *     }
 * }
 */
export default class SearchParamsFactory
{
    /**
     * @param {Object} data
     * @param {URLSearchParams}|null searchParams
     * @return {URLSearchParams}
     */
    createSearchParams(data, searchParams)
    {
        if (!searchParams || !searchParams instanceof URLSearchParams) {
            searchParams = new URLSearchParams();
        }

        const self = this;
        Object
            .keys(data)
            .forEach(key => self._appendToSearchParams(key, data[key], searchParams))
        ;

        return searchParams;
    }

    /**
     * @param {String} objectKey
     * @param {Object} obj
     * @param {URLSearchParams} searchParams
     * @private
     */
    _appendObjectToSearchParams(objectKey, obj, searchParams)
    {
        const self = this;
        for (let [key, value] of Object.entries(obj)) {
            if (key && 'string' === typeof key && undefined !== value) {
                const objKey = `${objectKey}[${key}]`;
                self._appendToSearchParams(objKey, value, searchParams);
            }
        }
    }

    /**
     * @param {String} arrayKey
     * @param {Array} value
     * @param {URLSearchParams} searchParams
     * @private
     */
    _appendArraySearchParams(arrayKey, value, searchParams)
    {
        const self = this;
        for (let i = 0; i <= value.length - 1; i++) {
            let key = `${arrayKey}[${i}]`;
            self._appendToSearchParams(key, value[i], searchParams);
        }
    }

    /**
     * @param key
     * @param value
     * @param {URLSearchParams} searchParams
     * @private
     */
    _appendToSearchParams(key, value, searchParams)
    {
        const self = this;
        if ('object' === typeof value && null !== value) {
            if (Array.isArray(value)) {
                return self._appendArraySearchParams(key, value, searchParams);
            }

            return self._appendObjectToSearchParams(key, value, searchParams);
        }

        return searchParams.append(key, value);
    }
};