import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {loader_add, removeImportDataNotification, sendDashboardClaims, setImportInProgressFlag, setS3FieldsForRequest} from "../../../../actions/actions";
import UploadFileArea from "../UploadFileArea";

import {getType} from './helper';
import CompanyIdInput from "./CompanyIdInput";

const Claims = () => {
    const dispatch = useDispatch();
    const [companyId, setCompanyId] = useState('');

    const uploadFile = (file, e) => {
        const csvData = new Blob([file], {type: getType(file.name)});
        let formData = new FormData();
        formData.append("claims", csvData);
        dispatch(setImportInProgressFlag());
        dispatch(removeImportDataNotification());
        dispatch(sendDashboardClaims(formData, dispatch, companyId));
        dispatch(setS3FieldsForRequest(null, dispatch));
        dispatch(loader_add());
        e.target.value = null;
        setCompanyId('');
    };

    return <div>
                <CompanyIdInput companyId={companyId} setCompanyId={setCompanyId}/>
                <UploadFileArea uploadFile={uploadFile}/>
            </div>
};

export default Claims;