import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Menu, MenuItem, Button, Tooltip, Chip} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useSelector} from "react-redux";
import {getCognitoUserId} from "../../../../../helper/userHelper";
import {getPrettyDate, getRoleName} from "./helper";
import {hideToolsForUser} from '../../../../../helper/userHelper'
import DeleteUser from "../actionButtons/DeleteUser"
import EnableDisableUser from "../actionButtons/EnableDisableUser";
import UpdateUser from "../actionButtons/UpdateUser";
import AddUserToBranch from "../actionButtons/AddUserToBranch";
import ManageCompany from "../actionButtons/ManageCompany";

const Row = (props) => {
    const userAdmin = useSelector(state => state.user);
    const userAdminId = getCognitoUserId(userAdmin);
    const companyBranches  = useSelector(state => state.companyBranches);
    const company  = useSelector(state => state.company);
    const {titles, user, queryParams} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getActionButtons = () => {
        if (!hideToolsForUser(user, userAdmin)) {
            return <div></div>
        }

        return (
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{maxWidth: 450}}
                >
                    <MenuItem onClick={handleClose}><DeleteUser user={user} queryParams={queryParams}/></MenuItem>
                    <MenuItem onClick={handleClose}><EnableDisableUser user={user} queryParams={queryParams}/></MenuItem>
                    <MenuItem onClick={handleClose}><UpdateUser user={user} queryParams={queryParams}/></MenuItem>
                    <AddUserToBranch handleClose={handleClose} queryParams={queryParams} user={user}/>
                    <ManageCompany queryParams={queryParams} user={user}/>
                </Menu>
            </div>
        );
    };

    const getBranchLabel = (userBranches, key) => {
        if(null === companyBranches) {
            return [];
        }
        let branch =  companyBranches.filter(branch => -1 < userBranches.indexOf(branch.id));
        return <Tooltip title={branch.map(branch => <span key={branch.id}>{branch.location}{key === 'branchId' ? null :', ' } </span>)} arrow>
                    <div
                        style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}}>
                        {branch.map(branch => <span key={branch.id}>{branch.location}{key === 'branchId' ? null :', ' }</span>)}
                    </div>
                 </Tooltip>
    };

    return <TableRow style={{borderLeft: (userAdminId === user.id) ? '5px solid #01c0d7' : ''}}>
                <TableCell>
                    {getActionButtons()}
                </TableCell>
                <TableCell style={{paddingRight: "8px", paddingLeft: "8px"}}>{company?.groupId}</TableCell>
                {titles.map(title => {
                    if (title.key === 'roleCode') {
                        return <TableCell key={title.key} style={{paddingRight: "8px", paddingLeft: "8px"}}><Chip variant="outlined" size="small" label={getRoleName(user[title.key])} /></TableCell>
                    }
                    if (title.key === 'createdAt' || title.key === 'updatedAt') {
                        return <TableCell key={title.key} style={{paddingRight: "8px", paddingLeft: "8px"}}>{getPrettyDate(user[title.key])}</TableCell>
                    }
                    if (title.key === 'managedBranchesIds' ) {
                        return <TableCell key={title.key} style={{ maxWidth: 100,paddingRight: "8px", paddingLeft: "8px"}}>
                            {undefined !== user[title.key] && 0 !== user[title.key].length
                                ? getBranchLabel(user[title.key], 'managedBranchesIds')
                                : null
                            }
                        </TableCell>
                    }
                    if (title.key === 'branchId' ) {
                        return <TableCell key={title.key} style={{ maxWidth: 100,paddingRight: "8px", paddingLeft: "8px"}}>
                            {undefined !== user[title.key] && 0 !== user[title.key].length
                                ? getBranchLabel(user[title.key], 'branchId')
                                : null
                            }
                        </TableCell>
                    }

                    if (title.key === "accessType") {
                        return <TableCell key={title.key} align="center" 
                            style={{paddingRight: "8px", paddingLeft: "8px"}}>{company?.accessType}</TableCell>
                    }

                    return <TableCell key={title.key} style={{paddingRight: "8px", paddingLeft: "8px"}}>{user[title.key]} </TableCell>
                })}
            </TableRow>
};

export default Row;