import React from "react";
import {exampleBtn} from '../../../css/styles';
import claims from '../../../exampleFiles/claims_example.csv';
import patient_demographics from '../../../exampleFiles/Patient_demographics_example.csv';
import news_example from '../../../exampleFiles/news_example.csv'

const Example = (props) => {
    const style = exampleBtn();
    const {typeValue} = props;
    const download = (e) => {

        if(undefined !== typeValue) {
            if (typeValue === 'news') e.target.href = news_example;
            if (typeValue === 'claims') e.target.href = claims;
            if (typeValue === 'patient') e.target.href = patient_demographics;

            e.target.download = `${typeValue}_example.csv`;
        }

    };

    return <div className={style.root}>
                <div className={style.text}>Download example file</div>
                <a className={style.btn}
                   href='data.csv'
                   onClick={e => download(e)}
                   style={{color: '#ffffff'}}
                >
                    File
                </a>
            </div>;
};

export default Example;