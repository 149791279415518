import { makeStyles } from '@material-ui/core/styles';

const loader = makeStyles({
    root: {
        zIndex: 10,
        position: 'fixed',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.5)'
    }
});

const navigation = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 0'
    },

    text: {
        marginLeft: 8,
        color: '#ffffff',

    },
    textActive: {
        marginLeft: 8,
        color: '#000000'
    },
    icon: {
        color: '#ffffff',
        fontSize: 15
    },
    iconActive: {
        fontSize: 15,
        color: '#000000'
    },
    btn: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 20px',
        "&:hover": {

                color: '#000000',

        }
    },
});

const navigationmobile = makeStyles({
    root: {
        padding: '12px 0'
    },
    btn: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0'
    },
    text: {
        marginLeft: 8,
        color: '#ffffff'
    },
    icon: {
        color: '#ffffff',
        fontSize: 15
    }
});

const useStyles = makeStyles({
    root: {
        maxWidth: 420,
        minWidth: 300,
        margin: '20px auto'
    },
    content: {
        borderTop: '1px solid #e3e3e3',
        padding: 16
    }
});

const forgotPassword = makeStyles({
    root: {
        maxWidth: 420,
        minWidth: 300,
        margin: '20px auto'
    },
    content: {
        borderTop: '1px solid #e3e3e3',
        padding: 16
    }
});

const headerLogoStyles = makeStyles({
    root: {
        padding: 16,
        height: 50
    },
    logo: {
        height: 50
    },
    logout: {
        color: '#fff',
        cursor: 'pointer',
        '&:hover': {
            color: '#000'
        }
    }
});

const signUpFormStyles = makeStyles({
    incorrectInput: {
        padding: 8,
        fontSize: 16,
        marginBottom: 16
    },
    registerBtn: {
        marginTop: 20
    }
});

const signIpFormStyles = makeStyles({
    forgot: {
        cursor: 'pointer',
        fontSize: 14,
        color: '#000000',
        "&:hover": {
            color: '#02c9e3',
            textDecoration: 'underline'
        }
    }
});

const inputStyles = makeStyles({
    root: {
        '&$focused': {
            color: '#000',
        },
    },
    back: {
        fontSize: 14,
        color: '#000000',
        textDecoration: 'underline',
        cursor: 'pointer',
        "&:hover": {
            color: '#02c9e3',
            textDecoration: 'underline'
        }
    }
});

const contentWrapper = makeStyles( {
    root: {
        flexGrow: 1,
        marginBottom: 80,
        marginTop: 24,
        width: '100%',
        minWidth: '100%'
    }
});

const footer = makeStyles({
    root: {
        width: "100%",
        flexShrink: 0
    },
    wrapBtnFooter: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: "center"
    },
    mainBtn: {
        padding: 10,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    icon: {
        marginRight: 8,
        color: '#d3d3d3',
        fontSize: 20
    },
    popup: {
        position: 'absolute',
        bottom: 60,
        background: '#ffffff',
        width: '100%'
    }
});

const newsStyles = makeStyles({
    wrapper: {
        marginBottom: 30,

    },
    date: {
        fontWeight: 700,
        fontSize: 13,
        color: '#bababa'
    },
    dateWrapper: {
        width: 200,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center"

    },
    line: {
        height: 1,
        width: 110,
        background: '#e5e5e5',
        marginTop: 4
    },
    title: {
        lineHeight: 2,
        fontWeight: 600
    },
    description: {
        lineHeight: 2
    },
    showbtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        width: 100,
        color: '#02c9e3',
        cursor: 'pointer'
    },
    showbtnIcon: {
        marginTop: 3,
    },
    showbtnIconR: {
        marginTop: 3,
        transform: 'rotate(180deg)'
    }

});
const uploadArea = makeStyles({
    wrapper: {
        position: 'relative',
        height: 200,
        widths: 300,
        marginBottom: 40
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translate(-50%, -50%)'
    }
});

const uploadLogo = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        width: 450,
        marginRight: 20
    },
    text: {
        maxWidth: '60%'
    },
    icon: {
        opacity: 0.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #ffffff',
        borderRadius: 5,
        padding: 3,
        marginLeft: 8,
        cursor: 'pointer',
        "&:hover": {
            opacity: 0.9
        }
    },
    image: {
        objectFit: "contain",
        height: 60,
        maxWidth: '70%'
    }
});
const links = makeStyles({
    root: {
        color: '#353535',
        marginBottom: 40,
        borderTop: '1px solid #e2e2e2'
    },
    title: {
        height: 36,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        color: '#ababab'
    },
    link: {
        textDecoration: 'none',
        height: 36,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        color: '#000000',
        cursor: 'pointer',
        "&:hover": {
            color: '#02c9e3'
        }
    }
});

const exampleBtn = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        marginRight: 16,
        color: '#ababab',
        fontSize: 14
    },
    btn: {
        textDecoration: 'none',
        backgroundColor: '#02c9e3',
        color: '#ffffff',
        padding: '5px 20px',
        borderRadius: 5,
        '&:hover' : {
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        }
    }
});

const table = makeStyles({
    root: {
        overflow: "auto",
        width: '100%'
    },
    header: {
        display: "flex",
        alignItems: "center"
    },
    headertd: {
        minWidth: 200,
        minHeight: 40,
        width: 200,
        padding: 10,
        borderBottom: '1px solid #eaeaea',
        fontSize: 14,
color: '#8c8c8c'
    },
    row: {
        display: "flex",
        alignItems: "center",
        width: '100%'
    },
    rowTd: {
        width: 200,
        padding: 10,
        minWidth: 200,
        minHeight: 46,
        borderBottom: '1px solid #eaeaea',
        display: 'flex',
        alignItems: 'center',
        overflowWrap: 'anywhere',
        fontSize: 15
    },
    actionBtn: {
        minWidth: 180,
        width: 180,
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-around',
        minHeight: 46,
        borderBottom: '1px solid #eaeaea'
    }
});
const addLink = makeStyles({
    root: {
        height: 47,
        display: "flex",
        alignItems: "center",
        marginLeft: '10%'
    },
    btn: {
        backgroundColor: '#02c9e3',
        color: '#ffffff',
        borderRadius: 5,
        display: 'flex',
        width: 25,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover' : {
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        }
    },
    back: {
        marginLeft: 16,
        color: '#ababab',
        fontSize: 14
    }
});

const manageBranch = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    btn: {
        marginBottom: 24
    },
    title: {
        margin: '8px 0 16px'
    }
}));

const controlePanel = makeStyles({
    root: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export {
        addLink,
        table,
        exampleBtn,
        links,
        uploadLogo,
        uploadArea,
        newsStyles,
        footer,
        navigationmobile,
        loader, navigation,
        forgotPassword,
        useStyles,
        headerLogoStyles,
        signUpFormStyles,
        signIpFormStyles,
        inputStyles,
        contentWrapper,
        manageBranch,
    controlePanel
};