export const routes = {
    SEND_NEWS: '/api/news',
    GET_NEWS: '/api/news/today',
    PHONE_VERIFICATION_CODE: '/api/auth/send-phone-verification-code',
    VERIFICATION_PHONE: '/api/auth/verify-phone',
    USER_MFA_WAS_SET: '/api/user/user-mfa-was-set',
    GET_COMPANY_LOGO: '/api/company/logo',
    GET_HOMEPAGE_LINK: '/api/homepage-link',
    CREATE_HOMEPAGE_LINK: '/api/homepage-link',
    DASHBOARD_CLAIMS: '/api/dashboard/import/claims',
    PATIENT_DEMOGRAPHICS: '/api/dashboard/import/patient-demographics',
    GET_EMBEDED_DASHBOARD_URL: '/api/dashboard/embeded-dashboard-url',
    GET_EMBEDED_DASHBOARDS_NAMES: '/api/dashboard/embeded-dashboards-names',
    GET_COMPANY_LIST: '/api/company/list',
    GET_ADMIN_COMPANY_LIST: '/api/company/sp/list',
};