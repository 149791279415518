import AuthApiClient from './AuthApiClient';

export default class CompanyApi extends AuthApiClient {
    getCompany(headers, companyId) {
        return this._sendAuthRequest(`/api/company/${companyId}`, 'GET', null, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    updateCompanyStatus(headers, companyId, body) {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';
        return this._sendAuthRequest(`/api/company/${companyId}/access`, 'PATCH', body, headers)
            .then(response => response.json());
    }

}