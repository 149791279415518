import {actionTypes} from '../actions/actionTypes';
import {
    loader_remove,
    notification_close,
    removeImportInProgressFlag,
    setImportDataNotification,
    setS3FieldsForRequest
} from "../actions/actions";
import DashboardApi from "../Api/DashboardApi";
const _dashboardApi = new DashboardApi();

export default function dashboard(state = {}, action)
{
    switch (action.type) {

        case actionTypes.SEND_DASHBOARD_CLAIMS:            
            _dashboardApi.sendDashboardClaims(
                {},
                action.formData,
                action.companyId
                )
                .then(() => {
                    return state;
                })
                .catch(e => {
                    action.dispatch(notification_close());
                    if(undefined !== e.presignedRequestForDataUpload) {
                        action.dispatch(setImportDataNotification('exaption', {message: 'Oops!  There appears to be something wrong.  Please try to upload again, if this problem persists, you can send the file to this secure location and the eSimplify team will manually move the file.'}));
                        action.dispatch(setS3FieldsForRequest(e, action.dispatch));
                    }
                    if(undefined !== e.error) {
                        action.dispatch(setS3FieldsForRequest(null, action.dispatch));
                        action.dispatch(setImportDataNotification('exaption', e.error))
                    }

                    action.dispatch(removeImportInProgressFlag());
                    action.dispatch(loader_remove());
                    return state;
                });

            return state;

        case actionTypes.SEND_PATIENT_DEMOGRAPHICS:
            _dashboardApi.sendPatientDemographics(
                {},
                action.formData,
                action.companyId
            )
                .then(data => {
                    return state;
                })
                .catch(e => {
                    if(undefined !== e.presignedRequestForDataUpload) {
                        action.dispatch(setImportDataNotification('exaption', {message: 'Oops!  There appears to be something wrong.  Please try to upload again, if this problem persists, you can send the file to this secure location and the eSimplify team will manually move the file.'}));
                        action.dispatch(setS3FieldsForRequest(e, action.dispatch));
                    }
                    if(undefined !== e.error) {
                        action.dispatch(setS3FieldsForRequest(null, action.dispatch));
                        action.dispatch(setImportDataNotification('exaption', e.error))
                    }

                    action.dispatch(removeImportInProgressFlag());
                    action.dispatch(loader_remove());
                    action.dispatch(notification_close());
                    return state;
                });
            return state;

        case actionTypes.SET_IMPORT_IN_PROGRESS_FLAG:
            return {
                ...state,
                importInProgress: true
            }

        case actionTypes.REMOVE_IMPORT_IN_PROGRESS_FLAG:
            return {
                ...state,
                importInProgress: false
            }
            
        default:
            break;
    }

    return state;
};