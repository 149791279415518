import React, {useState} from "react";
import {Box, Button, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom'
import {
    loader_add,
    sendVerificationCodeToPhoneNumber,
    sendPhoneCorfirmationCode
} from "../../../actions/actions";

const SMSSetup = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.user);
    const [phoneCode, setPhoneCode] = useState('');
    const {setup} = props;

    const verifyPhone = () => {
        dispatch(sendVerificationCodeToPhoneNumber(dispatch));
    };

    const sendPhone = () => {
        dispatch(sendPhoneCorfirmationCode(phoneCode, user, dispatch, history, setup));
        dispatch(loader_add());
    };

    return  <div>
                <div className='loin-text-info' >
                    <p>Send verification code to your phone: {user.attributes.phone_number}</p>
                </div>
                    <Button
                        onClick={e => verifyPhone(e)}
                        variant="contained"
                        style={{
                            backgroundColor: '#02c9e3',
                            color: '#ffffff',
                            marginBottom: 20
                        }}>
                        Send Code
                    </Button>
                <TextField
                    name='Code'
                    label='Code'
                    variant="outlined"
                    fullWidth={true}
                    margin="normal"
                    onChange={e => setPhoneCode(e.target.value)}
                    onKeyUp={e => setPhoneCode(e.target.value)}
                />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
                <Button
                    onClick={e => sendPhone(e)}
                    variant="contained"
                    style={{
                        backgroundColor: '#02c9e3',
                        color: '#ffffff'
                    }}>
                    Confirm
                </Button>
            </Box>
            </div>

};

export default SMSSetup;