import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class EmbededDashboardApi extends AuthApiClient {

    getEmbededDashboardUrl(headers, body=null, query = false) {

        return this._sendAuthRequest(routes.GET_EMBEDED_DASHBOARD_URL, 'POST', body, headers, null, query)
            .then(response => {
                return this._filterNonJsonResponse(response)
                    .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                    .then(response => response.json())
                    .then(data => {
                        if('DashboardNotFoundError' === data.error.name) {
                            return Promise.reject({
                                'DashboardNotFoundError': data.error.message
                            });
                        }
                        return Promise.reject(data);
                    });
            });
    }

    getEmbededDashboardsNames(headers, body=null, query = false) {

        return this._sendAuthRequest(routes.GET_EMBEDED_DASHBOARDS_NAMES, 'GET', body, headers, null, query)
            .then(response => {
                return this._filterNonJsonResponse(response)
                    .then(response => response.json())
            })
            .catch(result => {
                return this._filterNonJsonResponse(result.response)
                    .then(response => response.json())
                    .then(data => {
                        if('DashboardNotFoundError' === data.error.name) {
                            return Promise.reject({
                                'DashboardNotFoundError': data.error.message
                            });
                        }
                        return Promise.reject(data);
                    });
            });
    }

}