import React, {createRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCompanyLogo, uploadCompanyLogo, uploadWLCompanyLogo, getWLCompanyLogo} from "../../actions/actions";
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import {Box} from "@material-ui/core";
import {signUpKeys} from "../forms/helper/attributesKey";
import {uploadLogo} from  "../../css/styles";
import { getCognitoUserRole, UserRole } from "../../helper/userHelper";


const CompanyLogo = () => {
    const styles = uploadLogo();
    const dispatch = useDispatch();
    const logo = useSelector(state => state.logo);
    const user = useSelector(state => state.user);
    const companyAccess = useSelector(state => state.companyAccess);
    const whiteLabel = useSelector((state) => state.whiteLabel);
    const whiteLabelId = whiteLabel?.id;
    const userRole = getCognitoUserRole(user);

    const fileInput = createRef();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(companyAccess==="whiteLabel" && whiteLabelId){
        dispatch(getWLCompanyLogo( whiteLabelId, dispatch));
        }
        if(companyAccess!=="whiteLabel"){
        dispatch(getCompanyLogo(dispatch));
        }

    }, [dispatch, companyAccess, whiteLabelId]);

    const uploadFile = (file) => {
        let formData = new FormData();
        formData.append("logo", file);
        if(companyAccess!=="whiteLabel" ){
        dispatch(uploadCompanyLogo(formData, dispatch))
        }

        if(companyAccess==="whiteLabel" && whiteLabelId){
        dispatch(uploadWLCompanyLogo(formData, whiteLabelId, dispatch));
        }
    };

    const isEmptyPlaseholder = () => {
      return  ((null === logo || null === logo.data.logoUrl) && ((undefined === user.attributes[signUpKeys.PRACTICE_NAME.name]
        || '' === user.attributes[signUpKeys.PRACTICE_NAME.name])))
    };

    const setOpacity = show || isEmptyPlaseholder() ? 1 : 0;

    const btnUploader = () => {
        return (<div>
                    {userRole===UserRole.SuperAdmin || userRole===UserRole.WhiteLabelAdmin ? 
                     <div onClick={e => fileInput.current.click()} style={{opacity: setOpacity}} className={styles.btn}>
                        <div className={styles.icon}>
                            <CreateRoundedIcon fontSize="small" />
                        </div>
                        <input type='file' style={{display: 'none'}} onChange={e => uploadFile(e.target.files[0])} ref={fileInput} />
                    </div>:null
                    }
                </div>)
    };


    if(null === logo || null === logo.data.logoUrl) {
        if(undefined !== user.attributes[signUpKeys.PRACTICE_NAME.name]
            && '' !== user.attributes[signUpKeys.PRACTICE_NAME.name]) {
            return (<div className={styles.wrapper} onMouseEnter={e => setShow(true)} onMouseLeave={e => setShow(false)}>
                        <div className={styles.text}>{user.attributes[signUpKeys.PRACTICE_NAME.name]}</div>
                        {btnUploader()}
                    </div>);
        }
        return <div className={styles.wrapper}>{userRole===UserRole.SuperAdmin || (userRole===UserRole.WhiteLabelAdmin && <div style={{opacity: 0.5}}>upload logo</div>)} {btnUploader()}</div>;
    }

    return (<Box className={styles.wrapper} onMouseEnter={e => setShow(true)} onMouseLeave={e => setShow(false)}>
                {!logo.show
                    ? <div style={{width: '70%'}}><img style={{width: '100%', objectFit: "contain", height: 60,}} 
                        src={logo.data.logoUrl} alt="logo"/>
                            <p style={{position: 'absolute', opacity: 0,  top: 0}}>1</p>
                    </div>
                    : <img className={styles.image} src={logo.data.logoUrl} alt="logo"/>}

                {btnUploader()}
            </Box>)

};

export default CompanyLogo;