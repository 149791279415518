import {actionTypes} from '../actions/actionTypes';
import {popupTypes} from "../helper/popupTypes";

export default function popup(state = null, action)
{
    switch (action.type) {
        case actionTypes.POPUP_ADD_NEW_USER:
            return {type: popupTypes.addNewUser, companyId: action.companyId};

        case actionTypes.POPUP_UPDATE_USER:
            return {type: popupTypes.updateUser, user: action.user, queryParams: action.queryParams};

        case actionTypes.POPUP_USER_LIST:
            return {type: popupTypes.userList, id: action.id};

        case actionTypes.POPUP_CLOSE:
            return null;

        case actionTypes.POPUP_MANAGE_BRANCHES:
            return {type: popupTypes.manageBranches, companyId: action.companyId};

        default:
            break;
    }

    return state;
};