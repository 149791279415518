import {signUpKeys} from "./attributesKey";

const isNumbers = (value) => {
    const rule = new RegExp('^[0-9\+\/]+$');//eslint-disable-line
    return rule.test(value);
};

const isValidLength = (value, length) => {
    return value.length === length;
};

const minRangeCarakter = (value, count) => {
    return value.length <= count;
};

// const maxRangeCarakter = (value, count) => {
//     return value.length >= count;
// };

const isValidEmail = (value) => {
    const rule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;//eslint-disable-line
    return rule.test(value);

};

const isEmpty = (value) => {
    return value === '';
};

const phoneValidation = (value) => {
    if(isNumbers(value) && minRangeCarakter(value, 14)) {
        return true;
    }
    return false;
};

const zipCodeValidation = (value) => {
    if(isNumbers(value)) {
        return true;
    }
    return false;
};

const npiCodeValidation = (value) => {
    if(isNumbers(value) && minRangeCarakter(value, 10)) {
        return true;
    }
    return false;
};

const validatePassword = (password, helperText) => {
    let count = 0;

    let text = [];

    if(!password.match(/[a-z]/g)){
        count++;
        text.push('Lower case letters');
    }

    if(!password.match(/[A-Z]/g)) {
        count++;
        text.push('Upper case letters');
    }

    if(!password.match(/[0-9]/g)) {
        count++;
        text.push('Numbers');
    }

    if(count > 0) {
        helperText.push(`Passwords must contain: `);

        return [...helperText, ...text];
    }


    return helperText;

};

export const validation = {

    isValid: (name, value) => {
        if(name === signUpKeys.MOBILE_NUMBER.name && !phoneValidation(value) && '' !== value) {
            return true;
        }

        if(name === signUpKeys.POSTAL_CODE.name && !zipCodeValidation(value) && '' !== value) {
            return true;
        }

        if(name === signUpKeys.NPI.name && !npiCodeValidation(value) && '' !== value) {
            return true;
        }

        if(name === signUpKeys.GROUP_ID.name && !npiCodeValidation(value) && '' !== value) {
            return true;
        }
        return false;
    },

    checkPassword: (password, setState) => {
        const helperText = [];

        if(10 > password.length) {
            helperText.push('Passwords or passphrase must be at least 10 characters');
        }

        return setState(validatePassword(password, helperText));
    },

    checkPsw: (password, setState) => {

        if(isEmpty(password)) {
            setState('This value should not be blank.');
            return false;
        }
        setState(null);
        return true;
    },

    checkEmail: (email, setState, key = false, state = false) => {
        if(isEmpty(email)) {
            key !== false && state !== false
                ? setState({...state, [key]: 'This value should not be blank.'})
                : setState('This value should not be blank.');

            return false;
        }
        if(!isValidEmail(email)){
            key !== false && state !== false
                ? setState({...state, [key]: 'This value is not a valid Email.'})
                : setState('This value is not a valid Email.');
            return false;
        }
        setState(null);
        return true;
    },

    checkNPI: (npi, setState) => {

        if(isEmpty(npi)) {
            setState(null);
            return true;
        }
        if(!isValidLength(npi, 10)) {
            setState('This value is not a valid NPI.');
            return false;
        }
        setState(null);
        return true;
    },

    checkPhone: (phone, setState, key = false, state = false) => {
        if(isEmpty(phone)) {
            key !== false && state !== false
                ? setState({...state, [key]: 'This value should not be blank.'})
                : setState('This value should not be blank.');
            return false;
        }
        setState(null);
        return true;
    },

    checkLastName: (lastName, setState, key = false, state = false) => {
        if(isEmpty(lastName)) {
            key !== false && state !== false
                ? setState({...state, [key]: 'This value should not be blank.'})
                : setState('This value should not be blank.');
            return false;
        }
        setState(null);
        return true;
    },

    checkGroupId: (groupId, setState) => {
        if(isEmpty(groupId)) {
            setState(null);
            return true;
        }

        if(!isValidLength(groupId, 10)) {
            setState('This value is not a valid group id.');
            return false
        }
        setState(null);
        return true;
    },

    checkConfirm: (password, confirm) => {
        if(password === '') {
            return false;
        }

        if(password !== confirm) {
            return true;
        }
    },

    checkRole: (role, companyId, setState) => {
        if(role === '') {
            setState('This value should not be blank.');
            return false;
        }
        if (undefined !== companyId && '' === companyId && ('3' === role || '5' === role || '6' === role|| '7' === role)) {
            setState('"Company Id" value should not be blank if desired role is equal: Practice Admin, Location Admin, User, White Label admin');
            return false;
        }
        setState(null);
        return true;
    }
};
