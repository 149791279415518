import {actionTypes} from '../actions/actionTypes';

export default function filters(state = {}, action)
{
    switch (action.type) {
        case actionTypes.COMPANY_LIST_FILTERS:
            return  {filters: action.filters}

        default:
            break;
    }

    return state;
};
