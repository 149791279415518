import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Link, useHistory} from 'react-router-dom';
import {TextField, Box, Button} from "@material-ui/core";
import {signInKeys} from './helper/attributesKey';
import {signin, loader_add} from '../../actions/actions';
import {signIpFormStyles, inputStyles} from '../../css/styles';
import {validation} from './helper/validationHelper';


const SignInForm = (props) => {
    const history = useHistory();
    const email = null !== props.params && props.params.email ? props.params.email : '';
    const classes = signIpFormStyles();
    const inputStyle = inputStyles();
    const dispatch = useDispatch();
    const [username, setUsername] = useState(email);
    const [password, setPassword] = useState('');
    const [validateEmail, setValidateEmail] = useState(null);
    const [validatePsw, setValidatePsw] = useState(null);
    const [submitAttempt, setSubmitAttempt] = useState(null);

    const signIn = (e) => {
        e.preventDefault()
        setSubmitAttempt(true);
        let validEmail = validation.checkEmail(username, setValidateEmail);
        let validPsw = validation.checkPsw(password, setValidatePsw);

        if(false === validEmail || false === validPsw) {
            return;
        }

        if (null === validatePsw && null === validateEmail) {
            dispatch(loader_add());
            dispatch(signin({username: username,  password: password}, dispatch, history));
        }
    };

    const fillForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if(name === signInKeys.EMAIL.name) {
            setUsername(value);
        }
        if(name === signInKeys.PASSWORD.name) {
            setPassword(value);
        }
        if(submitAttempt) {
            validation.checkEmail(username, setValidateEmail);
            validation.checkPsw(password,setValidatePsw);
        }
    };

    return (<div>
        <form noValidate autoComplete="off" onSubmit={signIn}>
            <TextField
                name={signInKeys.EMAIL.name}
                label={signInKeys.EMAIL.label}
                value={username}
                className={inputStyle.root}
                autoComplete="email"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                required
                onClick={e => fillForm(e)}
                onChange={e => fillForm(e)}
                onKeyUp={e => fillForm(e)}
                helperText={null !== validateEmail ? validateEmail : ''}
                error={null !== validateEmail ? true : false}
            />
            <TextField
                name={signInKeys.PASSWORD.name}
                label={signInKeys.PASSWORD.label}
                onClick={e => fillForm(e)}
                onKeyUp={e => fillForm(e)}
                margin="normal"
                type="password"
                variant="outlined"
                required
                fullWidth={true}
                onChange={e => setPassword(e.target.value)}
                helperText={null !== validatePsw ? validatePsw : ''}
                error={null !== validatePsw ? true : false}
            />
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                <div className={classes.forgot}><Link to="/forgot-password">Change or Forgot Password</Link></div>
                <Button
                    onClick={signIn}
                    type='submit'
                    variant="contained"
                    style={{
                        backgroundColor: '#02c9e3',
                        color: '#ffffff'
                    }}>
                    Login
                </Button>
            </Box>
        </form>
    </div>)
};

export default SignInForm;