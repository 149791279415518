import React from "react";
import {TextField} from "@material-ui/core";

const CompanyIdInput = (props) => {
    const {companyId, setCompanyId} = props;

    return (
        <div style={{display: "flex", alignItems: 'center', marginBottom: 16}}>
            <TextField
                value={companyId}
                label="Company Id"
                size="small"
                onChange={e => setCompanyId(e.target.value)}
                variant="outlined" />
            <div style={{paddingLeft: 20}}>Import file to group</div>
        </div>
    )
};

export default CompanyIdInput;