export const actionTypes = {
    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    ACCOUNT_RECOVERY_REQUEST: 'ACCOUNT_RECOVERY_REQUEST',
    ACCOUNT_RECOVERY_REQUEST_SUCCESS: 'ACCOUNT_RECOVERY_REQUEST_SUCCESS',
    ACCOUNT_RECOVERY_PASSWORD_RESET: 'ACCOUNT_RECOVERY_PASSWORD_RESET',
    ACCOUNT_RECOVERY_PASSWORD_RESET_SUCCESS: 'ACCOUNT_RECOVERY_PASSWORD_RESET_SUCCESS',
    RECOVERY_PASSWORD_CLEAR_STATE: 'RECOVERY_PASSWORD_CLEAR_STATE',
    LOADER_ADD: 'LOADER_ADD',
    LOADER_REMOVE: 'LOADER_REMOVE',
    EXEPTION_ADD: 'EXEPTION_ADD',
    EXEPTION_CLOSE: 'EXEPTION_CLOSE',
    NOTIFICATION_ADD: 'NOTIFICATION_ADD',
    NOTIFICATION_CLOSE: 'NOTIFICATION_CLOSE',
    GET_USER: 'GET_USER',
    SET_USER: 'SET_USER',
    CONFIRM_SIGN_UP: 'CONFIRM_SIGN_UP',
    SIGN_OUT: 'SIGN_OUT',
    GET_PREFERRED_MFA: 'GET_PREFERRED_MFA',
    GET_QR_URL: 'GET_QR_URL',
    SET_QR_URL: 'SET_QR_URL',
    TOTP_VERIFY: 'TOTP_VERIFY',
    LOGIN_CONFIRM: 'LOGIN_CONFIRM',
    NEW_PASSWORD_CONFIRM: 'NEW_PASSWORD_CONFIRM',
    SEND_NEWS: 'SEND_NEWS',
    GET_NEWS: 'GET_NEWS',
    SET_NEWS: 'SET_NEWS',
    GET_PHONE_VERIFICATION_CODE: 'GET_PHONE_VERIFICATION_CODE',
    SEND_PHONE_VERIFICATION_CODE: 'SEND_PHONE_VERIFICATION_CODE',
    SEND_PHONE_CONFIRMATION_CODE: 'SEND_PHONE_CONFIRMATION_CODE',
    SET_PREFERRED_MFA_SMS: 'SET_PREFERRED_MFA_SMS',
    SET_PREFERRED_MFA_TOTP: 'SET_PREFERRED_MFA_TOTP',
    SET_MFA_SETUP_STATUS: 'SET_MFA_SETUP_STATUS',
    RESET_MFA_SETUP_STATUS: 'RESET_MFA_SETUP_STATUS',
    USER_MFA_WAS_SET: 'USER_MFA_WAS_SET',
    GET_COMPANY_LOGO: 'GET_COMPANY_LOGO',
    SET_COMPANY_LOGO: 'SET_COMPANY_LOGO',
    UPLOAD_COMPANY_LOGO: 'UPLOAD_COMPANY_LOGO',
    GET_HOMEPAGE_LINK: 'GET_HOMEPAGE_LINK',
    CREATE_HOMEPAGE_LINK: 'CREATE_HOMEPAGE_LINK',
    DELETE_HOMEPAGE_LINK: 'DELETE_HOMEPAGE_LINK',
    EDIT_HOMEPAGE_LINK: 'EDIT_HOMEPAGE_LINK',
    SET_HOMEPAGE_LINK: 'SET_HOMEPAGE_LINK',
    SEND_DASHBOARD_CLAIMS: 'SEND_DASHBOARD_CLAIMS',
    SEND_PATIENT_DEMOGRAPHICS: 'SEND_PATIENT_DEMOGRAPHICS',
    SET_IMPORT_NOTIFICATION: 'SET_IMPORT_NOTIFICATION',
    SET_IMPORT_IN_PROGRESS_FLAG: 'SET_IMPORT_IN_PROGRESS_FLAG',
    REMOVE_IMPORT_IN_PROGRESS_FLAG: 'REMOVE_IMPORT_IN_PROGRESS_FLAG',
    REMOVE_IMPORT_NOTIFICATION: 'REMOVE_IMPORT_NOTIFICATION',
    SET_S3_FIELDS_FOR_REQUEST: 'SET_S3_FIELDS_FOR_REQUEST',
    SEND_FILE_TO_S3: 'SEND_FILE_TO_S3',
    GET_EMBEDED_DASHBOARD_URL: 'GET_EMBEDED_DASHBOARD_URL',
    SET_EMBEDED_DASHBOARD_URL: 'SET_EMBEDED_DASHBOARD_URL',
    GET_EMBEDED_DASHBOARDS_NAMES: 'GET_EMBEDED_DASHBOARDS_NAMES',
    SET_EMBEDED_DASHBOARDS_NAMES: 'SET_EMBEDED_DASHBOARDS_NAMES',
    GET_COMPANY_LIST: 'GET_COMPANY_LIST',
    SET_COMPANY_LIST: 'SET_COMPANY_LIST',
    GET_ADMIN_COMPANY_LIST: 'GET_ADMIN_COMPANY_LIST',
    SET_ADMIN_COMPANY_LIST: 'SET_ADMIN_COMPANY_LIST',
    GET_COMPANY_USERS: 'GET_COMPANY_USERS',
    GET_COMPANY_USERS_BRANCH: 'GET_COMPANY_USERS_BRANCH',
    SET_COMPANY_USERS: 'SET_COMPANY_USERS',
    DELETE_COMPANY_USERS: 'DELETE_COMPANY_USERS',
    DISABLE_COMPANY_USERS: 'DISABLE_COMPANY_USERS',
    ENABLE_COMPANY_USERS: 'ENABLE_COMPANY_USERS',
    UPDATE_COMPANY_USER: 'UPDATE_COMPANY_USER',
    ADD_NEW_USER: 'ADD_NEW_USER',
    POPUP_ADD: 'POPUP_ADD',
    POPUP_CLOSE: 'POPUP_CLOSE',
    POPUP_ADD_NEW_USER: 'POPUP_ADD_NEW_USER',
    POPUP_MANAGE_BRANCHES: 'POPUP_MANAGE_BRANCHES',
    POPUP_UPDATE_USER: 'POPUP_UPDATE_USER',
    POPUP_USER_LIST: 'POPUP_USER_LIST',
    GET_COMPANY_BRANCHES: 'GET_COMPANY_BRANCHES',
    ADD_COMPANY_BRANCH: 'ADD_COMPANY_BRANCH',
    DELETE_COMPANY_BRANCH: 'DELETE_COMPANY_BRANCH',
    SET_COMPANY_BRANCHES: 'SET_COMPANY_BRANCHES',
    GET_COMPANY: 'GET_COMPANY',
    SET_COMPANY: 'SET_COMPANY',
    USER_LINK_TO_BRANCH: 'USER_LINK_TO_BRANCH',
    USER_UNLINK_FROM_BRANCH: 'USER_UNLINK_FROM_BRANCH',
    USER_UNLINK_FROM_COMPANY: 'USER_UNLINK_FROM_COMPANY',
    USER_LINK_TO_COMPANY: 'USER_LINK_TO_COMPANY',
    GET_MANAGED_USER_COMPANY: 'GET_MANAGED_USER_COMPANY',
    LOGIN_CONFIRM_SELECT_MFA: 'LOGIN_CONFIRM_SELECT_MFA',
    SET_MANAGED_USER_COMPANY: 'SET_MANAGED_USER_COMPANY',
    SEND_MFA_SELECTION_ANSWER: 'SEND_MFA_SELECTION_ANSWER',
    MFA_SETUP_TO_LOCALSTORAGE: 'MFA_SETUP_TO_LOCALSTORAGE',
    REMOVE_MFA_FROM_LOCALSTORAGE: 'REMOVE_MFA_FROM_LOCALSTORAGE',
    COMPANY_LIST_FILTERS: 'COMPANY_LIST_FILTERS',
    UPDATE_COMPANY_URL: 'UPDATE_COMPANY_URL',
    SET_COMPANY_WHITE_LABEL: 'SET_COMPANY_WHITE_LABEL',
    SET_COMPANY_ACCESS: 'SET_COMPANY_ACCESS',
    UPDATE_COMPANY_STATUS: 'UPDATE_COMPANY_STATUS',
    SET_COLOR: 'SET_COLOR',
    GET_COMPANY_WHITE_LABEL: 'GET_COMPANY_WHITE_LABEL',
    UPDATE_WHITE_LABEL_COLOR: 'UPDATE_WHITE_LABEL_COLOR',
    SET_NEW_SUBDOMAIN: 'SET_NEW_SUBDOMAIN',
    WL_LOADER_ADD: 'WL_LOADER_ADD',
    WL_LOADER_REMOVE: 'WL_LOADER_REMOVE',
    SET_LOGOUT_STATUS: 'SET_LOGOUT_STATUS',
    GET_WHITE_LABEL_SETTINGS: 'GET_WHITE_LABEL_SETTINGS',
    UPLOAD_WLCOMPANY_LOGO: 'UPLOAD_WLCOMPANY_LOGO',
    GET_WLCOMPANY_LOGO: 'GET_WLCOMPANY_LOGO',
    SET_SUBDOMAIN_STATUS: 'SET_SUBDOMAIN_STATUS',
    SET_USER_COMPANY: 'SET_USER_COMPANY',
    GET_USER_COMPANY: 'GET_USER_COMPANY',
    UPDATE_COMPANY_NAME: 'UPDATE_COMPANY_NAME',
    SET_COMPANY_DETAILS: 'SET_COMPANY_DETAILS',
};
