import React, {useState} from "react";
import {Button, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {createHomepageLink, loader_add} from '../../../../actions/actions';
import {useDispatch} from "react-redux";
import {validation} from "./helper";
import {addLink} from "../../../../css/styles";

const CreateLink = () => {
    const style = addLink();
    const dispatch = useDispatch();
    const [isShowForm, setShowForm] = useState(false);
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [validationUrl, setvalidationUrl] = useState(null);
    const [validationTitle, setvalidationTitle] = useState(null);

    const submitLink = () => {
        let validUrl = validation.checkLink(url, setvalidationUrl);
        let validTitle = validation.checkTitle(title, setvalidationTitle);

        if(false === validUrl || false === validTitle) {
            return;
        }

            dispatch(createHomepageLink({
                "url": url,
                "title": title
            }, dispatch));
            dispatch(loader_add());
            setShowForm(false);
            setUrl('');
            setTitle('');

    };

    return <div>
                {true === isShowForm
                    ? <form noValidate autoComplete="off" style={{display: "flex", alignItems: 'baseline', marginLeft: '10%'}}>
                        <TextField
                            name='url'
                            label='Url'
                            value={url}
                            multiline
                            required
                            onClick={e => setUrl(e.target.value)}
                            onChange={e => setUrl(e.target.value)}
                            onKeyUp={e => setUrl(e.target.value)}
                            style={{margin: '0 8px'}}
                            helperText={null !== validationUrl ? validationUrl : ''}
                            error={null !== validationUrl ? true : false}
                        />
                        <TextField
                            name='title'
                            label='Title'
                            value={title}
                            multiline
                            required
                            onClick={e => setTitle(e.target.value)}
                            onChange={e => setTitle(e.target.value)}
                            onKeyUp={e => setTitle(e.target.value)}
                            style={{margin: '0 8px'}}
                            helperText={null !== validationTitle ? validationTitle : ''}
                            error={null !== validationTitle ? true : false}
                        />
                        <Button
                            onClick={e => submitLink()}
                            variant="contained"
                            size={"small"}
                            style={{
                                backgroundColor: '#02c9e3',
                                color: '#ffffff'
                            }}>
                            Send
                        </Button>
                    </form>
                    : null}
                {false === isShowForm
                ? <div className={style.root} >
                        <div
                        onClick={e => setShowForm(true)}
                        className={style.btn}>
                        <Add size="small"/>
                        </div>
                        <div className={style.back}>Create link</div>
                </div> : null}
            </div>
};

export default CreateLink;