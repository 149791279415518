import React from 'react';
import {useDispatch} from "react-redux";
import {notification_close} from '../../actions/actions';
import CancelIcon from '@material-ui/icons/Cancel';

export default (props) => {
    const dispatch = useDispatch();
    return (
        <div className="notification-popup-wrapper">
            <div>{props.notification}</div>
            <div onClick={e => {dispatch(notification_close())}}>
                <CancelIcon className='close' />
            </div>
        </div>
    );
};
