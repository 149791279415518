import {actionTypes} from '../actions/actionTypes';

export default function notification(state = null, action) {
    switch (action.type) {

        case actionTypes.NOTIFICATION_ADD:
            return action.msg;

        case actionTypes.NOTIFICATION_CLOSE:
            return null;

        default:
            break;
    }

    return state;

}