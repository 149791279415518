import React from "react";
import { Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const SearchButton = ({handleSearchClick}) => {
  return (
    <div
      style={{
        width: 40,
        height: 30,
        marginLeft: 10,
        marginRight: 30,
      }}
    > 
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={(e) => handleSearchClick(e)}
      >
        <SearchIcon />
      </Button>
    </div>
  );
};

export default SearchButton;
