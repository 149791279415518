import React from "react";

const Notification = (props) => {
    const {type, message} = props.notification;

    if ('exaption' === type && undefined === message.details && undefined !== message.message && 'string' === typeof message.message) {

        return <div style={{padding: 20, borderLeft: '3px solid #dc1f30', marginBottom: 20, color: '#4c4c4c'}}>
            <div>{message.message}</div>
        </div>;
    }

    if ('exaption' === type && undefined !== message.details) {
        let title = 'RowIsInvalidError' === message.name && undefined !== message.rowNumber
            ? `Following validation errors found on the row #${message.rowNumber}`
            : '';
        return <div style={{padding: 20, borderLeft: '3px solid #dc1f30', marginBottom: 20, color: '#4c4c4c'}}>
                    <div>{title}</div>
                    {message.details.map((item, idex) => <div key={idex}>{item.message}</div>)}
                </div>;
    }

    if ('notification' === type && undefined !== message && 'string' === typeof message) {
        return <div style={{padding: 20, borderLeft: '3px solid #3f51b5', marginBottom: 20, color: '#4c4c4c'}}>
            <div>{message}</div>
        </div>;
    }

    if ('notification' === type && undefined !== message.message) {
        return <div style={{padding: 20, borderLeft: '3px solid #3f51b5', marginBottom: 20, color: '#4c4c4c'}}>
                    <div>{message.message}</div>
                </div>;
    }

    return <div></div>;
};

export default Notification;