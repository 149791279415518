import {actionTypes} from '../actions/actionTypes';
import {setCompanyBranches, getCompanyBranches} from '../actions/actions';
import CompanyBranchesApi from '../Api/CompanyBranchesApi';
const _companyBranchesApi = new CompanyBranchesApi();

export default function companyBranches(state = null, action) {
    switch (action.type) {

        case actionTypes.SET_COMPANY_BRANCHES:
            return action.branches;

        case actionTypes.GET_COMPANY_BRANCHES:
            _companyBranchesApi.getCompanyBranches({}, action.companyId)
                .then(data => {
                    action.dispatch(setCompanyBranches(data.data.list));
                    return data.data.list;
                })
                .catch(err => {
                    action.dispatch(setCompanyBranches(null));
                    return state;
                });
            return state;

        case actionTypes.ADD_COMPANY_BRANCH:
            _companyBranchesApi.addCompanyBranch({}, {
                location: action.name,
                companyId: action.companyId
            })
                .then(data => {
                    action.dispatch(getCompanyBranches(action.companyId, action.dispatch));
                    return data.data.list;
                })
                .catch(err => {
                    return state;
                });
            return state;

            case actionTypes.DELETE_COMPANY_BRANCH:
                _companyBranchesApi.deleteCompanyBranch({}, action.branchId)
                    .then(data => {
                        action.dispatch(getCompanyBranches(action.companyId, action.dispatch));
                        return state;
                    })
                    .catch(err => {
                        return state;
                    });
                return state;

        default:
            break;
    }

    return state;
}