import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class SettingsApi extends AuthApiClient {

    getCompanyList(headers, body) {

        return this._sendAuthRequest(routes.GET_COMPANY_LIST, 'GET', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    getAdminCompanyList(headers, body) {

        return this._sendAuthRequest(routes.GET_ADMIN_COMPANY_LIST, 'GET', body, headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }


}