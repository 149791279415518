import {actionTypes} from '../actions/actionTypes';

export default function loader(state = false, action)
{
    switch (action.type) {
        case actionTypes.LOADER_ADD:
            return true;

        case actionTypes.LOADER_REMOVE:
            return false;
        default:
            break;
    }

    return state;
};