import AuthApiClient from './AuthApiClient';
import {routes} from "./helper/routes";

export default class HomepageLinkApi extends AuthApiClient {

    editeHomepageLink(headers, body, id) {
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(`${routes.CREATE_HOMEPAGE_LINK}/${id}`, 'PATCH', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }


    deleteHomepageLink(headers, id) {
        return this._sendAuthRequest(`${routes.CREATE_HOMEPAGE_LINK}/${id}`, 'DELETE', null,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    createHomepageLink(headers, body){
        headers = headers ?? {};
        headers['Content-type'] = 'application/json';

        return this._sendAuthRequest(routes.CREATE_HOMEPAGE_LINK, 'POST', body,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }

    getHomepageLink(headers)
    {
        return this._sendAuthRequest(routes.GET_HOMEPAGE_LINK, 'GET', null,  headers)
            .then(response => this._filterNonJsonResponse(response))
            .then(response => response.json());
    }
}