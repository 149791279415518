import {actionTypes} from '../actions/actionTypes';
import {setImportDataNotification, setS3FieldsForRequest} from "../actions/actions";
import DashboardApi from "../Api/DashboardApi";
const _dashboardApi = new DashboardApi();

export default function importS3(state = null, action)
{
    switch (action.type) {

        case actionTypes.SET_S3_FIELDS_FOR_REQUEST:
            return action.data;

        case actionTypes.SEND_FILE_TO_S3:
             _dashboardApi.presignedRequest(state.presignedRequestForDataUpload, state.file)
                 .then(data => {
                     action.dispatch(setImportDataNotification('notification', data));
                     action.dispatch(setS3FieldsForRequest(null, action.dispatch));
                     return null;
                 })
                 .catch(e => console.log(e));
            return state;

        default:
            break;
    }

    return state;
};