import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getHomepageLink, getUser} from "../../../actions/actions";
import {Typography, Grid} from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {links} from '../../../css/styles';
import {signUpKeys} from "../../forms/helper/attributesKey";
import CreateLink from "./home/CreateLink";
import EditHomePageLink from './home/EditHomePageLink';


const HomePageLink = () => {
    const styless = links();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const homepageLink = useSelector(state => state.homepageLink);

    const federalLink = null !== homepageLink && 0 < homepageLink.length ? homepageLink.filter(link => "from-esimplify" !== link.type) : [];
    const esimlifyLink = null !== homepageLink && 0 < homepageLink.length ? homepageLink.filter(link => "from-esimplify" === link.type) : [];

        useEffect(() => {
            dispatch(getHomepageLink(dispatch));
            if(null === user) {
                dispatch(getUser(dispatch));
            }
            //eslint-disable-next-line
        }, []);

    const isSuper = () => undefined !== user.attributes && '1' === user.attributes[signUpKeys.DESIRED_ROLE.name];

    const renderLinks = (link) => {
        if(isSuper() && "from-esimplify" === link.type) {
            return <EditHomePageLink key={link.id} link={link}/>
        } else {
            return <a href={link.url} target="_blank" rel="noopener noreferrer" key={link.id} 
            style={{ marginLeft: isSuper() ? '10%' : 0, width: isSuper() ? '90%' : '100%'}} className={styless.link}>
                        <div>{link.title}</div>
                        <ArrowRightAltIcon style={{ fontSize: 24 }} />
                    </a>;
        }
    };


    if (0 < federalLink.length || 0 < esimlifyLink.length) {
        return <div style={{marginBottom: 20}}>
            <Typography style={{padding: 20}} variant="h5" component="h5">Links</Typography>
                    {0 < esimlifyLink.length
                        ? <Grid  container spacing={3} className={styless.root}>
                            <Grid item xs={12} sm={4}>
                                <div className={styless.title}>From eSimplify</div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {esimlifyLink.map(link => renderLinks(link))}
                               {isSuper() ? <CreateLink /> : null}
                            </Grid>
                        </Grid >
                        : null}
                    {0 < federalLink.length
                        ? <Grid  container spacing={3} className={styless.root}>
                            <Grid item xs={12} sm={4}>
                                <div className={styless.title}>Federal and State CMS links</div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {federalLink.map(link => renderLinks(link))}
                            </Grid>
                        </Grid>
                        : null}
            </div>
    }

    return <div></div>;
};

export default HomePageLink;